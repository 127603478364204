import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Spinner,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import axiosInstance from "../../axios";

class ArticlesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authors: [{ nom: "", prenom: "" }],
      articles: null,
      fileName: "",
      titre: null,
      userData: this.props.userData,
      loading: false,
      successMessage: "",
    };
  }

  handleFileChange = (event) => {
    this.setState({
      articles: event.target.files[0],
      fileName: event.target.files[0].name,
    });
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleAuthorChange = (event, index) => {
    const { name, value } = event.target;
    const authors = [...this.state.authors];
    authors[index][name] = value;
    this.setState({ authors });
  };

  addAuthor = () => {
    const authors = [...this.state.authors, { nom: "", prenom: "" }];
    this.setState({ authors });
  };

  removeAuthor = (index) => {
    const authors = [...this.state.authors];
    authors.splice(index, 1);
    this.setState({ authors });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({ loading: true });

    const { userData } = this.props;
    const userId = userData.id;
    const { authors, articles, titre } = this.state;
    const formData = new FormData();
    formData.append("titre", titre);
    formData.append("file", articles);
    formData.append("user_id", userId);

    axiosInstance
      .post(`utilisateurs/upload_article/${userId}/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const articleId = response.data.id;

        const authorData = authors.map((author) => ({
          article: articleId,
          nom: author.nom,
          prenom: author.prenom,
        }));

        return axiosInstance.post("utilisateurs/upload_authors/", authorData);
      })
      .then(() => {
        this.setState({
          successMessage: "L'article a été ajouté avec succées",
          authors: [{ nom: "", prenom: "" }],
          articles: null,
          titre: null,
        });
        this.setState({ loading: false });
        window.location.reload();
        this.props.toggle();
      })
      .catch((error) => {
        console.error("Erreur création de l'auteur: ", error);
        this.setState({ loading: false });
      });
  };

  render() {
    const { authors, loading } = this.state;
    return (
      <Form onSubmit={this.handleSubmit}>
        <FormGroup>
          <Label for="titre">Article title</Label>
          <Input
            type="text"
            name="titre"
            id="titre"
            value={this.state.titre}
            onChange={this.handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="auteurs">Article author(s)</Label>
          {authors.map((author, index) => (
            <div key={index} className="author-input">
              <Input
                type="text"
                name="nom"
                placeholder="Saisir le nom de l'auteur"
                value={author.nom}
                onChange={(event) => this.handleAuthorChange(event, index)}
              />
              <Input
                type="text"
                name="prenom"
                placeholder="Saisir le prenom de l'auteur"
                value={author.prenom}
                onChange={(event) => this.handleAuthorChange(event, index)}
              />
              <Button
                type="button"
                color="danger"
                size="sm"
                className="mt-2 mb-2"
                onClick={() => this.removeAuthor(index)}
              >
                Delete author
              </Button>
            </div>
          ))}
          <Button
            type="button"
            color="secondary"
            size="sm"
            onClick={this.addAuthor}
          >
            Add author
          </Button>
        </FormGroup>
        <FormGroup>
          <Label for="article">
            Select the PDF article
            <InputGroup className="mt-3" style={{ cursor: "pointer" }}>
              <InputGroupText>Select a file</InputGroupText>
              <Input
                disabled="true"
                placeholder={this.state.fileName || "No file selected"}
              />
            </InputGroup>
            <Input
              type="file"
              name="file"
              id="article"
              accept="application/pdf"
              onChange={this.handleFileChange}
              style={{ visibility: "hidden" }}
            />
          </Label>
        </FormGroup>
        {loading ? (
          <Button color="success" disabled={true}>
            <Spinner size="sm" /> Loading
          </Button>
        ) : (
          <Button color="success" type="submit">
            Upload
          </Button>
        )}
      </Form>
    );
  }
}
export default ArticlesForm;
