import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

class GISPresentation extends Component {
  render() {
    return (
      <Container className="d-sm-block d-none mt-5">
        <Row className="row-content justify-content-center">
          <Col xs={12} sm={3} className="text-center">
            <img src="./static/img/num.png" alt="Numeric" />
            <h5>Digital</h5>
            <p>
              Digital Geographic Information is the new context of map libraries
            </p>
          </Col>
          <Col xs={12} sm={3} className="text-center offset-sm-1">
            <img src="./static/img/geo.png" alt="Geographic" />
            <h5>Geographic</h5>
            <p>
              The Geographic Information is a generalist journal that addresses
              all topics related to geography
            </p>
          </Col>
          <Col xs={12} sm={3} className="text-center offset-sm-1">
            <img src="./static/img/information.png" alt="Information" />
            <h5>Information</h5>
            <p>
              A concept from the dicipline of Science Information and
              Communication
            </p>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default GISPresentation;
