import React, { Component } from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Alert,
  Spinner,
} from "reactstrap";
import axiosInstance from "../../axios";
import { Link } from "react-router-dom";
import ResetPasswordModal from "../Modals/ResetPasswordModal";

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mail: "",
      password: "",
      errors: {},
      userData: null,
      showForgotPasswordModal: false,
      loading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  toggleForgotPasswordModal = () => {
    this.setState((prevState) => ({
      showForgotPasswordModal: !prevState.showForgotPasswordModal,
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ userData: this.props.userData, loading: true });
    axiosInstance
      .post("token/", {
        mail: this.state.mail,
        password: this.state.password,
        userData: this.state.userData,
      })
      .then((response) => {
        localStorage.setItem("access_token", response.data.access);
        localStorage.setItem("refresh_token", response.data.refresh);
        axiosInstance.defaults.headers["Authorization"] =
          "JWT " + localStorage.getItem("access_token");
        this.setState({ loading: false });
        window.location.reload();
        this.props.toggle();
        this.props.handleLoginState();
      })
      .catch((error) => {
        if (error) {
          this.setState({
            errors: { notFound: "Wrong email or password" },
            loading: false,
          });
        }
      });
  };

  render() {
    const { errors, showForgotPasswordModal, loading } = this.state;
    return (
      <div className="loginform">
        <Form onSubmit={this.handleSubmit}>
          <FormGroup>
            <Label for="mail">Email</Label>
            <Input
              id="mail"
              name="mail"
              placeholder="Enter your email address"
              type="email"
              required={true}
              value={this.state.mail}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="password">Password</Label>
            <Input
              id="password"
              name="password"
              placeholder="Enter your password"
              type="password"
              required={true}
              value={this.state.password}
              onChange={this.handleChange}
            />
            {errors.notFound && (
              <Alert color="danger" className="mt-3">
                {errors.notFound}
              </Alert>
            )}
          </FormGroup>
          {loading ? (
            <Button color="primary" disabled={true} className="mb-3">
              <Spinner size="sm" />
            </Button>
          ) : (
            <Button color="primary" type="submit" className="mb-2">
              SignIn
            </Button>
          )}
        </Form>

        <Link
          onClick={() =>
            this.toggleForgotPasswordModal(showForgotPasswordModal)
          }
        >
          Forgot Password?
        </Link>
        <ResetPasswordModal
          showForgotPasswordModal={showForgotPasswordModal}
          toggleForgotPasswordModal={this.toggleForgotPasswordModal}
        />
      </div>
    );
  }
}
export default LoginForm;
