import React, { Component } from "react";
import { Form, FormGroup, Label, Input, Button, Alert } from "reactstrap";
import axiosInstance from "../../axios";

class ResetPswForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mail: "",
      successMessage: "",
      errorMessage: "",
      userData: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    axiosInstance
      .post("password-reset/", {
        mail: this.state.mail,
      })
      .then((response) => {
        if (response && response.status === 200) {
          this.setState({
            successMessage: "A password reset link is sent to your email.",
            errorMessage: "",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);

        if (error.response) {
          const data = error.response.data;
          this.setState({
            errorMessage: data.detail || "An error occurred.",
            successMessage: "",
          });
        } else if (error.request) {
          // The request was made but no response was received
          this.setState({
            errorMessage:
              "No response from the server. Please try again later.",
            successMessage: "",
          });
        } else {
          // Something happened in setting up the request that triggered an Error
          this.setState({
            errorMessage: "An error occurred. Please try again later.",
            successMessage: "",
          });
        }
      });
  };

  render() {
    return (
      <div className="resetpswform">
      <Form onSubmit={this.handleSubmit}>
        <FormGroup>
          <Label for="mail">Email</Label>
          <Input
            id="mail"
            name="mail"
            placeholder="Enter your email"
            type="email"
            required={true}
            value={this.state.mail}
            onChange={this.handleChange}
          />
        </FormGroup>
        <Button color="primary" type="submit">
          Reset password
        </Button>
      </Form>
      {this.state.successMessage && (
        <Alert className="mt-3">{this.state.successMessage}</Alert>
      )}
      {this.state.errorMessage && (
        <Alert color="danger" className="mt-3">{this.state.errorMessage}</Alert>
      )}
      </div>
    );
  }
}
export default ResetPswForm;
