import React, { Component } from "react";
import {
  FormGroup,
  Label,
  Input,
  Button,
  Form,
  Alert,
  Spinner,
} from "reactstrap";
import ReactQuill from "react-quill";
import axiosInstance from "../../axios";
import "react-quill/dist/quill.snow.css";

class SendMail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
      subject: "",
      successmsg: "",
      loading: false,
    };
  }

  handleSubjectChange = (event) => {
    this.setState({ subject: event.target.value });
  };

  handleTextChange = (value) => {
    this.setState({ text: value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    const parser = new DOMParser();
    const doc = parser.parseFromString(this.state.text, "text/html");
    const plainTextContent = doc.body.textContent || "";

    const emailData = {
      subject: this.state.subject,
      email_text: plainTextContent,
    };
    axiosInstance
      .post("admin/send-email/", emailData)
      .then(() => {
        // Clear inputs and show success message
        this.setState({
          subject: "",
          text: "",
          successMessage: "Email sent successfully!",
          loading: false,
        });
      })
      .catch((error) => {
        console.log("error sending mail", error);
        this.setState({ loading: false });
      });
  };

  render() {
    const { loading } = this.state;
    return (
      <div className="mt-5 me-3">
        <Form onSubmit={this.handleSubmit}>
          <FormGroup>
            <Label for="subject">Mail subject</Label>
            <Input
              type="text"
              id="subject"
              value={this.state.subject}
              onChange={this.handleSubjectChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="TextArea">Enter the email to send</Label>
            <ReactQuill
              value={this.state.text}
              onChange={this.handleTextChange}
              id="TextArea"
              modules={{
                toolbar: [
                  [{ font: [] }],
                  ["bold", "italic", "underline", "strike"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["link", "image"],
                ],
                clipboard: {
                  matchVisual: false,
                },
              }}
              formats={[
                "font",
                "header",
                "bold",
                "italic",
                "underline",
                "strike",
                "list",
                "link",
                "image",
              ]}
            />
          </FormGroup>
          {loading ? (
            <Button color="primary" disabled={true} className="mb-3">
              <Spinner size="sm" />
            </Button>
          ) : (
            <Button color="primary" type="submit">
              Envoyer
            </Button>
          )}
        </Form>
        {this.state.successMessage && (
          <Alert color="success">{this.state.successMessage}</Alert>
        )}
      </div>
    );
  }
}
export default SendMail;
