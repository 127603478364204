import React, { Component } from "react";
import axiosInstance from "../../axios";
import { Button, Table } from "reactstrap";
import { Link, Outlet } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faList, faTrash } from "@fortawesome/free-solid-svg-icons";

class EventsAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    axiosInstance
      .get("events/")
      .then((response) =>
        this.setState({ events: response.data, isLoading: false })
      )
      .catch((error) => this.setState({ error, isLoading: false }));
  }

  handleDelete = (eventId) => {
    const confirmed = window.confirm(
      "You are about to delete an event"
    );
    if (confirmed) {
      axiosInstance
        .post(`/admin/delete-event/${eventId}/`)
        .then((response) => {
          this.setState((prevState) => ({
            events: prevState.events.filter((event) => event.id !== eventId),
          }));
        })
        .catch((error) => {
          console.error("Error deleting event: ", error);
        });
    }
  };

  updateEvents = (updateEvents) => {
    this.setState({ events: updateEvents });
  };

  passEventIdForUpdate = (eventId) => {
    this.props.onEventIdChange(eventId);
  };


  render() {
    const { events } = this.state;
    return (
      <div className="eventsadmin mt-3">
        <Table striped responsive>
          <thead>
            <tr>
              <th>Title</th>
              <th>Location</th>
              <th>Date</th>
              <th>DELETE</th>
              <th>Update</th>
              <th>List of registrations</th>
            </tr>
          </thead>
          <tbody>
            {events.map((event) => (
              <tr key={event.id}>
                <td>{event.title}</td>
                <td>{event.localisation}</td>
                <td>
                  {event.dateDeb} to {event.dateFin}
                </td>
                <td>
                  <Button
                    color="danger"
                    onClick={() => this.handleDelete(event.id)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </td>
                <td>
                  <Link to={`update-event/${event.id}`}>
                    <Button
                      color="primary"
                      onClick={() => this.passEventIdForUpdate(event.id)}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                  </Link>
                </td>
                <td>
                <Link to={`registered-users/${event.id}`}>
                    <Button
                      color="primary"
                      onClick={() => this.passEventIdForUpdate(event.id)}
                    >
                      <FontAwesomeIcon icon={faList} />
                    </Button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Link to="add-event/">
          <Button color="primary" type="button">
          Add an event
          </Button>
        </Link>
        <Outlet
          events={this.state.events}
          updateEvents={this.updateEvents}
          handleDelete={this.handleDelete}
        />
      </div>
    );
  }
}
export default EventsAdmin;
