import React, { Component } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Link } from "react-router-dom";

class SidebarComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="sidebar-container">
        <Sidebar className="sidebar">
          <Menu>
            <MenuItem component={<Link to="admin-users" />}>Members</MenuItem>
            <MenuItem component={<Link to="admin-events" />} >Events</MenuItem>
            <MenuItem component={<Link to="send-mail" />} >Send an email</MenuItem>
            <MenuItem component={<Link to="statistics" />} >Statistics</MenuItem>
          </Menu>
        </Sidebar>
        </div>
    );
  }
}
export default SidebarComponent;
