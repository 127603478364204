import React, { Component } from "react";
import { Container, Row } from "reactstrap";
import ContactUsMap from "../Home/ContactUsMap";

class Contact extends Component {
  render() {
    return (
      <Container className="mt-5">
        <Row>
          <hr />
          <h4>Contact us</h4>
          <ContactUsMap />
        </Row>
      </Container>
    );
  }
}
export default Contact;
