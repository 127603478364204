import React, { Component } from "react";
import {
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  Alert,
} from "reactstrap";
import axiosInstance from "../../axios";

class EventRegistrationForm extends Component {
  constructor(props) {
    super(props);
    this.state = Object.freeze({
      nom: "",
      prenom: "",
      numTel: "",
      mail: "",
      pays: "",
      niveaux: "",
      organisation: "",
      event_id: this.props.event_id,
      loading: false,
      success: false,
      errors: {},
    });
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ loading: true });

    const { nom, prenom, numTel, mail, pays, niveaux, organisation, event_id } =
      this.state;
    axiosInstance
      .post(`/event/${event_id}/invite_registration/`, {
        nom: nom,
        prenom: prenom,
        numTel: numTel,
        mail: mail,
        pays: pays,
        niveaux: niveaux,
        organisation: organisation,
        event_id: event_id,
      })
      .then((response) => {
        this.setState({ success: true, loading: false });
        setTimeout(() => {
          this.props.toggle();
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false });
      });
  }

  render() {
    const { errors, loading, success } = this.state;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="nom">Nom</Label>
              <Input
                id="nom"
                name="nom"
                placeholder="Saisissez votre nom"
                type="text"
                value={this.state.nom}
                onChange={this.handleChange}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="prenom">Prénom</Label>
              <Input
                id="prenom"
                name="prenom"
                placeholder="Saisissez votre prénom"
                type="text"
                value={this.state.prenom}
                onChange={this.handleChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Label for="numTel">Numéro de téléphone</Label>
          <Input
            id="numTel"
            name="numTel"
            placeholder="Saisissez votre numéro de téléphone"
            type="number"
            value={this.state.numTel}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="mail">Email</Label>
          <Input
            id="mail"
            name="mail"
            placeholder="Saisissez votre mail"
            type="email"
            value={this.state.mail}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="pays">Pays</Label>
          <Input
            id="pays"
            name="pays"
            placeholder="Saisissez votre pays de résidence"
            type="text"
            value={this.state.pays}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="niveaux">Niveaux</Label>
          <Input
            id="niveaux"
            name="niveaux"
            placeholder="Saisissez votre niveaux d'étude"
            type="text"
            value={this.state.niveaux}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="organisation">Organisation</Label>
          <Input
            id="organisation"
            name="organisation"
            placeholder="Saisissez votre organisation"
            type="text"
            value={this.state.organisation}
            onChange={this.handleChange}
          />
        </FormGroup>

        {loading ? (
          <Button color="primary" disabled={true}>
            <Spinner size="sm" />
          </Button>
        ) : success ? (
          <Alert color="success">You have been successfully registered</Alert>
        ) : (
          <Button color="primary" type="submit">
            Register
          </Button>
        )}
      </Form>
    );
  }
}
export default EventRegistrationForm;
