import React, { Component } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faFax, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <Container>
          <Row>
            <Col xs={4} sm={{ size: 1, offset: 1 }}>
              <h5>Links</h5>
              <ul className="list-unstyled">
                <li>
                  <a href="#">Home</a>
                </li>
                <li>
                  <a href="https://www.unioneag.org/">UEAG</a>
                </li>
                <li>
                <Link to="/geotunis" target="_blank">GeoTunis</Link>
                </li>
              </ul>
            </Col>
            <Col sm={6} xs={7}>
              <h5>Our Address</h5>
              <address>
                1001, Radhia Hadded Street <br />
                Radhia Haded Street
                <br />
                Tunisia, Tunis
                <br />
                <FontAwesomeIcon icon={faPhone} /> 00 (216) 71 245 692 <br />
                <FontAwesomeIcon icon={faFax} /> 00 (216) 71 24 56 92 <br />
                <FontAwesomeIcon icon={faEnvelope} />
                <a href="mailto:geoconf2@gmail.com"> geoconf2@gmail.com</a>
              </address>
            </Col>
            <Col xs={12} sm={4} className="align-self-center">
              <div className="text-center mb-5">
                <Button
                  href="https://www.facebook.com/ATIGNATIGEO"
                  className="btn btn-social-icon btn-facebook me-3"
                >
                  <FontAwesomeIcon icon={faFacebook} />
                </Button>
                <Button
                  href="https://www.youtube.com/channel/UCwbdN2P5HpTb8VtcttBGlhA/featured"
                  className="btn btn-social-icon btn-facebook"
                >
                  <FontAwesomeIcon icon={faYoutube} />
                </Button>
              </div>
              <h5>In collaboration with: </h5>
                <div className="logo-container">
                <a href="https://sgs.ksu.edu.sa/en" target="_blank">
                  <img
                    src="./static/img/SGS.jpg"
                    className="footer-logo mb-2"
                  />
                  <p>SGS</p>
                </a>
                </div>
                <div className="logo-container">
                <a
                  href="https://www.ausgeo.org/"
                  target="_blank"
                >
                  <img
                    src="./static/img/UAG.jpg"
                    className="footer-logo mb-2"
                  />
                  <p>AUS GEO</p>
                </a>
                </div>
                <div className="logo-container">
                <a
                  href="https://www.unioneag.org/contact/?lang=en"
                  target="_blank"
                >
                  <img
                    src="./static/img/UEAGEO.jpg"
                    className="footer-logo mb-2"
                  />
                  <p>UEAG</p>
                </a>
                </div>
                <div className="logo-container">
                <a
                  href="https://www.facebook.com/GeoSciencePublication"
                  target="_blank"
                >
                  <img
                    src="./static/img/geosp_footer.png"
                    className="footer-logo mb-2"
                  />
                  <p>GEOSP MAG</p>
                </a>
                </div>
            </Col>
            <div className="text-center d-flex justify-content-center align-items-center mt-4">
                ©Web developer : <a href="mailto:mokhlessouissi@gmail.com">SOUISSI Mokhles</a>
              </div>
          </Row>
        </Container>
      </footer>
    );
  }
}
export default Footer;
