import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
  Spinner
} from "reactstrap";
import axiosInstance from "../../axios";
import { useParams, useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const [uidb64, setUidb64] = useState("");
  const [token, setToken] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const { uidb64: routeUidb64, token: routeToken } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    setUidb64(routeUidb64);
    setToken(routeToken);
  }, [routeUidb64, routeToken]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axiosInstance
      .post(`password-reset/confirm/${routeUidb64}/${routeToken}/`, {
        uidb64,
        token,
        new_password: newPassword,
      })
      .then(() => {
        setSuccessMessage("Password reset successful.");
        setErrorMessage("");
        navigate("/");
      })
      .catch((error) => {
        setErrorMessage(error.response?.data?.detail || "An error occurred.");
        setSuccessMessage("");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    setNewPassword(e.target.value);
  };

  return (
    <Container>
      <Row>
        <Col md={{ size: 6, offset: 3 }}>
          <h2 className="mt-4 mb-4">Password Reset</h2>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="newPassword">New Password</Label>
              <Input
                type="password"
                id="newPassword"
                placeholder="Enter new password"
                value={newPassword}
                onChange={handleChange}
              />
            </FormGroup>
            {loading === false ? (
              <Button color="primary" type="submit">
                Reset Password
              </Button>
            ) : (
              <Button color="primary" disabled>
                <Spinner size="sm" />
              </Button>
            )}
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ChangePassword;
