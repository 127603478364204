import { Col, Row } from "reactstrap";
import { Component } from "react";
import MembersPerCountryMap from "./Charts/MembersPerCountryMap";
import MembersPerYearStats from "./Charts/MembersPerYearStats";
import NiveauxEtudesStats from "./Charts/NiveauxEtudesStats";
import MembersPerCountryStats from "./Charts/MembersPerCountryStats";

class Statistics extends Component {
  render() {
    return (
      <div className="statistics mt-5 mb-5">
        <Row>
          <Col ms={2}>

            <Row>
              <MembersPerYearStats />
            </Row>
          </Col>
          <Col ms={8}>
            <MembersPerCountryMap />
          </Col>
          <Col ms={2}>
            <Row>
            <NiveauxEtudesStats />
            </Row>
            <Row>
              <MembersPerCountryStats />
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Statistics;
