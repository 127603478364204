import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

class PastEvents extends Component {
  render() {
    return (
      <Container className="container text-center" id="events">
        <hr />
        <h4>Past events</h4>
        <Row className="row-content">
          <Col xs="12" sm="4">
            <Card className="mb-3" style={{ width: "18rem" }}>
              <img
                className="card-img-top"
                src="./static/img/research.jpg"
                alt="Event"
              />
              <CardHeader className="text-center">
                <CardTitle tag="h5">
                  The 9th International Scientific Forum for Research, Studies
                  and Associated Conferences
                </CardTitle>
              </CardHeader>
              <CardBody className="text-center">
                <ListGroup flush>
                  <ListGroupItem>Turkey, Istanbul</ListGroupItem>
                  <ListGroupItem>14-17 November 2023</ListGroupItem>
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
          <Col xs="12" sm="4">
            <Card className="mb-3" style={{ width: "18rem" }}>
              <img
                className="card-img-top"
                src="./static/img/energy.jpg"
                alt="Event"
              />
              <CardHeader className="text-center">
                <CardTitle tag="h5">
                  The 9th International Conference on Water, Energy and
                  Agricultural Technology Management
                </CardTitle>
              </CardHeader>
              <CardBody className="text-center">
                <ListGroup flush>
                  <ListGroupItem>Turkey, Istanbul</ListGroupItem>
                  <ListGroupItem>14-17 November 2023</ListGroupItem>
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
          <Col xs="12" sm="4">
            <Card className="mb-3" style={{ width: "18rem" }}>
              <img
                className="card-img-top"
                src="./static/img/climate.jpg"
                alt="Event"
              />
              <CardHeader className="text-center">
                <CardTitle tag="h5">
                  The 6th International Conference on the Impacts of Climate
                  Change: Adaptation and Mitigation
                </CardTitle>
              </CardHeader>
              <CardBody className="text-center">
                <ListGroup flush>
                  <ListGroupItem>Turkey, Istanbul</ListGroupItem>
                  <ListGroupItem>14-17 November 2023</ListGroupItem>
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
          <Col xs="12" sm="4">
            <Card className="mb-3" style={{ width: "18rem" }}>
              <img
                className="card-img-top"
                src="./static/img/geomatics.jpg"
                alt="Event"
              />
              <CardHeader className="text-center">
                <CardTitle tag="h5">
                  The 6th International Conference on Applications of Geomatics,
                  Artificial Geospatial Intelligence and Data Science
                </CardTitle>
              </CardHeader>
              <CardBody className="text-center">
                <ListGroup flush>
                  <ListGroupItem>Turkey, Istanbul</ListGroupItem>
                  <ListGroupItem>14-17 November 2023</ListGroupItem>
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
          <Col xs="12" sm="4">
            <Card className="mb-3" style={{ width: "18rem" }}>
              <img
                className="card-img-top"
                src="./static/img/cities.jpg"
                alt="Event"
              />
              <CardHeader className="text-center">
                <CardTitle tag="h5">
                  International Conference on Smart Cities and Sustainable Urban
                  Planning
                </CardTitle>
              </CardHeader>
              <CardBody className="text-center">
                <ListGroup flush>
                  <ListGroupItem>Turkey, Istanbul</ListGroupItem>
                  <ListGroupItem>14-17 November 2023</ListGroupItem>
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
          <Col xs="12" sm="4">
            <Card className="mb-3" style={{ width: "18rem" }}>
              <img
                className="card-img-top"
                src="./static/img/geology.jpg"
                alt="Event"
              />
              <CardHeader className="text-center">
                <CardTitle tag="h5">
                International Forum on Environment, Earth Sciences and Geology
                </CardTitle>
              </CardHeader>
              <CardBody className="text-center">
                <ListGroup flush>
                  <ListGroupItem>Turkey, Istanbul</ListGroupItem>
                  <ListGroupItem>14-17 November 2023</ListGroupItem>
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default PastEvents;
