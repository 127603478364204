import React from 'react';
import Header from './Header';

const Objective = () => {
  return (
    <div className="obj-container">
    <Header />
      <div className="container text-center">
        <h4 className='mt-5'>Objective of the association</h4>
        <div className="row row-content align-item-center">
          <div className="col-8 col-sm-8 offset-2">
            <p className='mb-5'>
              The valuation of Geomatics using New Information and Communication
              Technologies nationally and internationally
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Objective;
