import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  ButtonGroup,
  Button,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardText,
  CardSubtitle,
  Table,
  CardTitle,
} from "reactstrap";

class GeoTunisAr extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "about",
    };
  }

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  render() {
    return (
      <div className="geotunisAr">
        <header className="jumbotronGeotunis">
          <ButtonGroup
            className="geo-btn-group"
            role="group"
            aria-label="language"
          >
            <Button
              tag={Link}
              to="/geotunis"
              color="primary"
              className="translate"
              id="en"
            >
              English
            </Button>
            <Button color="primary" className="translate" id="ar" disabled>
              عربية
            </Button>
          </ButtonGroup>
        </header>

        <Container
          fluid
          style={{ height: "100%" }}
          className="mt-4 ArDirection"
        >
          <Row className="row-container">
            <Col xs="12" sm="12" style={{ height: "100%" }}>
              <Card className="mb-3">
                <CardHeader className="card-nav" ref={this.cardNavRef}>
                  <Nav
                    tabs
                    className="nav-tabs card-header-tabs"
                    id="list"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={
                          this.state.activeTab === "about"
                            ? "active lang navlinkGeoTunis"
                            : "lang navlinkGeoTunis"
                        }
                        onClick={() => this.toggleTab("about")}
                        role="tab"
                        aria-controls="about"
                        aria-selected="true"
                        key="aboutHeader"
                      >
                        الاستقبال
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          this.state.activeTab === "scthemes"
                            ? "active lang navlinkGeoTunis"
                            : "lang navlinkGeoTunis"
                        }
                        onClick={() => this.toggleTab("scthemes")}
                        role="tab"
                        aria-controls="scthemes"
                        aria-selected="false"
                        key="scthemesHeader"
                      >
                        المحاور العلمية
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          this.state.activeTab === "comittees"
                            ? "active lang navlinkGeoTunis"
                            : "lang navlinkGeoTunis"
                        }
                        onClick={() => this.toggleTab("comittees")}
                        role="tab"
                        aria-controls="comittees"
                        aria-selected="false"
                        key="comitteesHeader"
                      >
                        اللجان
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          this.state.activeTab === "callpaper"
                            ? "active lang navlinkGeoTunis"
                            : "lang navlinkGeoTunis"
                        }
                        onClick={() => this.toggleTab("callpaper")}
                        role="tab"
                        aria-controls="callpaper"
                        aria-selected="false"
                        key="callpaperHeader"
                      >
                        تقديم بحث
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          this.state.activeTab === "exhibition"
                            ? "active lang navlinkGeoTunis"
                            : "lang navlinkGeoTunis"
                        }
                        onClick={() => this.toggleTab("exhibition")}
                        role="tab"
                        aria-controls="exhibition"
                        aria-selected="false"
                        key="exhibitionHeader"
                      >
                        المعرض
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          this.state.activeTab === "security"
                            ? "active lang navlinkGeoTunis"
                            : "lang navlinkGeoTunis"
                        }
                        onClick={() => this.toggleTab("security")}
                        role="tab"
                        aria-controls="security"
                        aria-selected="false"
                        key="securityHeader"
                      >
                        أمن قومي
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          this.state.activeTab === "libya"
                            ? "active lang navlinkGeoTunis"
                            : "lang navlinkGeoTunis"
                        }
                        onClick={() => this.toggleTab("libya")}
                        role="tab"
                        aria-controls="libya"
                        aria-selected="false"
                        key="libyaHeader"
                      >
                        يوم ليبيا
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          this.state.activeTab === "importantdates"
                            ? "active lang navlinkGeoTunis"
                            : "lang navlinkGeoTunis"
                        }
                        onClick={() => this.toggleTab("importantdates")}
                        role="tab"
                        aria-controls="importantdates"
                        aria-selected="false"
                        key="importantdatesHader"
                      >
                        آجال مهمة
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          this.state.activeTab === "program"
                            ? "active lang navlinkGeoTunis"
                            : "lang navlinkGeoTunis"
                        }
                        onClick={() => this.toggleTab("program")}
                        role="tab"
                        aria-controls="program"
                        aria-selected="false"
                        key="programHeader"
                      >
                        شركائنا
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          this.state.activeTab === "fees"
                            ? "active lang navlinkGeoTunis"
                            : "lang navlinkGeoTunis"
                        }
                        onClick={() => this.toggleTab("fees")}
                        role="tab"
                        aria-controls="fees"
                        aria-selected="false"
                        key="feesHeader"
                      >
                        التسجيل
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader>
                <CardBody>
                  <TabContent activeTab={this.state.activeTab} className="mt-3">
                    <TabPane tabId="about">
                      <CardText>
                        <p className="aboutp1" key="aboutp1">
                          تقيم الجمعية التونسية للإعلام الجغرافي الرقمي بالتعاون
                          مع الجمعية الجغرافية السعودية و الإتحاد الأورو-عربي
                          للجيوماتيك و الاتحاد العربي للمساحة و مجلة منشورات
                          علوم جغرافية الدورة 16 للمؤتمر الدولي لتطبيقات
                          الجيوماتيكس و الذكاء الاصطناعي الجغرافي - جيو تونس -
                          بمدينة الحمامات ايام 18-22 نوفمبر 2024
                        </p>
                      </CardText>
                      <CardText>
                        <p className="text-center">
                          <b
                            className="app lang"
                            key="aboutp2"
                            ref={this.appRef}
                          >
                            ،دور البنية التحتية الجغرافية في التاسيس لتنمية
                            مستدامة
                          </b>
                        </p>
                      </CardText>
                      <CardText>
                        <p className="text-center lang" key="aboutp3">
                          ايام 18-22 نوفمبر الحمامات
                        </p>
                      </CardText>
                      <CardText>
                        <p className="lang" key="aboutp4">
                          في هذا الإطار نتشرف بدعوتكم للمشاركة ضمن مختلف
                          الفعاليات العلمية و التكوينية حيث تنتظم الدورة 16
                          بالتعاون مع عديد المؤسسات العلمية الدولية و بمشاركة
                          هامة من الباحثين و الأكاديميين الدوليين . تنطلق الدورة
                          16 لتؤسس لانطلاقة جديدة في التعاطي مع المعلومة
                          الجغرافية ووسائلها المتعددة من جغرافيا فضائية و نظم
                          معلومات و استشعار عن بعد و علوم المساحة ..........، و
                          لتتناول مواضيع و أطروحات هامة تهدف لإرساء أسس منوال
                          تنموي ناجح بالإعتماد على المعايير الجغرافية، ولتساهم
                          في البحث عن حلول عملية جادة لمختلف المشكلات البيئية و
                          الصحية و المجتمعية و الامنية التي يعيشها عالمنا اليوم
                          . يستعرض المؤتمر عديد المحاور العلمية و مواضيع و محاور
                          تقنية تهدف الى تنمية قدرات الباحثين و المختصين في
                          المجال و تطوير مهاراتهم و زيادة قدراتهم المعرفية
                          والتقنية و الرفع من قدرات المؤسسات و مهارات منتسبيها .
                          يعتبر اليوم جيو تونس فضاء دولي و أكاديمية لتكنولوجيا
                          الجيو فضاء و نظم المعلومات الجغرافية و الاستشعار عن
                          بعد و العلوم ذات العلاقة، و ذلك بفضل إدارة المشرفين
                          عليه و ما يضمه من فضاءات أكاديمية و علمية ذات صيت
                          عالمي. لذلك فإن المشاركة فيه هي فرصة لكل الراغبين في
                          تطوير معارفهم و تنمية قدراتهم التقنية و التعريف
                          بإضافاتهم التكنولوجية ، كما أنه فضاء من خلاله ينظر إلى
                          التنمية بمنظور علمي تقني يحقق المنشود. دعوتنا لكم في
                          كل أنحاء العالم للمشاركة ضمن أحد أهم المؤتمرات العلمية
                          الدولية المهتمة بتطبيقات تكنولوجيا الفضاء و نظم
                          المعلومات الجغرافية و الاستشعار عن بعد، فكونوا في
                          الموعد و مرحبا بكم بيننا في النسخة السادسة عشرة لجيو
                          تونس.
                        </p>
                      </CardText>
                      <CardText>
                        <p
                          className="objectifs lang"
                          key="aboutObjectifs"
                          ref={this.objRef}
                        >
                          أهداف المؤتمر
                        </p>
                        <ol className="obj" ref={this.olRef}>
                          <li className="lang themeObj" key="aboutObj1">
                            استعراض أحدث التطورات في نظم المعلومات الجغرافية
                            والجغرافيا الرقمية.
                          </li>
                          <li className="lang themeObj" key="aboutObj2">
                            تقديم الحلول المكانية و التقنية لدعم صناعة القرار.
                          </li>
                          <li className="lang themeObj" key="aboutObj3">
                            تعزيز التفاعل العلمي في مجال نظم المعلوماتية
                            الجغرافية.
                          </li>
                          <li className="lang themeObj" key="aboutObj4">
                            تعزيز التواصل المعرفي والتقني بين المختصين.
                          </li>
                          <li className="lang themeObj" key="aboutObj5">
                            تبادل الخبرات بين المشاركين.
                          </li>
                          <li className="lang themeObj" key="aboutObj6">
                            دعم منظومات البحث العلمي في التخصصات الذكية.
                          </li>
                          <li className="lang themeObj" key="aboutObj7">
                            تعزيز فرص الاستثمار المعرفي والتقني.
                          </li>
                          <li className="lang themeObj" key="aboutObj8">
                            تشبيك العلاقات العلمية بين مختلف الجهات المشاركة في
                            القطاع.
                          </li>
                        </ol>
                        <p
                          className="card-text themes lang"
                          key="aboutThemes"
                          ref={this.themesRef}
                        >
                          المحاور العلمية الكبرى لجيو تونس
                        </p>
                        <ol className="themeList" ref={this.olRef}>
                          <li className="lang themeLi" key="aboutli1">
                            إدارة الموارد الطبيعية و المائية والتصحر والتغير
                            المناخي
                          </li>
                          <li className="lang themeLi" key="aboutli2">
                            الزراعية و الغذاء
                          </li>
                          <li className="lang themeLi" key="aboutli3">
                            المدن الذكية و التخطيط العمراني و التنمية المستدامة
                          </li>
                          <li className="lang themeLi" key="aboutli4">
                            الجيولوجيا و علوم الأرض و البيئة
                          </li>
                          <li className="lang themeLi" key="aboutli5">
                            الطاقة و الطاقات المتجددة و التحول الطاقي
                          </li>
                          <li className="lang themeLi" key="aboutli6">
                            الامن و الدفاع
                          </li>
                          <li className="lang themeLi" key="aboutli7">
                            الصحة و التعليم و الخدمات الاجتماعية
                          </li>
                          <li className="lang themeLi" key="aboutli8">
                            مواضيع تقنية / الجغرافية الرقمية و نظم المعلومات
                            الجغرافية و الذكاء الاصطناعي الجغرافي
                          </li>
                          <li className="lang themeLi" key="aboutli9">
                            المصادر المفتوحة – الحرة – لتكنولوجيات الجيوماتيكس و
                            علوم الفضاء و الذكاء الاصطناعي الجغرافي
                          </li>
                        </ol>
                        <p
                          className="card-text aboutActHeader lang"
                          key="aboutActHeader"
                          ref={this.aboutActHeaderRef}
                        >
                          الأنشطــــــــة المبرمجة بجيو تونس :
                        </p>
                        <ol type="I" className="aboutAct">
                          <li
                            className="lang sem"
                            key="seminars"
                            ref={this.semRef}
                          >
                            <p className="card-text">نــــــــــــــــدوات</p>
                          </li>
                          <ol className="semList" ref={this.olRef}>
                            <li className="lang semList" key="semList1">
                              إستراتيجية الأمن القومي في ضوء التحولات
                              الجيوإستراتيجية
                            </li>
                            <li className="lang semList" key="semList2">
                              نظم المعلومات الجغرافية الأمنية (GISS) وتطبيقاتها
                            </li>
                            <li className="lang semList" key="semList3">
                              التغير المناخي و انعكاساته على البنية الاقتصادية و
                              الطبيعية
                            </li>
                            <li className="lang semList" key="semList4">
                              المدن الذكية و التخطيط العمراني المستدام
                            </li>
                            <li className="lang semList" key="semList5">
                              نظم المعلومات الجغرافية والاستشعار عن بعد
                              وتطبيقاتهما في علم الآثار
                            </li>
                            <li className="lang semList" key="semList6">
                              الأثر الأمني للطائرات بدون طيار: التحديات والفرص
                            </li>
                            <li className="lang semList" key="semList7">
                              دور العلوم المساحية - نظم المعلومات الجغرافية في
                              التنمية
                            </li>
                            <li className="lang semList" key="semList8">
                              إدارة المياه والتقنيات الزراعية بالعالم العربي
                            </li>
                            <li className="lang semList" key="semList9">
                              تقييم الطاقة المتجددة في التحول العالمي للطاقة
                            </li>
                            <li className="lang semList" key="semList10">
                              دور الجيوماتكس في إدارة الكوارث و الوقاية من
                              إخفاقات البنية التحتية
                            </li>
                            <li className="lang semList" key="semList11">
                              الاقتصاد الاجتماعي و التضامني في ظل التحديات
                              الرقمية
                            </li>
                            <li className="lang semList" key="semList12">
                              الهجرة الغير نظامية بين المقاربات الحقوقية و امن
                              الدول
                            </li>
                          </ol>
                          <p className="card-text lang"></p>
                          <li className="lang workshops" key="workshops">
                            ورش عمل ستؤمنها مؤسسات و خبراء دوليين.
                          </li>
                          <p></p>
                          <p className="card-text lang"></p>
                          <li className="lang courses" key="courses">
                            دورات تدريبية في مجال البنية التحتية الجغرافية و نظم
                            المعلومات الجغرافية و الاستشعار عن بعد.
                          </li>
                        </ol>
                      </CardText>
                      <CardText>
                        <p className="interEx lang" key="interEx">
                          صالون دولي جيو تكنولوجي ستدعى له و تشارك به كبرى
                          المؤسسات و المخابر العلمية الدولية المتخصة في مجال
                          اسداء الخدمات و التصوير الجوي و مزودي الصور الفضائية و
                          الرادارية و منتجي و مطوري البرمجيات و الات و معدات
                          القيس المساحي و اجهزة التموقع عبر الاقمار الصناعية و
                          مكاتب الهندسة و الاستشارات و مختلف المجالات ذات
                          العلاقة
                        </p>
                      </CardText>
                    </TabPane>
                    <TabPane tabId="scthemes">
                      <CardSubtitle>
                        <h5 className="mb-3 lang" key="themeHeader1">
                          المحور الأول :إدارة الموارد الطبيعية و المائية والتصحر
                          والتغير المناخي
                        </h5>
                        <ul className="themeList">
                          <li className="lang themeLi" key="theme1li1">
                            إدارة الموارد الطبيعية
                          </li>
                          <li className="lang themeLi" key="theme1li2">
                            علوم التربة و النبات
                          </li>
                          <li className="lang themeLi" key="theme1li3">
                            إدارة الموارد المائية / الشح و الندرة المائية /
                            الحصاد و التصرف
                          </li>
                          <li className="lang themeLi" key="theme1li4">
                            الأخطار الطبيعية
                          </li>
                          <li className="lang themeLi" key="theme1li5">
                            الجفاف و التصحر و الصحراء – التنمية الصحراوية
                          </li>
                          <li className="lang themeLi" key="theme1li6">
                            التلوث البحري و الهوائي
                          </li>
                          <li className="lang themeLi" key="theme1li7">
                            التلوث الصناعي و الصناعات المنجمية / الفسفاط –
                            الاسمنت
                          </li>
                          <li className="lang themeLi" key="theme1li8">
                            التغير المناخي و اليات التخفيف و التاقلم
                          </li>
                          <li className="lang themeLi" key="theme1li9">
                            المجالات البيئية
                          </li>
                          <li className="lang themeLi" key="theme1li10">
                            القوانين و التشريعات المحلية و الدولية في مجال
                            البيئة و ادارة الموارد الطبيعية
                          </li>
                        </ul>
                        <h5
                          className="card-subtitle mb-2 lang"
                          key="themeHeader2"
                        >
                          المحور الثاني : الزراعة و الغذاء
                        </h5>
                        <ul className="themeList">
                          <li className="lang themeLi" key="theme2li1">
                            المجالات الزراعية
                          </li>
                          <li className="lang themeLi" key="theme2li2">
                            المصادر الوراثية النباتية و الحيوانية
                          </li>
                          <li className="lang themeLi" key="theme2li3">
                            التقنيات الزراعية
                          </li>
                          <li className="lang themeLi" key="theme2li4">
                            التغير المناخي و سبل التخفيف و التاقلم
                          </li>
                          <li className="lang themeLi" key="theme2li5">
                            الغذاء و الاستدامة
                          </li>
                          <li className="lang themeLi" key="theme2li6">
                            الامن و السيادة الغذائية
                          </li>
                          <li className="lang themeLi" key="theme2li7">
                            التكثيف الزراعي
                          </li>
                          <li className="lang themeLi" key="theme2li8">
                            الإنتاج الحيواني
                          </li>
                          <li className="lang themeLi" key="theme2li9">
                            العلف و الأنماط الرعوية
                          </li>
                          <li className="lang themeLi" key="theme2li10">
                            الاستيطان الريفي
                          </li>
                        </ul>
                        <h5
                          className="card-subtitle mb-2 lang"
                          key="themeHeader3"
                        >
                          المحور الثالث : المدن الذكية و التخطيط العمراني و
                          التنمية المستدامة
                        </h5>
                        <ul className="themeList">
                          <li className="lang themeLi" key="theme3li1">
                            تخطيط المدن و المدن الذكية
                          </li>
                          <li className="lang themeLi" key="theme3li2">
                            المدن الخضراء
                          </li>
                          <li className="lang themeLi" key="theme3li3">
                            التهيئة الترابية
                          </li>
                          <li className="lang themeLi" key="theme3li4">
                            شبكات النقل
                          </li>
                          <li className="lang themeLi" key="theme3li5">
                            الاتصالات السلكية واللاسلكية
                          </li>
                          <li className="lang themeLi" key="theme3li6">
                            إدارة الشريط الساحلي
                          </li>
                          <li className="lang themeLi" key="theme3li7">
                            علم الآثا رو السياحة
                          </li>
                          <li className="lang themeLi" key="theme3li8">
                            إدارة المدن و المنشئات الصناعية
                          </li>
                        </ul>
                        <h5
                          className="card-subtitle mb-2 lang"
                          key="themeHeader4"
                        >
                          المحور الرابع : الجيولوجيا و علوم الأرض و البيئة
                        </h5>
                        <ul className="themeList">
                          <li className="lang themeLi" key="theme4li1">
                            الجيولوجيا البيئية
                          </li>
                          <li className="lang themeLi" key="theme4li2">
                            جيولوجيا البترول
                          </li>
                          <li className="lang themeLi" key="theme4li3">
                            الجيولوجيا والموارد المعدنية
                          </li>
                          <li className="lang themeLi" key="theme4li4">
                            الجيولوجيا الهندسية
                          </li>
                          <li className="lang themeLi" key="theme4li5">
                            الجيولوجيا الهيكلية
                          </li>
                          <li className="lang themeLi" key="theme4li6">
                            الجيولوجيا والهندسة المدنية
                          </li>
                          <li className="lang themeLi" key="theme4li7">
                            الجيوفيزياء الاستكشافية
                          </li>
                          <li className="lang themeLi" key="theme4li8">
                            جيولوجيا التعدين
                          </li>
                          <li className="lang themeLi" key="theme4li9">
                            الجيولوجيا الاقتصادية
                          </li>
                          <li className="lang themeLi" key="theme4li10">
                            الجيولوجيا البحرية
                          </li>
                          <li className="lang themeLi" key="theme4li11">
                            الجيولوجيا الفيزيائية
                          </li>
                          <li className="lang themeLi" key="theme4li12">
                            الاستشارات الجيولوجية
                          </li>
                        </ul>
                        <h5
                          className="card-subtitle mb-2 lang"
                          key="themeHeader5"
                        >
                          المحور الخامس : الطاقة و الطاقات المتجددة و التحول
                          الطاقي
                        </h5>
                        <ul className="themeList">
                          <li className="lang themeLi" key="theme5li1">
                            النفط والغاز
                          </li>
                          <li className="lang themeLi" key="theme5li2">
                            الطاقة الشمسية
                          </li>
                          <li className="lang themeLi" key="theme5li3">
                            الجيولوجيا وعلوم الأرض
                          </li>
                          <li className="lang themeLi" key="theme5li4">
                            قوة الرياح / طاقة الرياح
                          </li>
                          <li className="lang themeLi" key="theme5li5">
                            طاقة المياه الحارة
                          </li>
                          <li className="lang themeLi" key="theme5li6">
                            تثمين النفايات
                          </li>
                          <li className="lang themeLi" key="theme5li7">
                            التحول الطاقي
                          </li>
                          <li className="lang themeLi" key="theme5li8">
                            الصناعة و الصناعات المنجمية
                          </li>
                        </ul>
                        <h5
                          className="card-subtitle mb-2 lang"
                          key="themeHeader6"
                        >
                          المحور السادس : الامن و الدفاع
                        </h5>
                        <ul className="themeList">
                          <li className="lang themeLi" key="theme6li1">
                            تطبيقات نظم المعلومات الجغرافية الامنية
                          </li>
                          <li className="lang themeLi" key="theme6li2">
                            الهجرة و النزوح
                          </li>
                          <li className="lang themeLi" key="theme6li3">
                            الهجرة الغير نظامية و التحولات الإقليمية
                          </li>
                          <li className="lang themeLi" key="theme6li4">
                            الجيوماتيك لدعم اتخاذ القرار
                          </li>
                          <li className="lang themeLi" key="theme6li5">
                            الاستشعار عن بعد الفضائي
                          </li>
                          <li className="lang themeLi" key="theme6li6">
                            الاستشعار عن بعد الجوي
                          </li>
                          <li className="lang themeLi" key="theme6li7">
                            انظمة تحديد المواقع الجغرافية
                          </li>
                          <li className="lang themeLi" key="theme6li8">
                            خرائط الواب و خرائط المحمولة
                          </li>
                          <li className="lang themeLi" key="theme6li9">
                            المراقبة البحرية و الحدودية
                          </li>
                        </ul>
                        <h5
                          className="card-subtitle mb-2 lang"
                          key="themeHeader7"
                        >
                          المحور السابع : الصحة و التعليم و الخدمات الاجتماعية
                        </h5>
                        <ul className="themeList">
                          <li className="lang themeLi" key="theme7li1">
                            التطبيقات في مجال الصحة و مجابهة الأوبئة
                          </li>
                          <li className="lang themeLi" key="theme7li2">
                            التمدرس و التعليم
                          </li>
                          <li className="lang themeLi" key="theme7li3">
                            التعليم الافتراضي و عن بعد
                          </li>
                          <li className="lang themeLi" key="theme7li4">
                            الهجرة و النزوح
                          </li>
                          <li className="lang themeLi" key="theme7li5">
                            الهجرة و النزوح / اللجوء و العمل الإنساني
                          </li>
                          <li className="lang themeLi" key="theme7li6">
                            مجابهة الفقر و المساعدات الاجتماعية
                          </li>
                        </ul>
                        <h5
                          className="card-subtitle mb-2 lang"
                          key="themeHeader8"
                        >
                          المحور الثامن : مواضيع تقنية / الجغرافية الرقمية و نظم
                          المعلومات الجغرافية و الذكاء الاصطناعي الجغرافي
                        </h5>
                        <ul className="themeList">
                          <li className="lang themeLi" key="theme8li1">
                            تطبيقات الجغرافيا الرقمية و الذكاء الاصطناعي
                            الجغرافي
                          </li>
                          <li className="lang themeLi" key="theme8li2">
                            الذكاء الاصطناعي الجغرافي
                          </li>
                          <li className="lang themeLi" key="theme8li3">
                            التطورات في الجغرافيا الرقمية
                          </li>
                          <li className="lang themeLi" key="theme8li4">
                            رسم الخرائط والنمذجة الرقمية المتقدمة
                          </li>
                          <li className="lang themeLi" key="theme8li5">
                            تطبيقات المحاكاة الجغرافية
                          </li>
                          <li className="lang themeLi" key="theme8li6">
                            نظم المعلومات الجغرافية
                          </li>
                          <li className="lang themeLi" key="theme8li7">
                            إدارة وتحليل البيانات المكانية الرقمية
                          </li>
                          <li className="lang themeLi" key="theme8li8">
                            استخدام البيانات المكانية الرقمية في اتخاذ القرار
                          </li>
                          <li className="lang themeLi" key="theme8li9">
                            البيانات الجغرافية الضخمة والبيانات الوصفية
                          </li>
                          <li className="lang themeLi" key="theme8li10">
                            الالتقاط الإلكتروني للبيانات المكانية
                          </li>
                          <li className="lang themeLi" key="theme8li11">
                            تخزين البيانات الجغرافية
                          </li>
                          <li className="lang themeLi" key="theme8li12">
                            معالجة البيانات الجغرافية وتحليلها
                          </li>
                          <li className="lang themeLi" key="theme8li13">
                            مبادئ ومفاهيم الجغرافيا الرقمية
                          </li>
                          <li className="lang themeLi" key="theme8li14">
                            المهارات الأساسية في نظم المعلومات الجغرافية
                          </li>
                          <li className="lang themeLi" key="theme8li15">
                            التطبيقات الحكومية والصناعية والاقتصادية
                          </li>
                          <li className="lang themeLi" key="theme8li16">
                            الخدمات البلدية والصحة
                          </li>
                          <li className="lang themeLi" key="theme8li17">
                            التقنيات الجيومكانية في العلوم الطبيعية والاجتماعية
                          </li>
                        </ul>
                        <h5
                          className="card-subtitle mb-2 lang"
                          key="themeHeader9"
                        >
                          المحور التاسع المصادر المفتوحة – الحرة – لتكنولوجيات
                          الجيوماتيكس و علوم الفضاء و الذكاء الاصطناعي الجغرافي
                        </h5>
                        <ul className="themeList">
                          <li className="lang themeLi" key="theme8li1">
                            الاستشعار عن بعد مفتوح المصدر
                          </li>
                          <li className="lang themeLi" key="theme8li2">
                            نظم المعلومات الجغرافية مفتوحة المصدر
                          </li>
                          <li className="lang themeLi" key="theme8li3">
                            خرائط الويب مفتوحة المصدر
                          </li>
                          <li className="lang themeLi" key="theme8li4">
                            تطبيقات الهاتف المحمول مفتوحة المصدر
                          </li>
                          <li className="lang themeLi" key="theme8li5">
                            البيانات المفتوحة والتحليلية
                          </li>
                          <li className="lang themeLi" key="theme8li6">
                            البيانات الضخمة
                          </li>
                          <li className="lang themeLi" key="theme8li7">
                            البرمجيات المفتوحة
                          </li>
                          <li className="lang themeLi" key="theme8li8">
                            برمجيات الذكاء الاصطناعي الجغرافي
                          </li>
                        </ul>
                      </CardSubtitle>
                    </TabPane>
                    <TabPane tabId="comittees">
                      <h4 className="lang h4GeoTunis" key="comitteesH1" style={{color: "#cf0202" }}>
                        المجلس العلمي الفخري للمؤتمر
                      </h4>
                      <ul ref={this.ulRef}>
                        <li className="lang" key="honorary1">
                          اعضاء اللجنة التحضيرية لليوم الإقليمي لطلبة و اقسام
                          الجيوماتيك
                        </li>
                        <li className="lang" key="honorary2">
                          البروفيسير رونالد فرانسيس أبلر - رئيس الاتحاد الدولي
                          للجغرافيا و رئيس الجمعية الجغرافية الامركية سابقا
                        </li>
                        <li className="lang" key="honorary3">
                          البروفيسور السيد سيد الحسيني - رئيس الجمعية الجغرافية
                          المصرية سابقا
                        </li>
                        <li className="lang" key="honorary4">
                          البروفيسور سركيس فدعوس – رئيس الاتحاد العربي للمساحة –
                          نقيب الطوبوغرافيين المجازين بلبنان – عميد كلية الهندسة
                          بالجامعة الكندية اللبنانية
                        </li>
                        <li className="lang" key="honorary5">
                          البروفيسور جورج جارتنر الرئيس السابق للرابطة الدولية
                          للكارتوغرافيا
                        </li>
                      </ul>
                      <h4 className="lang h4GeoTunis" key="comitteesH2" style={{color: "#cf0202" }}>
                        اللجنة التحضيرية و العلمية
                      </h4>
                      <ul ref={this.ulRef}>
                        <li className="lang" key="organizational1">
                          رئيس اللجنة التحضيرية العليا : د. محمد العياري / رئيس
                          الاتحاد الاوروعربي للجيوماتيك - رئيس الجمعية التونسية
                          للاعلام الجغرافي الرقمي
                        </li>
                        <li className="lang" key="organizational2">
                          رئيس المجلس العلمي – البروفيسور سلوى السعيدي – منسق
                          اقسام الجيوماتيك بكلية العلوم بتونس
                        </li>
                        <li className="lang" key="organizational3">
                          المشرف العام عن معرض جيو تونس المهندس أحمد السافي/
                          خبير ومستشار دولي في البيانات والتقنيات الجغرافية
                          المكانية للتطبيقات المدنية والدفاعية
                        </li>
                      </ul>
                      <h4 className="lang h4GeoTunis" key="comitteesH3" style={{color: "#cf0202" }}>
                        اللجنة العلمية العليا للمنتدى
                      </h4>
                      <ul ref={this.ulRef}>
                      <li className="lang" key="high1">
                      د. علي بن عبدالله الدوسري - رئيس مجلس إدارة الجمعية الجغرافية السعودية
                        </li>
                        <li className="lang" key="high2">
                          البروفيسير جان دومط - رئيس قسم الجغرافيا - الجامعة
                          اللبنانية لبنان
                        </li>
                        <li className="lang" key="high3">
                          البروفيسير محمد سيرتو – المغرب
                        </li>
                        <li className="lang" key="high4">
                          الدكتور نجيب بالحبيب – رئيس المدرسة العليا للتكلوجيا و
                          إدارة الاعمال - تونس
                        </li>
                        <li className="lang" key="high5">
                          البروفيسر الان اثمان – فرنسا
                        </li>
                        <li className="lang" key="high5">
                          <li className="lang" key="high6">
                          د. محمد بن سعد المقرّي -  قسم الجغرافيا بجامعة الملك سعود - الرياض - المملكة العربية السعودية
                        </li>
                        </li>
                        <li className="lang" key="high8">
                          الدكتور عبد القادر رحيم مستشار الامن السبراني - ليبيا
                        </li>
                        <li className="lang" key="high9">
                          البروفيسر حسين نشنيش – الجزائر
                        </li>
                        <li className="lang" key="high10">
                          البروفيسر فيليب دي يوا - الاتحاد الاورومتوسطي للبحث
                          العلمي و التجديد التكنولوجي
                        </li>
                        <li className="lang" key="high11">
                          البروفيسير حيدر هجو السنوسي - السودان
                        </li>
                        <li className="lang" key="high12">
                          البروفيسر عبد صالح فياض – رئيس قسم الجيولوجيا
                          التطبيقية كلية العلوم جامعة الانبار - العراق
                        </li>
                        <li className="lang" key="high13">
                          البيروفيسير نعيمة العيساوي - المغرب
                        </li>
                        <li className="lang" key="high14">
                          البروفيسير رقية امين العاني / الجامعة العراقية -
                          العراق
                        </li>
                        <li className="lang" key="high15">
                          الدكتور منير ارجدال - المغرب
                        </li>
                        <li className="lang" key="high16">
                          البروفيسير عمر الشرميطي - تونس
                        </li>
                        <li className="lang" key="high17">
                          البروفيسور عثمان شركس – فلسطين
                        </li>
                      </ul>
                      <h4 className="lang h4GeoTunis" key="comitteesH4" style={{color: "#cf0202" }}>
                        اعضاء اللجنة العلمية العليا
                      </h4>
                      <ul ref={this.ulRef}>
                        <li className="lang" key="members1">
                          الدكتور نعمان بكاري - كلية العلوم / جامعة تونس المنار
                          - تونس
                        </li>
                        <li className="lang" key="members3">
                          الدكتور محمد حمدي – كندا
                        </li>
                        <li className="lang" key="members4">
                          الدكتورة سمر صقر - الجامعة اللبنانية - لبنان
                        </li>
                        <li className="lang" key="members5">
                          الدكتور مفتاح الفتني – الجامعة الاسمرية - ليبيا{" "}
                        </li>
                        <li className="lang" key="members6">
                          الدكتور صيد سفيان - الجمعية الجغرافية الجزائرية -
                          الجزائر
                        </li>
                        <li className="lang" key="members7">
                          الدكتور صالح زرايب - جامعة باتنة - الجزائر
                        </li>
                        <li className="lang" key="members8">
                          الدكتورة لويزة عمروش – الجزائر
                        </li>
                        <li className="lang" key="members9">
                          البروفيسير بية الطياشي - جامعة المنار - تونس
                        </li>
                        <li className="lang" key="members10">
                          البروفيسر علي ابو عزوم - رئيس المركز العربي لدراسات
                          التصحر – ليبيا
                        </li>
                        <li className="lang" key="members11">
                          البروفيسير طارق الراشد - الولايات المتحدة الامركية
                        </li>
                        <li className="lang" key="members12">
                          الدكتور جورج ابي ديوان – الجامعة اللبنانية – لبنان
                        </li>
                        <li className="lang" key="members13">
                          البروفيسير بريس انسلام - فرنسا
                        </li>
                        <li className="lang" key="members14">
                          الدكتور افجيني كيسيلوف – روسيا
                        </li>
                        <li className="lang" key="members15">
                          البروفيسر محمد لامة – ليبيا
                        </li>
                        <li className="lang" key="members16">
                          الدكتور امين بركة – جامعة الملك فيصل - تشاد
                        </li>
                        <li className="lang" key="members17">
                          المهندس محمد البرقاوي – اكساد
                        </li>
                        <li className="lang" key="members18">
                          البروفيسر عبد العزيز داود - جامعة صفاقس - تونس
                        </li>
                        <li className="lang" key="members19">
                          الدكتور صديق نورين - المملكة العربية السعودية
                        </li>
                        <li className="lang" key="members20">
                          الدكتورة فاطمة طقطق – تونس
                        </li>
                        <li className="lang" key="members21">
                          الدكتورة أسماء الكتبي - رئيس الجمعية الجغرافية
                          الامارتية – الامارات العربية المتحدة
                        </li>
                      </ul>
                      <h4 className="lang h4GeoTunis" key="comitteesH5" style={{color: "#cf0202" }}>
                        اعضاء اللجنة التحضيرية
                      </h4>
                      <ul ref={this.ulRef}>
                        <li className="lang" key="memberso1">
                          المهندس حلمي اللموشي
                        </li>
                        <li className="lang" key="memberso2">
                          المهندس عماد بالهاشمي – تونس
                        </li>
                        <li className="lang" key="memberso3">
                          الأستاذ محمد ادريز – ليبيا
                        </li>
                        <li className="lang" key="memberso4">
                          الدكتور منير ارجدال - المغرب
                        </li>
                        <li className="lang" key="memberso5">
                          الاستاذ عبد الباسط الخضراوي – تونس
                        </li>
                        <li className="lang" key="memberso6">
                          الأستاذ امين بن عثمان – تونس
                        </li>
                        <li className="lang" key="memberso7">
                          الاستاذ علي ناجي – تونس
                        </li>
                        <li className="lang" key="memberso8">
                          الاستاذة دليلة عواني – تونس
                        </li>
                        <li className="lang" key="memberso9">
                          الاستاذة شيماء الدريدي – تونس
                        </li>
                        <li className="lang" key="memberso10">
                          الاستاذة هالة السعيدي – تونس
                        </li>
                        <li className="lang" key="memberso12">
                          الأستاذ مخلص السويسي – تونس
                        </li>
                        <li className="lang" key="memberso13">
                          الأستاذ محسن بالعربي – تونس
                        </li>
                        <li className="lang" key="memberso14">
                          الأستاذة حنان بو علاق – تونس
                        </li>
                      </ul>
                    </TabPane>
                    <TabPane tabId="callpaper">
                      <p className="lang" key="paperp1">
                        تتشرف اللجنة التحضيرية للدورة ال 16 للمؤتمر العلمي
                        الدولي لتطبيقات نظم المعلومات الجغرافية و الجيو فضاء جيو
                        تونس 2024 بدعوة الزملاء والزميلات الراغبين في تقديم ورقة
                        عمل لإرسال ملخصات بحوثهم و اعمالهم إلى الكتابة العامة
                        للمؤتمر في أجل أقصاه 30 سبتمبر 2024 ويجب على الراغبين
                        بالمشاركة بورقة عمل إتباع التعليمات التالية في كتابة
                        الملخص
                      </p>
                      <ul ref={this.ulRef}>
                        <li className="lang" key="paperi1">
                          يمكن تقديم الملخص باللغات التالية : الفرنسية / العربية
                          / الأنقليزية
                        </li>
                        <li className="lang" key="paperi2">
                          الكلمات الدالة – يجب أن لا يتجاوز الملخص 300 كلمة –
                          يجب أن لا تكون ورقة العمل قد قدمت سابقا بأحدى
                          المؤتمرات أو نشرت بأحد المجلات أو المواقع
                        </li>
                        <li className="lang" key="paperi3">
                          .حجم الملصق: A0. يجوز للباحث اختيار الحجم المناسب
                          للملصق
                        </li>
                      </ul>
                      <p className="lang" key="paperp2">
                        والدعوة مفتوحة إلى الراغبين بالمشاركة لإرسال أعمالهم في
                        أجل لا يتعدى 30 – 09 - 2023
                      </p>
                    </TabPane>
                    <TabPane tabId="importantdates">
                      <Table bordered ref={this.tabRef}>
                        <tbody>
                          <tr>
                            <td
                              scope="col"
                              className="lang important"
                              key="importante1"
                            >
                              آخر أجل لقبول الملخصات
                            </td>
                            <td scope="col" className="lang date" key="date1">
                              30 سبتمبر 2024
                            </td>
                          </tr>
                          <tr>
                            <td
                              scope="col"
                              className="lang important"
                              key="importante2"
                            >
                              آخر اجل لقبول الأعمال كاملة
                            </td>
                            <td scope="col" className="lang date" key="date2">
                              18 نوفمبر 2024
                            </td>
                          </tr>
                          <tr>
                            <td
                              scope="col"
                              className="lang important"
                              key="importante3"
                            >
                              آخر أجل لقبول الملصقات الحائطية
                            </td>
                            <td scope="col" className="lang date" key="date3">
                              6 نوفمبر 2024
                            </td>
                          </tr>
                          <tr>
                            <td
                              scope="col"
                              className="lang important"
                              key="importante4"
                            >
                              آخر أجل لقبول عروض
                            </td>
                            <td
                              col="col"
                              className="lang important"
                              key="date4"
                            >
                              6 نوفمبر 2024
                            </td>
                          </tr>
                          <tr>
                            <td
                              scope="col"
                              className="lang date"
                              key="importante5"
                            >
                              آخر أجل لقبول المشاركة بالحضور
                            </td>
                            <td scope="col" className="lang date" key="date5">
                              10 نوفمبر 2024
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </TabPane>
                    <TabPane tabId="exhibition">
                      <CardTitle>
                        <h4 className="lang h4GeoTunis" key="exihibitionH1">
                          معرض دولي لتقنيات نظم المعلومات الجغرافية و الجيو فضاء
                          و الذكاء الاصطناعي الجغرافي
                        </h4>
                      </CardTitle>
                      <p className="lang" key="exhibitionP1">
                        معرض جيو تونس الخامس عشر يجذب أهم منتجي ومصنعي ومقدّمي
                        خدمات تكنولوجيا الجيو فضاء ونظم المعلومات الجغرافية عبر
                        العالم. وهو فرصة أمام منتجي ومصنعي ومسدي خدمات تكنولوجيا
                        الجيو فضاء ونظم المعلومات الجغرافية للقاء مستخدمي وطالبي
                        هذه التكنولوجيا، وهي فرصة لتقديم آخر التكنولوجيا
                        والإضافات في مستوى البرمجيات والمعدات وتطوير الحلول
                        والقدرات وبناء شراكة بين المنتجين والمستخدمين
                      </p>
                      <CardTitle>
                        <h4 className="lang h4GeoTunis" key="exihibitionH2">
                          لماذا تشارك بالمعرض؟
                        </h4>
                      </CardTitle>
                      <p className="lang" key="exhibitionP2">
                        معرض جيو تونس السادس عشر هو جزء هام من فعاليات المؤتمر
                        الدولي جيو تونس وسيكون مصحوبا بورش عمل تطبيقية وبرامج
                        تدريبية وجلسات علمية وحوارات بين المستخدمين والمنتجين
                        لتكنولوجيا المعلومة المكانية , وسيسلط الضوء على المعدات
                        والخدمات و التطبيقات و البرمجيات التي تقدّمها الشركات
                        العارضة ويفتح المجال للشركات لتنظيم ورشة عمل لعرض
                        إنتاجها أمام المستخدمين
                      </p>
                      <CardTitle>
                        <h4 className="lang h4GeoTunis" key="exihibitionH3">
                          مساحة الجناح
                        </h4>
                      </CardTitle>
                      <p className="lang" key="exhibitionPrice">
                        900 أورو للجناح الواحد
                      </p>
                      <ul ref={this.ulRef}>
                        <li className="lang" key="exihibitionLi1">
                          الجناح في المعرض يحتوي على طاولة وكرسيين / اتصال
                          كهربائي / اتصال إنترنت واي فاي
                        </li>
                        <li className="lang" key="exihibitionLi2">
                          2 شارات دخول
                        </li>
                        <li className="lang" key="exihibitionLi3">
                          استراحة قهوة
                        </li>
                        <li className="lang" key="exihibitionLi4">
                          تخصيص فضاء لتنظيم ورش عمل ان رغب للتسويق لمنتجه او
                          مؤسسته / ضمن توقيت مخصص ب25 دقيقة و يمكن الزيادة حسب
                          الطلب
                        </li>
                        <li className="lang" key="exihibitionLi5">
                          التكريم و شهائد المشاركة
                        </li>
                      </ul>
                      <p className="lang" key="exhibitionInfo">
                        للحجز يمكنكم مراسلة اللجنة التحضيرية على الايميل التالي
                        geoconf2@gmail.com/ atignassotiation@gmail.com{" "}
                      </p>
                    </TabPane>
                    <TabPane tabId="security">
                      <center>
                        <h5>
                          الندوة الدولية
                          <p style={{ color: "#ab0d02" }}>
                            إستراتيجية الأمن القومي في ضوء التحولات
                            الجيوإستراتيجية:
                          </p>
                        </h5>
                      </center>
                      <p style={{ color: "#cf0202" }}>
                        <b>
                          <u>الإطار النظري</u>
                        </b>
                      </p>
                      <p>
                        تطرح التحولات العالمية الكبرى اليوم وسلسلة الأحداث
                        المتعاقبة والمتسارعة في الآن نفسه جملة من الإشكاليات و
                        التحديات التي يجب الوقوف عليها وإعادة النظر والتركيز
                        فيها، ذلك أن التغيرات العالمية على جميع الأصعدةتنبؤ
                        بتغير في شكل النظام العالمي وسقوط وتهاوي العديد من البنى
                        القديمة بما قد يفضي إلى المزيد منالتأزم في الوضع الدولي.
                        حيث أن ما يشهده العالم اليوم من أزمات وحروب وبروز
                        للتيارات المتشددة والحركات الارهابية، ينذر بأزمة عالمية
                        عميقة وشاملة، قد تنتهي إلى حرب عالمية ثالثةمن شأنها أن
                        تمس بالأمن القومي للدول. كما أن "حرب العمالقة" التي يدور
                        رحاها على الأراضي الأوكرانية يمكن اعتبارها اليوم الشرارة
                        التي ترتسم من وراءها ملامح العالم الجديد , عالم جديد
                        نشهد فيه الإرهاصات الأولى لأفول منطق القطبية الأحادية ،
                        وولادة أقطاب جديدة قادرة على تغيير المعادلة وفرض توازنات
                        قادرة على استيعاب الاختلافات بين الدول . وبالتالي فمن
                        الضروري إدراك هول ما قد يصيب العالم في ظل التطورات
                        التكنولوجية العسكرية ونظم المعلومات والرقمنة والتقدم
                        الهائل في علوم الفضاء و الفضاء السبراني و تقنيات الذكاء
                        الاصطناعي ، ناهيك عن الأسلحة النووية والبيولوجية
                        الفتاكة، وحرب الفيروسات، و الكوارث الطبيعية و اثار
                        الانهيار المناخي بما يهدد التوازن الكوني، السلم الانساني
                        والدولي والمجتمعي ويطرح في المقابل العديد من القضايا على
                        غرار قضية الأمن القومي باعتبارها غاية قصوى وملحة، تهدف
                        من جهة إلى بحث الجهوزية اللازمة للتعامل مع الأزمات
                        العالمية ودرأ كل المخاطر المترتبة عنها، وترنو من جهة
                        ثانية إلى وضع الاستراتيجيات والمخططات العلمية والعملية
                        لاستشراف ما يمكن أن يحصل. و حيث انه في الوقت الحالي،
                        تمتد مفاهيم الأمن القومي في العلوم السياسية لتشمل مجموعة
                        متنوعة من المجالات بما في ذلك الموارد الطبيعية والأمن
                        المائي والأمن الغذائي والأمن السيبراني وغيرها... وفي
                        إطار انعقاد الدورة السادسة عشر للمؤتمر الدولي لتطبيقات
                        الجيوماتيك و الذكاء الاصطناعي جيو-تونس ،ينظم الاتحاد
                        الأورو- عربي للجيوماتيك ، و مؤسسة المنارة العالمية
                        والجمعية التونسية للإعلام الجغرافي الرقمي ينظمون ندوة
                        دولية تحت عنوان:
                        <center>
                          <b style={{ color: "#cf0202" }}>
                            <u>
                              "إستراتيجية الأمن القومي في ضوء التحولات
                              الجيوإستراتيجية"
                            </u>
                          </b>
                        </center>
                      </p>
                      <p style={{ color: "#cf0202" }}>
                        <b>
                          <u>إشكالية الندوة :</u>
                        </b>
                      </p>
                      <p>
                        سيتناولالندوة واقع ومستقبل الأمن القومي في ضوء التحولات
                        الجيوإستراتيجية ضمن محاور علمية كبرىمرتبطة بمفاهيم الأمن
                        القومي ومضامينه عبر طرحه لجملة من التساؤلات:
                        <ul>
                          <li>
                            كيف يمكن قراءة الصراعات والنزاعات الاقليمية والدولية
                            واقتفاء أثارها وتداعياتها على العديد من المناطق على
                            غرار الشرق الأوسط ودول الساحل والصحراء وشمال
                            افريقيا؟
                          </li>
                          <li>
                            إلى أي مدى كان للتحولات الجيواستراتيجية والنزاعات
                            والحروب من أثر على الأمن الغذائي والصحي والبيئي
                            والمناخي؟
                          </li>
                          <li>
                            ما هي السياسات والتدابير التي يمكن للدول والمجتمع
                            الدولي اتخاذها للتعامل مع ظاهرة الهجرة غير الشرعية
                            والحد منها؟
                          </li>
                          <li>
                            كيف يمكن تلافي هذه المخاطر والتهديدات عبر بحث
                            الاستراتيجيات التي من شأنها المحافظة على الأمن
                            القومي في تجلياته المختلفة؟
                          </li>
                          <li>
                            إلى أي مدى يمكن للتشريعات والقوانين الوطنية والدولية
                            والمنظومات الأمنية أن تستجيب الى الأمن القومي في ظل
                            تنامي ظاهرة الهجرة والجريمة والإرهاب ؟
                          </li>
                          <li>
                            كيف يمكن للدول والمؤسسات حماية بياناتهم وأنظمتهم
                            السيبرانية من التهديدات السيبرانية المتقدمة مثل
                            الهجمات الهجينة والهجمات الصاعقة؟
                          </li>
                          <li>
                            كيف يمكن قراءة التهديدات السيبرانية في عصر التطور
                            التكنولوجي وإلى أي مدى يمكن للاستراتيجيات الحالية
                            مجابهتها ؟
                          </li>
                          <li>
                            ما هي التحديات الأخلاقية والقانونية المرتبطة بتطبيق
                            الذكاء الاصطناعي في مجالات الأمن والدفاع؟
                          </li>
                        </ul>
                      </p>
                      <p style={{ color: "#cf0202" }}>
                        <b>
                          <u>محاور الندوة :</u>
                        </b>
                      </p>
                      <u style={{ color: "#cf0202" }}>
                        المحور الأول:الصراعات والنزاعات الإقليمية والدولية
                        وتداعياتها على الأمن القومي
                      </u>
                      <ol type="1">
                        <li>
                          التداعيات الجيواستراتجية للحرب الروسية الأوكرانية
                        </li>
                        <li>شرق المتوسط وصراع ترسيم الحدود والطاقة</li>
                        <li>الساحل والصحراء وعدم الاستقرار </li>
                        <li>تواصل الأزمة الليبية وتداعياتها على أمن المنطقة</li>
                        <li>
                          الشرق الاوسط والخليج العربيبين الصرعات والانفراج
                        </li>
                      </ol>
                      <u style={{ color: "#cf0202" }}>
                        المحور الثاني: الأبعاد الصحية والتغير المناخي
                      </u>
                      <ol type="1">
                        <li>
                          <u>التغير المناخي وآليات التأقلم</u>
                        </li>
                        <li>
                          <u>الأمن الغذائي</u>
                        </li>
                        <li>
                          <u>الأمن الصحي</u>
                        </li>
                        <li>
                          <u>الأمن البيئي</u>
                        </li>
                      </ol>
                      <u style={{ color: "#cf0202" }}>
                        المحور الثالث: أمن الموارد الطبيعية وإدارة المخاطر
                      </u>
                      <ol type="1">
                        <li>
                          <u>الأمن المائي</u>
                        </li>
                        <li>
                          <u>إدارة الموارد الطبيعية</u>
                        </li>
                        <li>
                          <u>إدارة المخاطر والكوارث الطبيعية و البشرية</u>
                        </li>
                        <li>
                          <u>الأمن الاقتصادي وتحديات الطاقة</u>
                        </li>
                        <li>
                          <u>السلم والأمن الاجتماعي</u>
                        </li>
                      </ol>
                      <u style={{ color: "#cf0202" }}>
                        المحور الرابع : التكنولوجيا الرقمية والامن السيبراني
                      </u>
                      <ol>
                        <li>
                          <u>الأمن السبراني وتحديات الجريمة الاكترونية</u>
                        </li>
                        <ul>
                          <li>اثار التهديدات السيبرانية على الأمن القومي</li>
                          <li>أمن البنية التحتية الحيوية والأمن القومي:</li>
                          <ul>
                            <li>
                              دور أمان البنية التحتية الحيوية (الكهرباء، المياه،
                              النقل) في الحفاظ على الأمن القومي وضمان استدامته.
                            </li>
                            <li>
                              الهجمات السيبرانية استهداف هذه البنية التحتية
                              وتأثيرها على الأمن القومي؟
                            </li>
                          </ul>
                          <li>التجسس والتهديدات السيبرانية للدولة:</li>
                          <li>
                            تعزيز التعاون الدولي في مكافحة التهديدات السيبرانية:
                          </li>
                          <li>التوعية والتدريب في مجال الأمن السيبراني:</li>
                          <li>تنمية القدرات الوطنية في مجال الأمن السيبراني</li>
                          <li>
                            التحديات القانونية والأخلاقية في مجال الأمن
                            السيبراني
                          </li>
                        </ul>
                        <li>
                          <u>
                            التطور التكنولوجي وعصر قواعد البيانات والبيانات
                            الضخمة
                          </u>
                        </li>
                        <li>
                          <u>عسكرة الفضاء والسيادة الوطنية</u>
                        </li>
                        <li>
                          <u>الجيوفضاء وتقنيات نظم المعلومات</u>
                        </li>
                        <li>
                          <u>الذكاء الاصطناعي وتحديات الامن القومي </u>
                        </li>
                      </ol>
                      <ul>
                        <li>تحديات الأمن القومي المرتبطة بالذكاء الاصطناعي</li>
                        <li>
                          التهديدات الأمنية المحتملة التي يمكن أن يشكلها الذكاء
                          الاصطناعي
                        </li>
                        <li>
                          استخدامات الذكاء الاصطناعي في الهجمات السيبرانية
                          والتجسس.
                        </li>
                        <li>
                          التحديات القانونية والأخلاقية المرتبطة بالاستخدام
                          العسكري للذكاء الاصطناعي.
                        </li>
                        <li>
                          استراتيجيات تعزيز الأمن القومي من خلال الذكاء
                          الاصطناعي.
                        </li>
                        <li>
                          تطوير تقنيات الذكاء الاصطناعي لمراقبة وحماية الحدود
                          الوطنية.
                        </li>
                        <li>
                          استخدام الذكاء الاصطناعي في التحليل التكتيكي
                          والاستخباراتي
                        </li>
                        <li>
                          تعزيز الجاهزية السيبرانية والقدرة على الاستجابة
                          للتهديدات السيبرانية باستخدام الذكاء الاصطناعي.
                        </li>
                        <li>
                          التعاون الدولي والتنسيق في مجال الأمن القومي والذكاء
                          الاصطناعي
                        </li>
                        <li>
                          المستقبل والتطورات المتوقعة في تكنولوجيا الذكاء
                          الاصطناعي وأثرها على الأمن القومي
                        </li>
                        <li>
                          الابتكارات المستقبلية في مجال الذكاء الاصطناعي
                          وتأثيرها على التهديدات والفرص الأمنية.
                        </li>
                        <li>
                          تأثير تطورات التعلم الآلي والتعلم العميق على
                          استراتيجيات الأمن القومي..
                        </li>
                        <li>
                          تحليل مستقبلي لكيفية تكيف الدول مع التحولات
                          التكنولوجية في مجال الذكاء الاصطناعي
                        </li>
                      </ul>
                      <u style={{ color: "#cf0202" }}>
                        المحور الخامس : الهجرة والجريمة والإرهاب وآليات تعزيز
                        الأمن الداخلي وتطوير المنظومات الأمنية للمعابر والحدود
                      </u>
                      <ol>
                        <li>التحديات المرتبطة بالهجرة:</li>
                        <ul>
                          <li>زيادة أعداد المهاجرين وأسبابها.</li>
                          <li>تأثيرات الهجرة غير الشرعية على الأمان الداخلي</li>
                        </ul>
                        <li>الجريمة والهجرة</li>
                        <ul>
                          <li>علاقة بين الجريمة والمهاجرين.</li>
                          <li>
                            الظواهر الجريمية المرتبطة بالهجرة غير الشرعية.
                          </li>
                        </ul>
                        <li>الإرهاب والهجرة:</li>
                        <ul>
                          <li>تصاعد التهديدات الإرهابية وتوجيهها للمهاجرين</li>
                          <li>
                            استغلال الجماعات الإرهابية للهجرة كوسيلة للتسلل.
                          </li>
                        </ul>
                        <li>تعزيز الأمن الداخلي:</li>
                        <ul>
                          <li>
                            دور الأجهزة الأمنية والشرطة في مكافحة الجريمة
                            والإرهاب.
                          </li>
                          <li>
                            التعاون الدولي لمكافحة الجريمة ومشاركة المعلومات.
                          </li>
                        </ul>
                        <li>تطوير المنظومات الأمنية للمعابر والحدود:</li>
                        <ul>
                          <li>تكنولوجيا التأمين والرصد على الحدود.</li>
                          <li>تعزيز الجاهزية والاستعداد لمواجهة التهديدات.</li>
                        </ul>
                      </ol>
                      <u style={{ color: "#cf0202" }}>
                        المحور السادس : التعليم والبحث العلمي:
                      </u>
                      <ol>
                        <li>
                          <u>
                            دور التعليم والبحث العلمي في تطوير القدرات والمعرفة
                            في مجال الأمن القومي.
                          </u>
                        </li>
                        <li>
                          <u>
                            استعراض أفضل الممارسات في تعزيز التعليم والبحث
                            العلمي في هذا المجال.
                          </u>
                        </li>
                      </ol>
                      <u style={{ color: "#cf0202" }}>
                        المحور السابع : حقوق الإنسان والأمن القومي:
                      </u>
                      <ol>
                        <li>
                          <u>
                            كيفية تحقيق توازن بين الأمن القومي وحقوق الإنسان
                            وحريات المواطنين.
                          </u>
                        </li>
                        <li>
                          <u>
                            استعراض التحديات القانونية والأخلاقية المرتبطة
                            بممارسات الأمن القومي.
                          </u>
                        </li>
                      </ol>
                      <u style={{ color: "#cf0202" }}>
                        المحور الثامن : القوانين والتشريعات المحلية والدولية و
                        دورها في تعزيز مقومات الامن القومي
                      </u>
                      <li>
                        <u>
                          دور التحالفات الإقليمية والدولية في دعم استراتيجيات
                          الأمن القومي.
                        </u>
                      </li>
                      <li>
                        <u>
                          كيفية تعزيز التعاون الدولي في مجالات مثل التجارة
                          والأمن البيئي والتنمية لتعزيز
                        </u>
                      </li>
                      <li>
                        <u>
                          القوانين و التشريعات و الاتفاقيات الثنائية و الدولية
                        </u>
                      </li>
                      <p style={{ color: "#cf0202" }}>
                        <b>
                          <u>أهداف الندوة:</u>
                        </b>
                      </p>
                      <ol>
                        <li>
                          فهم التحديات الحالية: تحليل ومناقشة التحولات
                          الجيوإستراتيجية والتحديات الأمنية التي تطرحها تحديات
                          الهجرة الغير شرعية والأمن السيبراني والذكاء الاصطناعي
                          و التغيرات المناخية و الكوارث الطبيعية ...الخ.....
                        </li>
                        <li>
                          تبادل المعرفة والخبرات: توفير منصة للخبراء والباحثين
                          وصناع القرار لمشاركة أفضل الممارسات والأبحاث والتجارب
                          في مجالات الأمن القومي ذات الصلة
                        </li>
                        <li>
                          تطوير استراتيجيات فعالة: توجيه النقاش نحو تطوير
                          استراتيجيات ملموسة وفعالة للتعامل مع التحديات الكبرى
                          لتعزيز مقومات الأمن القومي.
                        </li>
                        <li>
                          التعاون الدولي: مناقشة سبل تعزيز التعاون والتنسيق بين
                          الدول والمنظمات الدولية لمواجهة هذه التحديات على
                          الصعيدين الإقليمي والدولي.
                        </li>
                        <li>
                          تطوير السياسات: تقديم توصيات سياسية ملموسة تساعد
                          الحكومات في تطوير استجاباتها لهذه التحديات.
                        </li>
                        <li>
                          تعزيز الوعي: زيادة الوعي العام حول أهمية مواجهة
                          التحديات المذكورة.
                        </li>
                        <li>
                          بناء شبكات: التشجيع على بناء علاقات وشبكات تعاون بين
                          المشاركين من مختلف الجهات والمجتمعات المهتمة بالأمن
                          القومي.
                        </li>
                        <li>
                          البحث في حلول مبتكرة: تشجيع المشاركين على تقديم حلول
                          مبتكرة وتكنولوجيا جديدة للتعامل مع هذه التحديات.
                        </li>
                      </ol>

                      <p style={{ color: "#cf0202" }}>
                        <b>
                          <u>شروط المشاركة</u>
                        </b>
                      </p>
                      <p>
                        المشاركة مفتوحة للخبراء و المختصين و مراكز البحث و مكاتب
                        الدراسات و الجامعات و جميع المعنيين بمجالات الامن القومي
                        سوى بالحضور او بتقديم عمل علمي او تصور من شانه رفد
                        الندوة بكل ما هو جديد و تخضع المشاركات لنفس الشروط
                        المعتمدة بالمؤتمر الدولي جيو تونس .
                      </p>
                      <p style={{ color: "#cf0202" }}>
                        <b>
                          <u>قبول الملخصات</u>
                        </b>
                      </p>
                      <p>
                        لجميع الراغبين بتقديم ورقة عمل ارسال ملخصات اعمالهم في
                        الاجال القانونية لجيو تونس
                      </p>
                    </TabPane>
                    <TabPane tabId="libya">
                      <center>
                        <h5>
                          يوم ليبيا لنظم المعلومات الجغرافية و الاستشعار عن بعد
                          و الذكاء الاصطناعي بجيو تونس
                        </h5>
                      </center>
                      <p style={{ color: "#cf0202" }}>
                        <b>
                          <u>تمهيــــــــــــــــد:</u>
                        </b>
                      </p>
                      <p>
                        تعتبر نظم المعلومات الجغرافية والذكاء الاصطناعي الجغرافي
                        من التكنولوجيا الحديثة التي تعكف على تحويل طريقة تفكيرنا
                        وتفاعلنا مع العالم الطبيعي والبيئة الجغرافية من حولنا.
                        إن التقدم المستمر في هذين المجالين يفتح أبواباً واسعة
                        للاستفادة من التكنولوجيا في مجموعة متنوعة من التطبيقات،
                        سواء كان ذلك في مجالات البحث العلمي أو تطوير الأعمال أو
                        التخطيط الحضري أو الزراعة أو التنمية المستدامة او إدارة
                        المخاطر ...الخ.... بفضل هذه الإمكانيات الكبيرة والواعدة
                        لنظم المعلومات الجغرافية والذكاء الاصطناعي الجغرافي، و
                        تبعا للنجاح الكبير الذي شهده - يوم ليبيا - خلال مختلف
                        دوراته السابقة فانه يسر منظمي &quot;يوم ليبيا لنظم
                        المعلومات الجغرافية والذكاء الاصطناعي الجغرافي&quot;
                        الإعلان عن الإصدار الجديد لهذا الحدث البارز. سيقام هذا
                        الحدث ضمن الفعاليات الكبرى للدورة 16 من المؤتمر الدولي
                        &quot;جيو تونس&quot; في مدينة الحمامات بتونس من 18 إلى
                        22نوفمبر 2024. هذا الحدث يهدف إلى توفير منصة رائدة
                        للباحثين والمهنيين والمهتمين بمجال نظم المعلومات
                        الجغرافية والذكاء الاصطناعي الجغرافي، حيث يمكنهم تبادل
                        الأفكار والخبرات والتواصل مع أقرانهم من مختلف أنحاء
                        العالم. سيتيح للمشاركين الاطلاع على أحدث التطورات
                        التكنولوجية والبحثية في هذا الميدان، واستكشاف كيفية
                        تطبيقها في مجموعة متنوعة من القطاعات. إن يوم ليبيا لنظم
                        المعلومات سيشهد مجموعة متنوعة من الفعاليات بما في ذلك
                        المحاضرات الرئيسية من قبل خبراء دوليين وورش العمل
                        التفاعلية والجلسات الخاصة بالبحث والتطبيقات العملية.
                        سيكون هناك أيضًا فرصة للمشاركين لتقديم أبحاثهم وأعمالهم
                        والتفاعل مع زملائهم في المجال. إن منظمي هذا الحدث
                        يتوقعون أن يساهم &quot;جيو تونس / يوم ليبيا&quot; في
                        تعزيز التعاون الدولي ونقل المعرفة في مجال نظم المعلومات
                        الجغرافية والذكاء الاصطناعي الجغرافي، وبالتالي، تحقيق
                        فعالية أكبر في استخدام هذه التقنيات للتنمية والتطوير.
                        كما يعتبر يوم ليبيا بجيو تونس فرصة استثمارية و سوق علمية
                        و تكنولوجية رائدة امام منتجي و مستخدمي هذه التكنولوجيات
                        و منصة للتبادل المعرفي و الاستثماري افريقيا و دوليا .
                        نتطلع إلى حضوركم ومشاركتكم في هذا الحدث المثير والمثمر
                        والذي يعد بأن يكون مناسبة استثنائية للتعلم والتبادل
                        والتواصل في هذا الميدان المثير. و الدعوة مفتوحة للمشاركة
                        و رفد المؤتمر و اثراء مضامينه
                        <center>
                          <b>مع تحيات اللجنة التحضيرية</b>
                        </center>
                      </p>
                      <p style={{ color: "#cf0202" }}>
                        <b>
                          <u>أهداف يوم ليبيا</u>
                        </b>
                      </p>
                      <p>
                        <b>
                          دفع الاستثمار و الشراكة من خلال:
                          <ol type="1">
                            <li>
                              دفع التعاون بين المؤسسات الخاصة و العامة و توثيق
                              علاقات التعاون بين المؤسسات الليبية و الشركات
                              الدولية الناشطة في مجال تكنولوجيا نظم المعلومات
                              الجغرافية و الجيو فضاء
                            </li>
                            <li>
                              دعم مجالات التخطيط بالاعتماد على التكنولوجيات
                              الرقمية
                            </li>
                            <li>
                              بناء قاعدة البيانات الجغرافية و ارساء البنية
                              التحتية للبيانات المكانية
                            </li>
                            <li>
                              التأسيس لتنمية شاملة باستخدام تكنولوجيا الجيوفضاء
                              و نظم المعلومات الجغرافية
                            </li>
                            <li>
                              تعميم استخدام تكنولوجيا الاستشعار عن بعد و نظم
                              المعلومات الجغرافية في مختلف القطاعات ذات الأولوية
                              على غرار التطبيقات في المجالات التالية :
                            </li>
                          </ol>
                        </b>
                        المجال الأمني و العسكري , الصحة و مجابهة الأوبئة . مجال
                        الطاقة و النفط , المدن الذكية التخطيط الحضري و العمراني
                        ,إدارة الموارد الطبيعية و المائية ,إدارة البنية التحتية
                        ,إدارة منظومات التصريف الصحي ,إدارة المجال البيئي و
                        التلوث ,إدارة الآثار و المعالم الأثرية الدينية و الحج
                        ,إدارة الاتصالات ,بناء الأطالس و البوابات الجغرافية
                        ,مجال الإحصاء و التعداد السكاني و خرائط الانتخابات
                        ....الخ...
                      </p>
                      <p style={{ color: "#cf0202" }}>
                        <b>
                          <u>المحاور العلمية</u>
                        </b>
                      </p>

                      <b>
                        <ol type="I">
                          <li>
                            تطبيقات نظم المعلومات الجغرافية و الاستشعار عن بعد و
                            الذكاء الاصطناعي في إدارة الكوارث والاستجابة السريعة
                          </li>
                          <ol type="1">
                            <li>التطبيقات الجغرافية في إدارة الكوارث</li>
                            <ul>
                              <li>
                                تطبيقات نظم المعلومات الجغرافية في إدارة الكوارث
                              </li>
                              <li>
                                استخدام الخرائط الرقمية ونظم المعلومات الجغرافية
                                في تحليل المخاطر.
                              </li>
                              <li>دور صور الأقمار الصناعية في رصد الكوارث.</li>
                              <li>دور الاستشعار عن بعد في رصد الكوارث</li>
                              <li>
                                استخدام التكنولوجيا البعيدة المثبتة على الأقمار
                                الصناعية والطائرات بدون طيار للرصد المستمر
                                للمناطق المعرضة للكوارث
                              </li>
                            </ul>
                            <li>الذكاء الاصطناعي وإدارة الكوارث</li>
                            <ul>
                              <li>
                                تطبيقات الذكاء الاصطناعي في التنبؤ بالكوارث.
                              </li>
                              <li>
                                استخدام الذكاء الاصطناعي في تحسين استجابة
                                الكوارث.
                              </li>
                              <li>دور الذكاء الاصطناعي في إدارة الكوارث</li>
                              <li>
                                التعلم الآلي وتحليل البيانات الضخمة في توقع
                                الكوارث وتخطيط الاستجابة.
                              </li>
                            </ul>
                            <li>إدارة الكوارث والتخطيط للاستجابة السريعة</li>
                            <ul>
                              <li>تنظيم وتنسيق الجهود في حالات الكوارث.</li>
                              <li>
                                استراتيجيات تطبيق تكنولوجيا المعلومات في
                                الاستجابة السريعة.
                              </li>
                              <li>
                                استخدام الخرائط الرقمية والنظم الجغرافية لإدارة
                                الموارد والمساعدة في عمليات الإجلاء والإغاثة
                              </li>
                              <li>
                                دور البيانات المكانية والزمنية لدعم اتخاذ
                                القرارات.
                              </li>
                            </ul>
                          </ol>
                          <li>
                            تطبيقات نظم المعلومات الجغرافية و الاستشعار عن بعد
                            في قطاع الخدمات
                          </li>
                          <ul>
                            <li>
                              التطبيقات في مجال الصحة و الاستشفاء و مجابهة
                              الأوبئة
                            </li>
                            <li>التطبيقات في مجال التعليم و التكوين</li>
                            <li>
                              التطبيقات في مجال الإتصالات و التقانة / بناء شبكات
                              الالياف البصرية
                            </li>
                            <li>التطبيقات في مجال التعداد السكاني</li>
                            <li>التطبيقات في مجال النقل و التنقل</li>
                            <li>التطبيقات في مجال السياحة و الآثار</li>
                            <li>التطبيقات في مجال الاجتماعي</li>
                          </ul>
                          <li>
                            تطبيقات نظم المعلومات الجغرافية و الاستشعار عن بعد
                            في مجال الأمن و الدفاع
                          </li>
                          <ul>
                            <li>امن المدن و الافراد و المنشئات</li>
                            <li>امن / مراقية الحدود البرية و البحرية</li>
                            <li>التهريب و الهجرة الغير شرعية</li>
                            <li>الدفاع المدني</li>
                            <li>الحد من الجريمة و مقاومة الإرهاب</li>
                            <li>الامن السبراني و الذكاء الاصطناعي</li>
                          </ul>
                          <li>المدن الذكية و التخطيط العمراني المستدام</li>
                          <li>البيئة و التغير المناخي</li>
                          <li>
                            المجال الزراعي و إدارة الموارد الطبيعية و المائية
                          </li>
                          <li>الطاقة و الطاقة المتجددة و الصناعة و التعدين</li>
                          <li>التخطيط و العمل البلدي</li>
                          <li>إدارة الشريط الساحي</li>
                        </ol>
                      </b>
                      <p style={{ color: "#cf0202" }}>
                        <b>
                          <u>المحتوى العام لليوم الليبي</u>
                        </b>
                      </p>
                      <ul>
                        <li>جلسات علمية / محاضرات و عروض تقنية</li>
                        <li>ورش عمل تقنية</li>
                        <li>ورش تدريبية</li>
                        <li>معرض للشركات و مراكز البحث و الوزارات</li>
                        <li>لقاءات اعمال بي تو بي</li>
                      </ul>
                      <p style={{ color: "#cf0202" }}>
                        <b>
                          <u>المستهدفون من اليوم الليبي</u>
                        </b>
                      </p>
                      <ul>
                        <li>الوزارات و المؤسسات العامة و الخاصة</li>
                        <li>الشركات و مكاتب الدراسات</li>
                        <li>الجامعات و المعاهد مراكز البحث</li>
                        <li>الطلبة و الجامعيين</li>
                        <li>البلديات و المجالس المحلية و الجهوية</li>
                        <li>العاملون بالقطاع الخدمي</li>
                      </ul>
                      <p style={{ color: "#cf0202" }}>
                        <b>
                          <u>اللجنة التحضيرية المشتركة</u>
                        </b>
                      </p>
                      <b>رئاسة المجلس العلمي</b>
                      <ul>
                        <li>
                          د.مفتاح دخيل - الجمعية الجغرافية الليبية : عن ليبيا{" "}
                        </li>
                        <li>
                          د.محمد العياري / الجمعية التونسية للاعلام الجغرافي
                          الرقمي : عن تونس
                        </li>
                      </ul>
                      <b>رئاسة اللجنة التحضيرية</b>
                      <ul>
                        <li>الأستاذ محمد ادريز – جامعة طرابلس عن ليبيا</li>
                        <li>الأستاذة هالة السعيدي – عن تونس</li>
                      </ul>
                      <p style={{ color: "#cf0202" }}>
                        <b>
                          <u>لغات اليوم الليبي</u>
                        </b>
                      </p>
                      <ul>
                        <li>العربية</li>
                        <li>الإنجليزية</li>
                      </ul>
                      <p style={{ color: "#cf0202" }}>
                        <b>
                          <u>تواريخ مهمة</u>
                        </b>
                      </p>
                      <b>
                        <ul>
                          <li>
                            <u>آخر اجل لاستلام ملخصات الاعمال 30 سبتمبر2024</u>
                          </li>
                          <li>
                            <u>أخر اجل للتسجيل للحضور 10 نوفمبر2024</u>
                          </li>
                        </ul>
                      </b>
                    </TabPane>
                    <TabPane tabId="program">
                      <Table bordered ref={this.tabRef}>
                        <tbody>
                          <tr>
                            <td>
                              <img
                                src="./static/img/geotunis16/SGS.jpg"
                                alt="sgs"
                                className="img-thumbnail mx-auto d-block"
                              />
                            </td>
                            <td>
                              <div className="text-center">
                                <p>الجمعية الجغرافية السعودية</p>
                                <p>Saudi Geographical Society (SGS)</p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <img
                                src="./static/img/geotunis16/AUSGEO.jpg"
                                alt="AUSGEO"
                                className="img-thumbnail mx-auto d-block"
                              />
                            </td>
                            <td>
                              <div className="text-center">
                                <p>الاتحاد العربي للمساحة</p>
                                <p>AUS GEO</p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <img
                                src="./static/img/geotunis16/UEAGEO.jpg"
                                width="150"
                                height="150"
                                alt="UEAGEO"
                                className="img-thumbnail mx-auto d-block"
                              />
                            </td>
                            <td>
                              <div className="text-center">
                                <p>الاتحاد الاوروعربي للجيوماتيك</p>
                                <p>UEA-GEO</p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <img
                                src="./static/img/geotunis16/suptech.jpg"
                                alt="suptech"
                                className="img-thumbnail mx-auto d-block"
                              />
                            </td>
                            <td>
                              <div className="text-center">
                                <p>
                                  الجامعة الخاصة للتكنولوجيا و إدارة الاعمال
                                </p>
                                <p>SUPTECH</p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <img
                                src="./static/img/geotunis16/DACFS.png"
                                alt="DACFS"
                                className="img-thumbnail mx-auto d-block"
                              />
                            </td>
                            <td>
                              <div className="text-center">
                                <p>
                                  المركز العربي الدنماركي للدراسات المستقبلية في
                                  كوبنهاكن / الدنمارك
                                </p>
                                <p>DACFS</p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <img
                                src="./static/img/geotunis16/EMS.jpg"
                                alt="EMS"
                                className="img-thumbnail mx-auto d-block"
                              />
                            </td>
                            <td>
                              <div className="text-center">
                                <p>الجمعية الجغرافية الامارتية</p>
                                <p>Emirates Geographical Society</p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <img
                                src="./static/img/geotunis16/IGU.jpg"
                                alt="IGU"
                                className="img-thumbnail mx-auto d-block"
                              />
                            </td>
                            <td>
                              <div className="text-center">
                                <p>الاتحاد الدولي للجغرافيا</p>
                                <p>International Geographical Union</p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <img
                                src="./static/img/geotunis16/ICA.jpg"
                                width="150"
                                height="150"
                                alt="ICA"
                                className="img-thumbnail mx-auto d-block"
                              />
                            </td>
                            <td>
                              <div className="text-center">
                                <p>الرابطة الدولية للكارتوغرافيا</p>
                                <p>ICA</p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <img
                                src="./static/img/geotunis16/SNGS.jpg"
                                alt="SNGS"
                                className="img-thumbnail mx-auto d-block"
                              />
                            </td>
                            <td>
                              <div className="text-center">
                                <p>الجمعية الجغرافية الوطنية السودانية</p>
                                <p>Sudanese National Geographic Society</p>
                              </div>
                            </td>
                          </tr>
                          <tr className="text-center">
                            <td>
                              Euro-Mediterranean Federation for Scientific
                              Research and Technological Innovation
                            </td>
                            <td>
                              الفيدرالية الاورومتوسطية للبحث العلمي و التجديد
                              التكنولوجي
                            </td>
                          </tr>
                          <tr className="text-center">
                            <td>Maghreb Organization GIS and Remote Sensing</td>
                            <td>
                              المنظمة المغاربية لنظم المعلومات الجغرافية و
                              الاستشعار عن بعد
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <img
                                src="./static/img/geotunis16/GSPMAG.jpg"
                                alt="GEO-SP"
                                className="img-thumbnail mx-auto d-block"
                              />
                            </td>
                            <td>
                              <div className="text-center">
                                <p>مجلة منشورات علوم جغرافية</p>
                                <p>Geographical Sciences Publications MAG</p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <img
                                src="./static/img/geotunis16/AECSFMMO.jpg"
                                height="150"
                                width="150"
                                alt="AECSFMMO"
                                className="img-thumbnail mx-auto d-block"
                              />
                            </td>
                            <td>
                              <div className="text-center">
                                <p>
                                  المنظمة الفرنسية للتبادل العلمي و الثقافي مع
                                  المغرب و الشرق الأوسط
                                </p>
                                <p>
                                  The French Organization for Scientific and
                                  Cultural Exchange with Magreb and the Middle
                                  East
                                </p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <img
                                src="./static/img/geotunis16/50africa.jpg"
                                height="150"
                                width="150"
                                alt="50Africa"
                                className="img-thumbnail mx-auto d-block"
                              />
                            </td>
                            <td>
                              <div className="text-center">
                                <p>جمعية افريقيا 50 فرنسا</p>
                                <p>Association Africa 50 / France</p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <img
                                src="./static/img/geotunis16/AFFA.jpg"
                                alt="AMAF"
                                className="img-thumbnail mx-auto d-block"
                              />
                            </td>
                            <td>
                              <div className="text-center">
                                <p>جمعية أصدقاء افريقيا الفرنكوفونية</p>
                                <p>
                                  Association of Friends of Francophone Africa
                                </p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <img
                                src="./static/img/geotunis16/FSG.jpg"
                                alt="FSG"
                                className="img-thumbnail mx-auto d-block"
                              />
                            </td>
                            <td>
                              <div className="text-center">
                                <p>مجلة الندوة للدراسات القانونية</p>
                                <p>Al Nadwa Journal for Legal Studies</p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <img
                                src="./static/img/geotunis16/OGTL.jpg"
                                width="150"
                                height="150"
                                alt="OGTL"
                                className="img-thumbnail mx-auto d-block"
                              />
                            </td>
                            <td>
                              <div className="text-center">
                                <p>نقابة الطوبوغرافيين المجازين بلبنان</p>
                                <p>OGTL</p>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                      <Table bordered ref={this.tabRef}>
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="lang text-center"
                              key="rfHead1"
                              colspan="2"
                            >
                              Media partner
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <img
                                src="./static/img/geotunis16/internationalca.jpg"
                                alt="International_Conference_Alerts "
                                width="200"
                                height="200"
                                className="img-thumbnail mx-auto d-block"
                              />
                            </td>
                            <td>
                              <div className="text-center">
                                <a
                                  href="https://internationalconferencealerts.com/"
                                  target="_blank"
                                >
                                  <p>International Conference Alerts</p>
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </TabPane>
                    <TabPane tabId="fees">
                      <center>
                        <p style={{ color: "#cf0202" }}>
                          <b>
                            <u>رسوم التسجيل بجيوتونس و يوم ليبيا</u>
                          </b>
                        </p>
                      </center>
                      <Table bordered ref={this.tabRef}>
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="lang text-center"
                              key="rfHead1"
                              colspan="2"
                            >
                              رسوم المشاركة مع الاقامة بغرفة مزدوجة
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th className="lang" key="rfcategory1">
                              أعضاء هيئات التدريس و الجامعيين و أعضاء المخابر
                              البحثية
                            </th>
                            <th className="lang" key="fee1">
                              500 اورو
                            </th>
                          </tr>
                          <tr>
                            <th className="lang" key="rfcategory2">
                            أورو	طلبة الدراسات العليا، وجوب تقديم ما يثبت مزاولته للدراسة فقط 
                            </th>
                            <th className="lang" key="fee2">
                              450 اورو
                            </th>
                          </tr>
                          <tr>
                            <th className="lang" key="rfcategory3">
                              الشركات و مؤسسات الرسمية و الدولية
                            </th>
                            <th className="lang" key="fee3">
                              550 اورو
                            </th>
                          </tr>
                          <tr>
                            <th className="lang" key="rfcategory4">
                              حجز جناح بالمعرض مع إقامة لشخص واحد
                            </th>
                            <th className="lang" key="fee4">
                              900 اورو
                            </th>
                          </tr>
                          <tr>
                            <th className="lang" key="rfcategory5">
                              المرافق العائلي بنفس الغرفة
                            </th>
                            <th className="lang" key="fee5">
                              250 اورو
                            </th>
                          </tr>
                        </tbody>
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="lang text-center"
                              key="rfHead2"
                              colspan="2"
                            >
                              رسوم المشاركة مع الاقامة بغرفة لشخص واحد
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th className="lang" key="rfcategory1">
                              أعضاء هيئات التدريس و الجامعيين و أعضاء المخابر
                              البحثية
                            </th>
                            <th className="lang" key="fee6">
                              550 اورو
                            </th>
                          </tr>
                          <tr>
                            <th className="lang" key="rfcategory2">
                              طلبة الدراسات العليا، وجوب تقديم ما يثبت مزاولته
                              للدراسة
                            </th>
                            <th className="lang" key="fee7">
                              500 اورو
                            </th>
                          </tr>
                          <tr>
                            <th className="lang" key="rfcategory3">
                              الشركات و مؤسسات الرسمية و الدولية
                            </th>
                            <th className="lang" key="fee8">
                              550 اورو
                            </th>
                          </tr>
                          <tr>
                            <th className="lang" key="rfcategory4">
                              حجز جناح بالمعرض
                            </th>
                            <th className="lang" key="fee4">
                              900 اورو
                            </th>
                          </tr>
                        </tbody>
                      </Table>
                      <h4 className="lang h4GeoTunis" key="includes" style={{color: "#cf0202" }}>
                      رسوم المشاركة  الخاصة بالتونسيين و المقيمين بتونس  من غير إقامة
                      </h4>
                      <Table bordered ref={this.tabRef}>
                        <tbody>
                          <tr>
                            <th className="lang" key="rfcategory1">
                            أعضاء هيئات التدريس و الجامعيين و أعضاء المخابر البحثية
                            </th>
                            <th className="lang" key="fee1">
                            450 دت
                            </th>
                          </tr>
                          <tr>
                            <th className="lang" key="rfcategory2">
                            طلبة الدراسات العليا، وجوب تقديم ما يثبت مزاولته للدراسة
                            </th>
                            <th className="lang" key="fee2">
                            300 دت
                            </th>
                          </tr>
                          <tr>
                            <th className="lang" key="rfcategory3">
                            الشركات و مؤسسات الرسمية و الدولية /  الوزارات و الدواوين 
                            </th>
                            <th className="lang" key="fee3">
                            600 دت
                            </th>
                          </tr>
                          <tr>
                            <th className="lang" key="rfcategory4">
                            حجز جناح بالمعرض  
                            </th>
                            <th className="lang" key="fee4">
                            900 دت 
                            </th>
                          </tr>
                        </tbody>
                      </Table>
                      <h4 className="lang h4GeoTunis" key="includes" style={{color: "#cf0202" }}>
                      رسوم المشاركة عن بعد   
                      </h4>
                      150 يورو 
                      <h4 className="lang h4GeoTunis" key="includes" style={{color: "#cf0202" }}>
                        يضم معلوم المشاركة:
                      </h4>
                      <ul ref={this.ulRef}>
                        <li className="lang" key="include1">
                          الإقامة لمدة 5 أيام اربع ليالي مع تغطية معاشية كاملة،
                          فطور الصباح، الغداء و العشاء
                        </li>
                        <li className="lang" key="include2">
                          الإقامة تبتدا من الساعة الثانية بعد الظهر من يوم 18
                          نوفمبر و تنتهي منتصف النهار من يوم 22 نوفمبر2024
                        </li>
                        <li className="lang" key="include3">
                          حضور مختلف الفعاليات العلمية
                        </li>
                        <li className="lang" key="include4">
                          المشاركة في حفل الافتتاح و الاختتام
                        </li>
                        <li className="lang" key="include5">
                          استراحة القهوة
                        </li>
                        <li className="lang" key="include6">
                          المنشورات و الإصدارات الخاصة بالمؤتمر
                        </li>
                        <li className="lang" key="include7">
                          حاملة الوثائق
                        </li>
                        <li className="lang" key="include8">
                          النشر العلمي لفعاليات المؤتمر
                        </li>
                        <li className="lang" key="include9">
                          البرنامج الاجتماعي
                        </li>
                      </ul>
                      <h4 className="lang h4GeoTunis" key="method" style={{color: "#cf0202" }}>
                        طريقة الدفع
                      </h4>
                      <p className="lang" key="methodp1">
                        يقع دفع معاليم التسجيل عن طريق التحويل البنكي للحساب
                        الجاري للجمعية او بالتسديد على عين المكان عند التسجيل
                      </p>
                      <ul ref={this.ulRef}>
                        <li className="lang" key="bankinfo1">
                          اسم البنك : التجاري بنك
                        </li>
                        <li className="lang" key="bankinfo2">
                          الفرع / الوكالة / شارع باريس تونس
                        </li>
                        <li className="lang" key="bankinfo3">
                          رقم الحساب الجاري : 04018104000419165651
                        </li>
                        <li className="lang" key="bankinfo4">
                          I.B.A.N: TN59 04018104000419165651
                        </li>
                        <li className="lang" key="bankinfo5">
                          اسم صاحب الحساب /ATIGN - GEOTUNIS
                        </li>
                        <li className="lang" key="bankinfo6">
                          Swift: BSTUTNTT
                        </li>
                      </ul>
                      <h4 className="lang h4GeoTunis" key="method2" style={{color: "#cf0202" }}>
                        طريقة الدفع بالنسبة للمشاركين من المؤسسات التونسية
                      </h4>
                      <p className="lang" key="methodp1">
                        يقع دفع معاليم التسجيل عن طريق التحويل البنكي للحساب
                        الجاري للجمعية او بالتسديد على عين المكان عند التسجيل
                      </p>
                      <ul ref={this.ulRef}>
                        <li className="lang" key="bankinfo1">
                          اسم البنك : التجاري بنك
                        </li>
                        <li className="lang" key="bankinfo2">
                          الفرع / الوكالة / شارع باريس تونس
                        </li>
                        <li className="lang" key="bankinfo3">
                          رقم الحساب الجاري : 04018104000419165651
                        </li>
                        <li className="lang" key="bankinfo4">
                          I.B.A.N: TN59 04018104000419165651
                        </li>
                        <li className="lang" key="bankinfo5">
                          اسم صاحب الحساب /ATIGN - GEOTUNIS
                        </li>
                        <li className="lang" key="bankinfo6">
                          Swift: BSTUTNTT
                        </li>
                      </ul>
                      <a
                        href="https://forms.gle/FT9YqMBJJWNdMwbe6"
                        target="_blank"
                        className="aGeoTunis"
                      >
                        <Button
                          color="primary"
                          className="lang"
                          key="registerBtn"
                        >
                          سجل الان
                        </Button>
                      </a>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default GeoTunisAr;
