import React, { Component } from "react";
import axiosInstance from "../../axios";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  ListGroup,
  ListGroupItem,
  Button,
} from "reactstrap";


class UserEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
    };
  }

  componentDidMount() {
    const access_token = localStorage.getItem("access_token");
    axiosInstance
      .get(`user_events/`, {}, {
        headers: { Authorization: `Bearer${access_token}` },
      })
      .then((res) => {
        this.setState({ events: res.data });
      })
      .catch((err) => console.log(err));
  }

  render() {
    const { events } = this.state;
    return (
      <Container className="text-center" id="events">
        <hr />
        <Row className="row-content">
          {events.map((event) => (
            <Col xs="12" sm="4">
              <Card className="mb-3" style={{ width: "18rem" }} key={event.id}>
                <CardHeader className="text-center">
                  <CardTitle tag="h5">{event.title}</CardTitle>
                </CardHeader>
                <CardBody className="text-center">
                  <ListGroup flush>
                    <ListGroupItem>{event.localisation}</ListGroupItem>
                    <ListGroupItem>{event.dateDeb} to {event.dateFin}</ListGroupItem>
                  </ListGroup>
                  <hr />
                  <CardBody>
                    <Button
                      href={event.moreDetails}
                      target="_blank"
                      color="primary"
                      className="mt-3"
                    >
                      More details
                    </Button>
                  </CardBody>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    );
  }
}

export default UserEvents