import React from "react";
import Header from "./Header";

const AssociationIdentity = () => {
  return (
    <div className="id-container">
      <Header />
      <div className="container text-center">
        <h4 className="mt-5">Identity of the association</h4>
        <div className="row row-content align-item-center">
          <div className="col-8 col-sm-8 offset-2">
            <p className="mb-5">
              Created in 2005, Jort Ref. N ° 108 of June 13, 2005 Page 2493
              having visa n ° 2041 of February 19, 2005 and tax number G /
              934134
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssociationIdentity;
