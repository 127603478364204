import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  ListGroup,
  ListGroupItem,
  Button,
  Alert,
} from "reactstrap";
import axiosInstance from "../../axios";

class UpcommingEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      isLoading: false,
      error: null,
      success: false,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    axiosInstance
      .get("events/")
      .then((response) =>
        this.setState({ events: response.data, isLoading: false })
      )
      .catch((error) => this.setState({ error, isLoading: false }));
  }

  handleMemberRegistration = (event) => {
    const access_token = localStorage.getItem("access_token");
    const refresh_token = localStorage.getItem("refresh_token");
    const token = access_token || refresh_token;
    const event_id = event.id;

    axiosInstance
      .post(`/event/${event_id}/member_register/`, event_id, {
        Authorization: `JWT ${token}`,
      })
      .then(() => {
        this.setState({ success: true });
        setTimeout(() => {
          this.setState({ success: false });
        }, 3000);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handleToggleSuccess = () => {
    this.setState({ success: false });
  };
  render() {
    const { userData, toggleEventModal } = this.props;
    const { events, isLoading, error } = this.state;
    if (isLoading) {
      return <p>Loading...</p>;
    }

    if (error) {
      return <p>{error.message}</p>;
    }
    return (
      <Container className="text-center" id="events">
        <hr />
        <h4>Upcomming Events</h4>
        <Row className="row-content">
          {events.map((event) => (
            <Col xs="12" sm="4">
              <Card className="mb-3" style={{ width: "18rem" }} key={event.id}>
                <img className="card-img-top" src={event.image} alt="Event" />
                <CardHeader className="text-center">
                  <CardTitle tag="h5">{event.title}</CardTitle>
                </CardHeader>
                <CardBody className="text-center">
                  <ListGroup flush>
                    <ListGroupItem>{event.localisation}</ListGroupItem>
                    <ListGroupItem>
                      {event.dateDeb} to {event.dateFin}
                    </ListGroupItem>
                  </ListGroup>
                  <hr />
                  <CardBody>
                    {userData && userData.mail !== "geoconf2@gmail.com" ? (
                      <Button
                        onClick={() => this.handleMemberRegistration(event)}
                        target="_blank"
                        color="primary"
                      >
                        Registration
                      </Button>
                    ) : userData &&
                      userData.mail === "geoconf2@gmail.com" ? null : (
                      <Button
                        onClick={() => toggleEventModal(event.id)}
                        target="_blank"
                        color="primary"
                      >
                        Registration
                      </Button>
                    )}
                    {!userData ||
                    (userData && userData.mail !== "geoconf2@gmail.com") ? (
                      <Button
                        href={event.moreDetails}
                        target="_blank"
                        color="primary"
                        className="mt-3"
                      >
                        More details
                      </Button>
                    ) : userData &&
                      userData.mail === "geoconf2@gmail.com" ? null : null}
                  </CardBody>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
        <Alert
          color="success"
          isOpen={this.state.success}
          toggle={this.handleToggleSuccess}
          className="mt-3"
        >
          Successfully registered to the event
        </Alert>
      </Container>
    );
  }
}
export default UpcommingEvents;
