import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import ArticlesForm from "../Forms/ArticlesForm";
class ArticlesModal extends Component {
  render() {
    return (
      <div>
        <Modal isOpen={this.props.showArticlesModal} toggle={this.props.toggleArticlesModal}>
          <ModalHeader toggle={this.props.toggleArticlesModal}>Article</ModalHeader>
          <ModalBody>
            <ArticlesForm toggle={this.props.toggleArticlesModal} userData={this.props.userData}/>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
export default ArticlesModal;
