import React, { Component } from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardHeader,
  CardBody,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import axiosInstance from "../../axios";

class UserCv extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cv: null,
      cvFileName: "",
      userData: this.props.userData,
      cvUrl: null,
    };
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleSubmit = this.handleUploadCV.bind(this);
  }

  componentDidMount() {
    const { userData } = this.props;
    axiosInstance
      .get(`utilisateurs/cv/${userData.id}/`)
      .then((res) => {
        this.setState({ cvUrl: res.data.cv_url });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleFileChange(event) {
    this.setState({
      cv: event.target.files[0],
      cvFileName: event.target.files[0].name
    });
  }

  handleUploadCV(event) {
    event.preventDefault();
    const data = new FormData();
    data.append("cv", this.state.cv);
    const { userData } = this.props;
    const userId = userData.id;
    axiosInstance
      .post(`utilisateurs/upload_cv/${userId}/`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        axiosInstance
          .get(`utilisateurs/cv/${userId}/`)
          .then((res) => {
            this.setState({
              cvUrl: res.data.cv_url,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      });
  }

  handleDeleteCV = () => {
    const { userData } = this.props;
    axiosInstance
      .post(`utilisateurs/cv/delete/${userData.id}/`)
      .then(() => {
        this.setState({
          cvUrl: null,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { cvUrl } = this.state;
    const embedKey = cvUrl ? cvUrl : "";
    return (
      <div className="UserCv">
        {cvUrl ? (
          <Card>
            <CardHeader>CV</CardHeader>
            <CardBody>
              <embed
                src={cvUrl}
                width="100%"
                height="600"
                type="application/pdf"
                key={embedKey}
              />
              <Button
                color="danger"
                onClick={this.handleDeleteCV}
                className="mb-3"
              >
                Delete the CV
              </Button>
            </CardBody>
          </Card>
        ) : (
          <Form onSubmit={this.handleSubmit}>
            <FormGroup>
              <Label for="cv">
                Download your CV
                <InputGroup className="mt-3" style={{cursor:"pointer"}}>
                  <InputGroupText>Select a file</InputGroupText>
                  <Input disabled='true' placeholder={this.state.cvFileName ||"No file selected"} />
                </InputGroup>
                <Input
                  type="file"
                  name="cv"
                  id="cv"
                  accept="application/pdf"
                  onChange={this.handleFileChange}
                  style={{ visibility: "hidden" }}
                />
              </Label>
            </FormGroup>
            <Button color="primary" type="submit" className="mb-3">
              Add CV
            </Button>
          </Form>
        )}
      </div>
    );
  }
}
export default UserCv;
