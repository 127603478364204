import React, { Component } from "react";
import axiosInstance from "../../axios";
import { Button, Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      hasChanges: false,
      pendingChanges: {},
    };
  }

  componentDidMount() {
    axiosInstance
      .get("/admin/all-users/")
      .then((response) => {
        const users = response.data;
        this.setState({ users });
      })
      .catch((error) => {
        error("Error fetching data: ", error);
      });
  }

  handleDelete = (userId) => {
    const confirmed = window.confirm(
      "You are about to delete a user"
    );
    if (confirmed) {
      axiosInstance
        .post(`/admin/delete-user/${userId}/`)
        .then((response) => {
          this.setState((prevState) => ({
            users: prevState.users.filter((user) => user.id !== userId),
          }));
        })
        .catch((error) => {
          console.error("Error deleting user: ", error);
        });
    }
  };

  handlestatusChange = (userId, field, value) => {
    this.setState((prevState) => ({
      users: prevState.users.map((user) => {
        if (user.id === userId) {
          return {
            ...user,
            [field]: value,
          };
        }
        return user;
      }),
      hasChanges: true,
    }));
  };

  handleValidate = () => {
    const { pendingChanges } = this.state;

    Object.entries(pendingChanges).forEach(([userId, changes]) => {
      axiosInstance
        .patch(`/admin/update-user/${userId}/`, changes)
        .then((response) => {
        })
        .catch((error) => {
          console.error("Error updating user status: ", error);
        });
    });
  };

  render() {
    const { users, hasChanges } = this.state;
    return (
      <div className="users mt-3">
        <Table striped responsive>
          <thead>
            <tr>
              <th>First name</th>
              <th>Last name</th>
              <th>Phone number</th>
              <th>Email</th>
              <th>Country</th>
              <th>Educational level</th>
              <th>Organization</th>
              <th>DELETE</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td>{user.nom}</td>
                <td>{user.prenom}</td>
                <td>{user.numTel}</td>
                <td>{user.mail}</td>
                <td>{user.pays}</td>
                <td>{user.niveaux}</td>
                <td>{user.organisation}</td>
                <td>
                  <Button
                    color="danger"
                    onClick={() => this.handleDelete(user.id)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {hasChanges && (
          <Button color="primary" onClick={this.handleValidate}>
            Validate
          </Button>
        )}
      </div>
    );
  }
}
export default Users;
