import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import SidebarComponent from "./Admin/SidebarComponent";
import AddEvent from "./Forms/AddEvent";
import Users from "./Admin/Users";
import { Routes, Route, Navigate } from "react-router-dom";
import EventsAdmin from "./Admin/EventsAdmin";
import UpdateEvent from "./Forms/UpdateEvent";
import Statistics from "./Admin/Statistics"
import SendMail from "./Admin/SendMail";
import RegisteredUsersComponent from "./Admin/RegisteredUsersComponent ";


class AdminPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventId: null,
      events: []
    }
  }

  handleEventIdChange = (eventId) => {
    this.setState({eventId})
  }

  updateEvents = (updatedEvents) => {
    this.setState({ events: updatedEvents });
  };

  render() {
    const {eventId} = this.state
    return (
      <div className="sidebar">
        <Row fluid>
          <Col xs={12} md={3} className="sidebar-container">
            <SidebarComponent />
          </Col>
          <Col xs={12} md={9} className="main-container">
            <Routes>
              <Route path="admin-users/" element={<Users />} />
              <Route path="admin-events/" element={<EventsAdmin events={this.state.events} updateEvents={this.updateEvents} onEventIdChange={this.handleEventIdChange}/>}>
                <Route path="add-event/" element={<AddEvent events={this.state.events} updateEvents={this.updateEvents}/>} />
                <Route path="update-event/:eventId/" element={<UpdateEvent eventId= {eventId}/>} />
                <Route path="registered-users/:eventId/" element={<RegisteredUsersComponent eventId={eventId} />} />
              </Route>
              <Route path="statistics/" element={<Statistics />} />
              <Route path="send-mail/" element={<SendMail />} />
              <Route index element={<Navigate to="admin-users" />} />
            </Routes>
          </Col>
        </Row>
      </div>
    );
  }
}
export default AdminPage;
