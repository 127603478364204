import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  ButtonGroup,
  Button,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardText,
  CardSubtitle,
  Table,
  CardTitle,
} from "reactstrap";

class GeoTunisEn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "about",
    };
  }

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  render() {
    return (
      <div className="geotunis">
        <header className="jumbotronGeotunis">
          <ButtonGroup
            className="geo-btn-group"
            role="group"
            aria-label="language"
          >
            <Button color="primary" className="translate" id="en" disabled>
              English
            </Button>
            <Button
              color="primary"
              className="translate"
              id="ar"
              tag={Link}
              to="/geotunis-ar"
            >
              عربية
            </Button>
          </ButtonGroup>
        </header>

        <Container fluid style={{ height: "100%" }} className="mt-4">
          <Row className="row-container">
            <Col xs="12" sm="12" style={{ height: "100%" }}>
              <Card className="mb-3">
                <CardHeader className="card-nav" ref={this.cardNavRef}>
                  <Nav
                    tabs
                    className="nav-tabs card-header-tabs"
                    id="list"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={
                          this.state.activeTab === "about"
                            ? "active lang navlinkGeoTunis"
                            : "lang navlinkGeoTunis"
                        }
                        onClick={() => this.toggleTab("about")}
                        role="tab"
                        aria-controls="about"
                        aria-selected="true"
                        key="aboutHeader"
                      >
                        About
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          this.state.activeTab === "scthemes"
                            ? "active lang navlinkGeoTunis"
                            : "lang navlinkGeoTunis"
                        }
                        onClick={() => this.toggleTab("scthemes")}
                        role="tab"
                        aria-controls="scthemes"
                        aria-selected="false"
                        key="scthemesHeader"
                      >
                        Scientific Themes
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          this.state.activeTab === "comittees"
                            ? "active lang navlinkGeoTunis"
                            : "lang navlinkGeoTunis"
                        }
                        onClick={() => this.toggleTab("comittees")}
                        role="tab"
                        aria-controls="comittees"
                        aria-selected="false"
                        key="comitteesHeader"
                      >
                        Comittees
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          this.state.activeTab === "callpaper"
                            ? "active lang navlinkGeoTunis"
                            : "lang navlinkGeoTunis"
                        }
                        onClick={() => this.toggleTab("callpaper")}
                        role="tab"
                        aria-controls="callpaper"
                        aria-selected="false"
                        key="callpaperHeader"
                      >
                        Call for Paper
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          this.state.activeTab === "exhibition"
                            ? "active lang navlinkGeoTunis"
                            : "lang navlinkGeoTunis"
                        }
                        onClick={() => this.toggleTab("exhibition")}
                        role="tab"
                        aria-controls="exhibition"
                        aria-selected="false"
                        key="exhibitionHeader"
                      >
                        Exhibition
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          this.state.activeTab === "security"
                            ? "active lang navlinkGeoTunis"
                            : "lang navlinkGeoTunis"
                        }
                        onClick={() => this.toggleTab("security")}
                        role="tab"
                        aria-controls="security"
                        aria-selected="false"
                        key="securityHeader"
                      >
                        National security
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          this.state.activeTab === "libya"
                            ? "active lang navlinkGeoTunis"
                            : "lang navlinkGeoTunis"
                        }
                        onClick={() => this.toggleTab("libya")}
                        role="tab"
                        aria-controls="libya"
                        aria-selected="false"
                        key="libyaHeader"
                      >
                        Libya Day
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          this.state.activeTab === "importantdates"
                            ? "active lang navlinkGeoTunis"
                            : "lang navlinkGeoTunis"
                        }
                        onClick={() => this.toggleTab("importantdates")}
                        role="tab"
                        aria-controls="importantdates"
                        aria-selected="false"
                        key="importantdatesHader"
                      >
                        Important Dates
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          this.state.activeTab === "program"
                            ? "active lang navlinkGeoTunis"
                            : "lang navlinkGeoTunis"
                        }
                        onClick={() => this.toggleTab("program")}
                        role="tab"
                        aria-controls="program"
                        aria-selected="false"
                        key="programHeader"
                      >
                        Partners
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          this.state.activeTab === "fees"
                            ? "active lang navlinkGeoTunis"
                            : "lang navlinkGeoTunis"
                        }
                        onClick={() => this.toggleTab("fees")}
                        role="tab"
                        aria-controls="fees"
                        aria-selected="false"
                        key="feesHeader"
                      >
                        Registration
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader>
                <CardBody>
                  <TabContent activeTab={this.state.activeTab} className="mt-3">
                    <TabPane tabId="about">
                      <CardText>
                        <p className="aboutp1" key="aboutp1">
                          The Tunisian Association of Digital Geography, in
                          collaboration with The Saudi Geographical Society ,
                          The Euro-Arab Union of Geomatics and the Arab Union of
                          Surveying, and in partnership with the Journal of
                          Geographic Sciences, is organizing the 16th
                          International Conference on Geomatics and Geographic
                          Artificial Intelligence - Geo-Tunis, in the city of
                          Hammamet from November 18th to 22nd, 2024.
                        </p>
                      </CardText>
                      <CardText>
                        <p className="text-center">
                          <b
                            className="app lang"
                            key="aboutp2"
                            ref={this.appRef}
                          >
                            The Role of Geographic Infrastructure in
                            Establishing Sustainable Development
                          </b>
                        </p>
                      </CardText>
                      <CardText>
                        <p className="text-center lang" key="aboutp3">
                          18-22 November 2024 / Tunisia
                        </p>
                      </CardText>
                      <CardText>
                        <p className="lang" key="aboutp4">
                          In this context, we are honored to invite you to
                          participate in various scientific and training events.
                          The 16th edition will be organized in collaboration
                          with numerous international scientific institutions
                          and will feature significant participation from
                          international researchers and academics. The 16th
                          edition aims to establish a new approach to dealing
                          with geographic information and its various means,
                          including remote sensing, spatial geography,
                          information systems, and more. It will address
                          important topics and proposals aimed at establishing a
                          successful development model based on geographical
                          standards and contributing to the search for practical
                          solutions to various environmental, health, social,
                          and security issues facing our world today. The
                          conference will cover numerous scientific themes,
                          technical topics, and technological advances designed
                          to enhance the capabilities of researchers and
                          specialists in the field, develop their knowledge and
                          technical skills, and improve the capabilities of
                          their institutions and their members. Today, Geo-Tunis
                          is considered an international and academic hub for
                          geospatial technology, geographic information systems,
                          remote sensing, and related sciences, thanks to the
                          leadership of its administrators and its renowned
                          academic and scientific spaces on the global stage.
                          Participation in Geo-Tunis represents an opportunity
                          for all those interested in expanding their knowledge,
                          enhancing their technical skills, and showcasing their
                          technological contributions. It is a space where
                          development is viewed from a scientific and technical
                          perspective, aiming to achieve the desired goals. We
                          invite you from all corners of the world to
                          participate in one of the most important international
                          scientific conferences dedicated to the applications
                          of space technology, geographic information systems,
                          and remote sensing. Be sure to mark your calendar, and
                          you are most welcome to join us in the sixteenth
                          edition of Geo-Tunis.
                        </p>
                      </CardText>
                      <CardText>
                        <p
                          className="objectifs lang"
                          key="aboutObjectifs"
                          ref={this.objRef}
                        >
                          Conference objectives:
                        </p>
                        <ol className="obj" ref={this.olRef}>
                          <li className="lang themeObj" key="aboutObj1">
                            Review the latest developments in Geographic
                            Information Systems and Digital Geography.
                          </li>
                          <li className="lang themeObj" key="aboutObj2">
                            Provide spatial and technological solutions to
                            support decision-making.
                          </li>
                          <li className="lang themeObj" key="aboutObj3">
                            Enhance scientific interaction in the field of
                            Geographic Information Systems.
                          </li>
                          <li className="lang themeObj" key="aboutObj4">
                            Foster knowledge and technical exchange among
                            experts.
                          </li>
                          <li className="lang themeObj" key="aboutObj5">
                            Facilitate the sharing of experiences among
                            participants.
                          </li>
                          <li className="lang themeObj" key="aboutObj6">
                            Support research systems in intelligent disciplines.
                          </li>
                          <li className="lang themeObj" key="aboutObj7">
                            Boost opportunities for intellectual and technical
                            investment
                          </li>
                          <li className="lang themeObj" key="aboutObj8">
                            Foster scientific relationships among various
                            participating entities in the sector.
                          </li>
                        </ol>
                        <p
                          className="card-text themes lang"
                          key="aboutThemes"
                          ref={this.themesRef}
                        >
                          Scientific themes:
                        </p>
                        <ol className="themeList" ref={this.olRef}>
                          <li className="lang themeLi" key="aboutli1">
                            Natural resources and water management,
                            desertification and climate change
                          </li>
                          <li className="lang themeLi" key="aboutli2">
                            Agriculture and Food
                          </li>
                          <li className="lang themeLi" key="aboutli3">
                            Smart Cities, Urban Planning and Sustainable
                            Development
                          </li>
                          <li className="lang themeLi" key="aboutli4">
                            Geology, Earth Sciences and the Environment
                          </li>
                          <li className="lang themeLi" key="aboutli5">
                            Energy, Renewable Energies and Energy Transition
                          </li>
                          <li className="lang themeLi" key="aboutli6">
                            Security and Defense
                          </li>
                          <li className="lang themeLi" key="aboutli7">
                            Health, Education and Social Services
                          </li>
                          <li className="lang themeLi" key="aboutli8">
                            Technical Topics / Digital geography , Geographic
                            Information Systems , and Geographic Artificial
                            Intelligence
                          </li>
                          <li className="lang themeLi" key="aboutli9">
                            Open and Free Sources for Geomatics Technologies,
                            Space Sciences, and Geographic Artificial
                            Intelligence.
                          </li>
                        </ol>
                        <p
                          className="card-text aboutActHeader lang"
                          key="aboutActHeader"
                          ref={this.aboutActHeaderRef}
                        >
                          Activities programmed in Geo Tunis:
                        </p>
                        <ol type="I" className="aboutAct">
                          <li
                            className="lang sem"
                            key="seminars"
                            ref={this.semRef}
                          >
                            <p className="card-text">Seminars :</p>
                          </li>
                          <ol className="semList" ref={this.olRef}>
                            <li className="lang semList" key="semList1">
                              International Symposium on National Security
                              Strategy in Light of Geostrategic Transformations
                            </li>
                            <li className="lang semList" key="semList2">
                              International Symposium / Geographical Information
                              Systems Security (GISS) and its applications
                            </li>
                            <li className="lang semList" key="semList3">
                              Symposium on : Climate change facts and its impact
                              on the economy and society
                            </li>
                            <li className="lang semList" key="semList4">
                              International Symposium / Smart Cities and
                              Sustainable Urban Planning
                            </li>
                            <li className="lang semList" key="semList5">
                              Symposium on / Geographical Information Systems
                              and Remote Sensing and their Applications in
                              Archeology
                            </li>
                            <li className="lang semList" key="semList6">
                              Symposium on / THE SECURITY IMPACT OF
                              DRONES:CHALLENGES AND OPPORTUNITIES
                            </li>
                            <li className="lang semList" key="semList7">
                              Symposium on / The Role of Surveying Sciences -
                              Geographical Information Systems in Development
                            </li>
                            <li className="lang semList" key="semList8">
                              Symposium on / Water Management and Agricultural
                              Techniques in the Arab World
                            </li>
                            <li className="lang semList" key="semList9">
                              Renewable energy valuation in the global energy
                              transition
                            </li>
                            <li className="lang semList" key="semList10">
                              The Role of Geomatics in the Management of
                              Disasters and Infrastructural Failure
                            </li>
                            <li className="lang semList" key="semList11">
                              Symposium on / Social and Solidarity Economy in
                              the Light of Digital Challenges
                            </li>
                            <li className="lang semList" key="semList12">
                              Symposium on / Irregular migration between human
                              rights approaches and state security
                            </li>
                          </ol>
                          <p className="card-text lang"></p>
                          <li className="lang workshops" key="workshops">
                            Workshops: will be organized by international
                            institutions and experts.
                          </li>
                          <p></p>
                          <p className="card-text lang"></p>
                          <li className="lang courses" key="courses">
                            Training courses: in the field of , GIS and Remote
                            Sensing.
                          </li>
                        </ol>
                      </CardText>
                      <CardText>
                        <p className="interEx lang" key="interEx">
                          International Exhibition: Large international
                          scientific institutions and laboratories will
                          participate in this event. Most of them specialized in
                          the field of providing services , aerial photography ,
                          providers of satellite images , radars ,producers
                          ,developers of software and machines, equipments,
                          measurement of space, satellite positioning devices ,
                          engineering offices Consulting and various related
                          fields
                        </p>
                      </CardText>
                    </TabPane>
                    <TabPane tabId="scthemes">
                      <CardSubtitle>
                        <h5 className="mb-3 lang" key="themeHeader1">
                          1st theme: Management of natural and water resources,
                          desertification and climate change
                        </h5>
                        <ul className="themeList">
                          <li className="lang themeLi" key="theme1li1">
                            Natural resource management
                          </li>
                          <li className="lang themeLi" key="theme1li2">
                            Soil and plant sciences
                          </li>
                          <li className="lang themeLi" key="theme1li3">
                            Water resources management / water scarcity / water
                            harvesting
                          </li>
                          <li className="lang themeLi" key="theme1li4">
                            Natural Hazards
                          </li>
                          <li className="lang themeLi" key="theme1li5">
                            Drought, desertification and desert - desert
                            development
                          </li>
                          <li className="lang themeLi" key="theme1li6">
                            Marine and air pollution
                          </li>
                          <li className="lang themeLi" key="theme1li7">
                            Industrial pollution and mining industries /
                            phosphate - cement, etc...
                          </li>
                          <li className="lang themeLi" key="theme1li8">
                            Climate change, mitigation and adaptation mechanisms
                          </li>
                          <li className="lang themeLi" key="theme1li9">
                            Environmental fields
                          </li>
                          <li className="lang themeLi" key="theme1li10">
                            Local and international laws and legislations in the
                            field of environment and natural resource management
                          </li>
                        </ul>
                        <h5
                          className="card-subtitle mb-2 lang"
                          key="themeHeader2"
                        >
                          2nd theme: Agricultural and Food Security
                        </h5>
                        <ul className="themeList">
                          <li className="lang themeLi" key="theme2li1">
                            Agricultural fields
                          </li>
                          <li className="lang themeLi" key="theme2li2">
                            Plant and animal genetic resources
                          </li>
                          <li className="lang themeLi" key="theme2li3">
                            Agricultural techniques
                          </li>
                          <li className="lang themeLi" key="theme2li4">
                            Climate change, mitigation and adaptation
                          </li>
                          <li className="lang themeLi" key="theme2li5">
                            Food and Sustainability
                          </li>
                          <li className="lang themeLi" key="theme2li6">
                            Food security and sovereignty
                          </li>
                          <li className="lang themeLi" key="theme2li7">
                            Agricultural intensification
                          </li>
                          <li className="lang themeLi" key="theme2li8">
                            Animal Production
                          </li>
                          <li className="lang themeLi" key="theme2li9">
                            Forage and pastoral patterns
                          </li>
                          <li className="lang themeLi" key="theme2li10">
                            Rural settlement
                          </li>
                        </ul>
                        <h5
                          className="card-subtitle mb-2 lang"
                          key="themeHeader3"
                        >
                          3rd theme: Smart Cities, Urban Planning and
                          Sustainable Development
                        </h5>
                        <ul className="themeList">
                          <li className="lang themeLi" key="theme3li1">
                            City planning and smart cities
                          </li>
                          <li className="lang themeLi" key="theme3li2">
                            Green cities
                          </li>
                          <li className="lang themeLi" key="theme3li3">
                            Urban development
                          </li>
                          <li className="lang themeLi" key="theme3li4">
                            Transport networks
                          </li>
                          <li className="lang themeLi" key="theme3li5">
                            Wired and wireless networks
                          </li>
                          <li className="lang themeLi" key="theme3li6">
                            Coastal strip management
                          </li>
                          <li className="lang themeLi" key="theme3li7">
                            Archeology and Tourism
                          </li>
                          <li className="lang themeLi" key="theme3li8">
                            Management of cities and industrial centers
                          </li>
                        </ul>
                        <h5
                          className="card-subtitle mb-2 lang"
                          key="themeHeader4"
                        >
                          4th theme: Geology, Earth Sciences and Environment
                        </h5>
                        <ul className="themeList">
                          <li className="lang themeLi" key="theme4li1">
                            Environmental Geology
                          </li>
                          <li className="lang themeLi" key="theme4li2">
                            Petroleum Geology
                          </li>
                          <li className="lang themeLi" key="theme4li3">
                            Geology and Mineral Resources
                          </li>
                          <li className="lang themeLi" key="theme4li4">
                            Engineering Geology
                          </li>
                          <li className="lang themeLi" key="theme4li5">
                            Structural Geology
                          </li>
                          <li className="lang themeLi" key="theme4li6">
                            Geology and Civil Engineering
                          </li>
                          <li className="lang themeLi" key="theme4li7">
                            Exploration Geophysics
                          </li>
                          <li className="lang themeLi" key="theme4li8">
                            Mining Geology
                          </li>
                          <li className="lang themeLi" key="theme4li9">
                            Economic Geology
                          </li>
                          <li className="lang themeLi" key="theme4li10">
                            Marine Geology
                          </li>
                          <li className="lang themeLi" key="theme4li11">
                            Physical Geology
                          </li>
                          <li className="lang themeLi" key="theme4li12">
                            Geological Consulting
                          </li>
                        </ul>
                        <h5
                          className="card-subtitle mb-2 lang"
                          key="themeHeader5"
                        >
                          5th theme: Energy, Renewable Energies and Energy
                          Transition
                        </h5>
                        <ul className="themeList">
                          <li className="lang themeLi" key="theme5li1">
                            Oil and gas
                          </li>
                          <li className="lang themeLi" key="theme5li2">
                            Geology and earth science
                          </li>
                          <li className="lang themeLi" key="theme5li3">
                            Solar energy
                          </li>
                          <li className="lang themeLi" key="theme5li4">
                            Wind power/ wind energy
                          </li>
                          <li className="lang themeLi" key="theme5li5">
                            Hot water energy
                          </li>
                          <li className="lang themeLi" key="theme5li6">
                            Waste valuation
                          </li>
                          <li className="lang themeLi" key="theme5li7">
                            Energy transformation
                          </li>
                          <li className="lang themeLi" key="theme5li8">
                            Industry and mining industries
                          </li>
                        </ul>
                        <h5
                          className="card-subtitle mb-2 lang"
                          key="themeHeader6"
                        >
                          6th theme: Security and Defense
                        </h5>
                        <ul className="themeList">
                          <li className="lang themeLi" key="theme6li1">
                            GIS applications in security
                          </li>
                          <li className="lang themeLi" key="theme6li2">
                            Geomatics in decision making
                          </li>
                          <li className="lang themeLi" key="theme6li3">
                            Remote sensing (space)
                          </li>
                          <li className="lang themeLi" key="theme6li4">
                            Remote sensing (aerial)
                          </li>
                          <li className="lang themeLi" key="theme6li5">
                            GPS system
                          </li>
                          <li className="lang themeLi" key="theme6li6">
                            Web mapping/ paper maps
                          </li>
                          <li className="lang themeLi" key="theme6li7">
                            Civil defense
                          </li>
                          <li className="lang themeLi" key="theme6li8">
                            Tourists safety and security
                          </li>
                          <li className="lang themeLi" key="theme6li9">
                            Crime mapping
                          </li>
                        </ul>
                        <h5
                          className="card-subtitle mb-2 lang"
                          key="themeHeader7"
                        >
                          7th theme: Health, Education and Social Services
                        </h5>
                        <ul className="themeList">
                          <li className="lang themeLi" key="theme7li1">
                            Applications in health and epidemic control
                          </li>
                          <li className="lang themeLi" key="theme7li2">
                            Schooling and education
                          </li>
                          <li className="lang themeLi" key="theme7li3">
                            Virtual and distance education
                          </li>
                          <li className="lang themeLi" key="theme7li4">
                            Immigration and emigration
                          </li>
                          <li className="lang themeLi" key="theme7li5">
                            Migration and displacement / asylum and humanitarian
                            work
                          </li>
                          <li className="lang themeLi" key="theme7li6">
                            Poverty alleviation and social assistance
                          </li>
                        </ul>
                        <h5
                          className="card-subtitle mb-2 lang"
                          key="themeHeader8"
                        >
                          8th theme: Technical Topics / Digital geography , GIS
                          , RS and Geospatial Artificial Intelligence (GeoAI)
                        </h5>
                        <ul className="themeList">
                          <li className="lang themeLi" key="theme8li1">
                            Digital geography and geographic artificial
                            intelligence applications
                          </li>
                          <li className="lang themeLi" key="theme8li2">
                            Geographical artificial intelligence
                          </li>
                          <li className="lang themeLi" key="theme8li3">
                            Advances in digital geography
                          </li>
                          <li className="lang themeLi" key="theme8li4">
                            Advanced mapping and digital modeling
                          </li>
                          <li className="lang themeLi" key="theme8li5">
                            Geographic simulation applications
                          </li>
                          <li className="lang themeLi" key="theme8li6">
                            Geographic information systems
                          </li>
                          <li className="lang themeLi" key="theme8li7">
                            Management and analysis of digital spatial data
                          </li>
                          <li className="lang themeLi" key="theme8li8">
                            Using digital spatial data for decision-making
                          </li>
                          <li className="lang themeLi" key="theme8li9">
                            Geographic big and meta data
                          </li>
                          <li className="lang themeLi" key="theme8li10">
                            Electronic capture of spatial data
                          </li>
                          <li className="lang themeLi" key="theme8li11">
                            Geographic data storage
                          </li>
                          <li className="lang themeLi" key="theme8li12">
                            Geographic data manipulation, and analysis
                          </li>
                          <li className="lang themeLi" key="theme8li13">
                            Principles and concepts of digital geography
                          </li>
                          <li className="lang themeLi" key="theme8li14">
                            Fundamental skills in GIS
                          </li>
                          <li className="lang themeLi" key="theme8li15">
                            Governmental, industrial, and economical
                            applications
                          </li>
                          <li className="lang themeLi" key="theme8li16">
                            Municipal services, health, and retailing
                          </li>
                          <li className="lang themeLi" key="theme8li17">
                            Geospatial technologies in natural and social
                            sciences
                          </li>
                        </ul>
                        <h5
                          className="card-subtitle mb-2 lang"
                          key="themeHeader9"
                        >
                          9th theme: Open Software for Geomatics, Geospatial
                          Science and Artificial Intelligence
                        </h5>
                        <ul className="themeList">
                          <li className="lang themeLi" key="theme8li1">
                            Open source remote sensing
                          </li>
                          <li className="lang themeLi" key="theme8li2">
                            Open source GIS
                          </li>
                          <li className="lang themeLi" key="theme8li3">
                            Open source web maps
                          </li>
                          <li className="lang themeLi" key="theme8li4">
                            Open source mobile applications
                          </li>
                          <li className="lang themeLi" key="theme8li5">
                            Open data and analytics
                          </li>
                          <li className="lang themeLi" key="theme8li6">
                            Big data
                          </li>
                          <li className="lang themeLi" key="theme8li7">
                            Open source software
                          </li>
                          <li className="lang themeLi" key="theme8li8">
                            Open software
                          </li>
                          <li className="lang themeLi" key="theme8li9">
                            Geographical Artificial Intelligence Software
                          </li>
                        </ul>
                      </CardSubtitle>
                    </TabPane>
                    <TabPane tabId="comittees">
                      <h4
                        className="lang h4GeoTunis"
                        key="comitteesH1"
                        style={{ color: "#cf0202" }}
                      >
                        Honorary Scientific Council
                      </h4>
                      <ul ref={this.ulRef}>
                        <li className="lang" key="honorary1">
                          Professor HRH Princess Mishael Al Saud - Professor of
                          Applied Geomorphology, Honorary President of the
                          Tunisian Association for Digital Geographic
                          Information
                        </li>
                        <li className="lang" key="honorary2">
                          Professor Ronald Francis Abler, former President of
                          the International Union of Geographical and former
                          President of the American Association of Geographers
                        </li>
                        <li className="lang" key="honorary3">
                          Professor El-Sayed Sayed El-Husseini - Former
                          president of the Egyptian Geographical Society
                        </li>
                        <li className="lang" key="honorary4">
                          Professor Sarkis Fadous - President of the Arab Union
                          for Surveying - Dean of the Faculty of Engineering at
                          the Lebanese Canadian University
                        </li>
                        <li className="lang" key="honorary5">
                          Professor Georg Gartner former President of the
                          International Cartographic Association
                        </li>
                      </ul>
                      <h4
                        className="lang h4GeoTunis"
                        key="comitteesH2"
                        style={{ color: "#cf0202" }}
                      >
                        Organizational and Scientific Committee
                      </h4>
                      <ul ref={this.ulRef}>
                        <li className="lang" key="organizational1">
                          Chairman of GEO-TUNIS: Dr.Mohammed Ayari /President of
                          the Euro-Arab Union of Geomatics - - President of the
                          Tunisian Association for Digital Geographic
                          Information
                        </li>
                        <li className="lang" key="organizational2">
                          Chairman of the Scientific Council - Professor Salwa
                          Saidi - Coordinator of the Geomatics Departments at
                          the Faculty of Science in Tunis
                        </li>
                        <li className="lang" key="organizational3">
                          The General Supervisor of the Geo Tunisia Exhibition
                          for Geomatics and Artificial Intelligence Eng. Ahmed
                          SEFI/ International Expert and consultant in
                          Geospatial data and technologies, for Civil and
                          Defense Applications.
                        </li>
                      </ul>
                      <h4
                        className="lang h4GeoTunis"
                        key="comitteesH3"
                        style={{ color: "#cf0202" }}
                      >
                        High Scientific Committee
                      </h4>
                      <ul ref={this.ulRef}>
                        <li className="lang" key="high1">
                          Dr. Ali bin Abdullah Al-Dosari - chairman of the Saudi
                          Geographical Society
                        </li>
                        <li className="lang" key="high2">
                          Professor Jean Doumit - Head of the Department of
                          Geography - Lebanese University
                        </li>
                        <li className="lang" key="high3">
                          Professor Mohamed Sirto – Morocco
                        </li>
                        <li className="lang" key="high4">
                          Dr. Najib Belhabib - President of the Higher School of
                          Technology - Tunisia
                        </li>
                        <li className="lang" key="high5">
                          Prof. Alan Othman - France
                        </li>
                        <li className="lang" key="high6">
                          Dr. Mohammed bin Saad Almogarry - A faculty member at
                          the University of King Saud University, College of
                          Arts - Department of Geography
                        </li>
                        <li className="lang" key="high7">
                          Dr. Abdelqader Rahim - Cybersecurity Consultant -
                          Libya
                        </li>
                        <li className="lang" key="high8">
                          Professor Hussein Nchnish - Algeria
                        </li>
                        <li className="lang" key="high9">
                          Professor Philippe de Joux - Union for the
                          Mediterranean for Scientific Research and
                          Technological Renewal
                        </li>
                        <li className="lang" key="high10">
                          Prof. Haider Hajo Al-Senussi - Sudan
                        </li>
                        <li className="lang" key="high11">
                          Professor Abdul Saleh Fayyad - Head of the Department
                          of Applied Geology, College of Science, University of
                          Anbar - Iraq
                        </li>
                        <li className="lang" key="high12">
                          Professor Naima El-Issaoui - Morocco
                        </li>
                        <li className="lang" key="high13">
                          Professor Ruqayya Amin Al-Ani / Iraqi University -
                          Iraq
                        </li>
                        <li className="lang" key="high14">
                          Dr. Mounir Arjdal - Morocco
                        </li>
                        <li className="lang" key="high15">
                          Prof. Omar Chermiti - Tunisia
                        </li>
                        <li className="lang" key="high16">
                          Professor Othman Sharkas - Palestine
                        </li>
                      </ul>
                      <h4
                        className="lang h4GeoTunis"
                        key="comitteesH4"
                        style={{ color: "#cf0202" }}
                      >
                        Members of the Higher Scientific Committee
                      </h4>
                      <ul ref={this.ulRef}>
                        <li className="lang" key="members1">
                          Dr. Noaman Bakari – Faculty of Science / University of
                          Tunis El Manar – Tunisia
                        </li>
                        <li className="lang" key="members3">
                          Dr. Mohamed Hamdi - Canada
                        </li>
                        <li className="lang" key="members4">
                          Dr. Samar Saqr - Lebanese University - Lebanon
                        </li>
                        <li className="lang" key="members5">
                          Dr. Miftah Al-Fatni - Al Asmarya University – Libya
                        </li>
                        <li className="lang" key="members6">
                          Dr. Sid Ahmed soufiane - Algerian Geographical Society
                          - Algeria
                        </li>
                        <li className="lang" key="members7">
                          Dr. Saleh Zarayeb - University of Batna - Algeria
                        </li>
                        <li className="lang" key="members8">
                          Dr. Louisa Amrouche - Algeria
                        </li>
                        <li className="lang" key="members9">
                          Prof. Beya Tayashi - University of Tunis El Manar -
                          Tunisia
                        </li>
                        <li className="lang" key="members10">
                          Professor Ali Abu Azoum - Head of the Arab Center for
                          Desertification Studies - Libya
                        </li>
                        <li className="lang" key="members11">
                          Professor Tariq Al-Rashed - United States of America
                        </li>
                        <li className="lang" key="members12">
                          Assistant Professor Dr. George Abi Diwan - Lebanese
                          University - Lebanon
                        </li>
                        <li className="lang" key="members13">
                          Prof. Brice Enslam - France
                        </li>
                        <li className="lang" key="members14">
                          Dr. Evgeny Kiselov - Russia
                        </li>
                        <li className="lang" key="members15">
                          Professor Mohamed Lama - Libya
                        </li>
                        <li className="lang" key="members16">
                          Dr. Amin Baraka - King Faisal University - Chad
                        </li>
                        <li className="lang" key="members17">
                          Eng. Muhammad Al-Barqawi - ACSAD
                        </li>
                        <li className="lang" key="members18">
                          Professor Abdelaziz Daoud - University of Sfax -
                          Tunisia
                        </li>
                        <li className="lang" key="members19">
                          Dr. Seddik Noreen - Kingdom of Saudi Arabia
                        </li>
                        <li className="lang" key="members20">
                          Dr. Fatma Taqtaq - Tunisia
                        </li>
                        <li className="lang" key="members21">
                          Dr. Asmaa Al Ketbi - President of the Emirates
                          Geographical Society - United Arab Emirates
                        </li>
                      </ul>
                      <h4
                        className="lang h4GeoTunis"
                        key="comitteesH5"
                        style={{ color: "#cf0202" }}
                      >
                        Members of the Organizing Committee
                      </h4>
                      <ul ref={this.ulRef}>
                        <li className="lang" key="memberso1">
                          Engineer Helmi Lamouchi
                        </li>
                        <li className="lang" key="memberso2">
                          Engineer Imad Belhachemi – Tunisia
                        </li>
                        <li className="lang" key="memberso3">
                          Mr. Mohamed Idris - Libya
                        </li>
                        <li className="lang" key="memberso4">
                          Dr. Mounir Arjdal – Morocco
                        </li>
                        <li className="lang" key="memberso5">
                          Mr. Abdel Baset Al Khadrawi - Tunisia
                        </li>
                        <li className="lang" key="memberso6">
                          Mr. Amin Ben Othman – Tunisia
                        </li>
                        <li className="lang" key="memberso7">
                          Mr. Ali Naji - Tunisia
                        </li>
                        <li className="lang" key="memberso8">
                          Ms. Dalila Aouani - Tunisia
                        </li>
                        <li className="lang" key="memberso9">
                          Ms. Chaima Dridi- Tunisia
                        </li>
                        <li className="lang" key="memberso10">
                          Ms. Hala Saiidi
                        </li>
                        <li className="lang" key="memberso12">
                          Mr. Mokhles Souissi
                        </li>
                        <li className="lang" key="memberso13">
                          Mr. Mohsen Belarabi
                        </li>
                        <li className="lang" key="memberso14">
                          Ms. Hanan Bou Allaggue
                        </li>
                      </ul>
                    </TabPane>
                    <TabPane tabId="callpaper">
                      <p className="lang" key="paperp1">
                        The organizing committee of The The 16th edition of the
                        international congress - Geo Tunis which will be held in
                        TUNISIA, from 18 to 22 November 2024 . is honored to
                        inform participants, who wish to make a poster
                        presentation, to send an abstract before 30-09-2024. If
                        you would like to participate please follow the
                        following steps:
                      </p>
                      <ul ref={this.ulRef}>
                        <li className="lang" key="paperi1">
                          Send us a clarifying abstract in: French, Arabic or
                          English
                        </li>
                        <li className="lang" key="paperi2">
                          Include the following information in the abstract:
                          Name of the participant/ Title of the abstract/
                          Participant’s institution/abstract text/ key words/
                          abstracts shall not exceed 300 words/ the poster
                          should not be presented previously in other
                          conferences or magazines
                        </li>
                        <li className="lang" key="paperi3">
                          Poster size: A0. The researcher may choose the
                          suitable size for the poster.
                        </li>
                      </ul>
                      <p className="lang" key="paperp2">
                        The invitation is open. Abstracts deadline: 30-09-2024;
                        Poster presenters are invited to send us their CVs with
                        photos along with posters if they wish to publish their
                        works.
                      </p>
                    </TabPane>
                    <TabPane tabId="importantdates">
                      <Table bordered ref={this.tabRef}>
                        <tbody>
                          <tr>
                            <td
                              scope="col"
                              className="lang important"
                              key="importante1"
                            >
                              Deadline for Abstract Submission
                            </td>
                            <td scope="col" className="lang date" key="date1">
                              30/09/2024
                            </td>
                          </tr>
                          <tr>
                            <td
                              scope="col"
                              className="lang important"
                              key="importante2"
                            >
                              Deadline for Full Paper Submission
                            </td>
                            <td scope="col" className="lang date" key="date2">
                              18/11/2024
                            </td>
                          </tr>
                          <tr>
                            <td
                              scope="col"
                              className="lang important"
                              key="importante3"
                            >
                              Deadline for Poster Presentation Submission
                            </td>
                            <td scope="col" className="lang date" key="date3">
                              06/11/2024
                            </td>
                          </tr>
                          <tr>
                            <td
                              scope="col"
                              className="lang important"
                              key="importante4"
                            >
                              Deadline for PowerPoint Presentation (PP)
                              Submission
                            </td>
                            <td
                              col="col"
                              className="lang important"
                              key="date4"
                            >
                              06/11/2024
                            </td>
                          </tr>
                          <tr>
                            <td
                              scope="col"
                              className="lang date"
                              key="importante5"
                            >
                              Deadline for On-Site Participation Registration
                            </td>
                            <td scope="col" className="lang date" key="date5">
                              10/11/2024
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </TabPane>
                    <TabPane tabId="exhibition">
                      <CardTitle>
                        <h4 className="lang h4GeoTunis" key="exihibitionH1">
                          GEO-TUNIS - EXHIBITION 2024
                        </h4>
                      </CardTitle>
                      <p className="lang" key="exhibitionP1">
                        GEO-TUNIS 2024 will host an exhibition that will include
                        exhibits by leading technology providers, policy-makers,
                        and users showcasing the innovations and upcoming trends
                        in geospatial and allied industries. It will be where
                        the largest geospatial companies, primary active
                        regional industry, and many large-scale user
                        organizations from across the globe will come together
                        to showcase their products and services.
                      </p>
                      <CardTitle>
                        <h4 className="lang h4GeoTunis" key="exihibitionH2">
                          WHY EXHIBIT WITH US?
                        </h4>
                      </CardTitle>
                      <p className="lang" key="exhibitionP2">
                        GEO-TUNIS exhibition 2024 is the place to be for
                        face-to-face meeting with your top prospects. Key
                        decision makers visit our exhibition, make the most of
                        it! Why we’re the best platform to showcase your
                        product: 900+ expected delegates/visitors from45+
                        countries Special SME/Institutional Corner at
                        competitive rate Dedicated time for exhibition visit
                        daily Product demo and training programs Technology
                        sessions Networking receptions.
                      </p>
                      <CardTitle>
                        <h4 className="lang h4GeoTunis" key="exihibitionH3">
                          EXHIBITION RATE
                        </h4>
                      </CardTitle>
                      <p className="lang" key="exhibitionPrice">
                        900 EUROS per Exhibitor
                      </p>
                      <ul ref={this.ulRef}>
                        <li className="lang" key="exihibitionLi1">
                          The booth in the exhibition contains a table and 2
                          chairs / electrical connection / Wi-Fi internet
                        </li>
                        <li className="lang" key="exihibitionLi2">
                          2 entry badges
                        </li>
                        <li className="lang" key="exihibitionLi3">
                          Coffee break
                        </li>
                        <li className="lang" key="exihibitionLi4">
                          25 minutes to organize a workshop or give
                          presentations at the conference
                        </li>
                        <li className="lang" key="exihibitionLi5">
                          Certificates of participation and appreciation
                        </li>
                      </ul>
                      <p className="lang" key="exhibitionInfo">
                        For exhibition related queries please contact:
                        geoconf2@gmail.com or atignassociation@gmail.com
                      </p>
                    </TabPane>
                    <TabPane tabId="security">
                      <center>
                        <h5>
                          International Symposium{" "}
                          <p style={{ color: "#ab0d02" }}>
                            on National Security Strategy in Light of
                            Geostrategic Transformations
                          </p>
                        </h5>
                      </center>
                      <p style={{ color: "#cf0202" }}>
                        <b>
                          <u>Theoretical Framework: </u>
                        </b>
                      </p>
                      <p>
                        The major global transformations today, along with a
                        series of consecutive and accelerating events, pose a
                        range of problematic issues and challenges that require
                        careful examination, reevaluation, and focus. This is
                        because global changes across all levels foreshadow a
                        shift in the shape of the world system, leading to the
                        collapse of many old structures, potentially resulting
                        in further international turmoil. The crises, wars, the
                        rise of extremist currents, and terrorist movements
                        witnessed in the world today signal a deep and
                        comprehensive global crisis. This crisis could culminate
                        in a third world war that could affect the national
                        security of countries. The "war of giants" currently
                        unfolding in Ukraine can be considered the spark that
                        outlines the contours of a new world, a world in which
                        we witness the first signs of the decline of unipolar
                        dominance. New poles are emerging, capable of changing
                        the equation and imposing balances that can accommodate
                        differences between nations. Therefore, it is essential
                        to realize the potential consequences for the world in
                        light of technological military advancements,
                        information systems, digitization, significant progress
                        in space sciences and space domains, artificial
                        intelligence technologies, as well as deadly nuclear and
                        biological weapons, virus wars, natural disasters, and
                        the effects of climate collapse. These threats
                        jeopardize cosmic balance, human, international, and
                        societal peace. In return, this raises various issues,
                        such as the matter of national security, which is of
                        utmost importance and urgency. On one hand, it aims to
                        investigate the necessary preparedness to deal with
                        global crises and mitigate the associated risks. On the
                        other hand, it seeks to develop scientific and practical
                        strategies and plans to anticipate future developments.
                        Currently, the concept of national security in political
                        science extends to encompass a diverse range of areas,
                        including natural resources, water security, food
                        security, and cyber security, among others. In the
                        context of the sixteenth session of the International
                        Conference on Geomatics and Artificial Intelligence,
                        GEO-Tunis, the Euro-Arab Union of Geomatics, the
                        Al-Manara Global Foundation, and the Tunisian
                        Association for Digital Geographic Information are
                        organizing an international seminar titled:
                        <center>
                          <b style={{ color: "#cf0202" }}>
                            <u>
                              "National Security Strategy in Light of
                              Geostrategic Transformations."
                            </u>
                          </b>
                        </center>
                      </p>
                      <p style={{ color: "#cf0202" }}>
                        <b>
                          <u>PROBLIMATIC</u>
                        </b>
                      </p>
                      <p>
                        The conference will address the current state and future
                        of national security in light of geostrategic
                        transformations within major scientific themes related
                        to the concepts of national security and its contents by
                        posing a series of questions:
                        <ul>
                          <li>
                            How can we interpret regional and international
                            conflicts, and trace their effects and repercussions
                            on various regions, such as the Middle East, Sahel
                            and Sahara region, & North Africa?
                          </li>
                          <li>
                            To what extent have geostrategic shifts, conflicts,
                            and wars impacted food, health, environmental, and
                            climate security?
                          </li>
                          <li>
                            What policies and measures can countries and the
                            international community adopt to deal with the
                            phenomenon of illegal migration and reduce it?
                          </li>
                          <li>
                            How can these risks and threats be mitigated through
                            the exploration of strategies that aim to preserve
                            national security in its various dimensions?
                          </li>
                          <li>
                            To what extent can national and international
                            legislation, laws, and security systems respond to
                            national security in light of the growing phenomena
                            of migration, crime, and terrorism?
                          </li>
                          <li>
                            How can countries and institutions protect their
                            data and cyber systems from advanced cyber threats
                            such as hybrid attacks and lightning strikes?
                          </li>
                          <li>
                            How can cyber threats be analyzed in the age of
                            technological advancement, and to what extent can
                            current strategies address them?
                          </li>
                          <li>
                            What are the ethical and legal challenges associated
                            with the application of artificial intelligence in
                            the fields of security and defense?
                          </li>
                        </ul>
                      </p>
                      <p style={{ color: "#cf0202" }}>
                        <b>
                          <u>Conference Agenda Topics:</u>
                        </b>
                      </p>
                      <u style={{ color: "#cf0202" }}>
                        Axis 1: Regional and International Conflicts and Their
                        Implications on National Security
                      </u>
                      <ol type="1">
                        <li>
                          The Geostrategic Implications of the Russo-Ukrainian
                          War.
                        </li>
                        <li>
                          Eastern Mediterranean: Border Demarcation and Energy
                          Conflicts.
                        </li>
                        <li>Coastal and Desert Instability.</li>
                        <li>
                          The Ongoing Crisis in Libya and Its Regional Security
                          Implications.
                        </li>
                        <li>
                          Conflicts and Resolutions in the Middle East and the
                          Arabian Gulf.
                        </li>
                      </ol>
                      <u style={{ color: "#cf0202" }}>
                        Axis 2: Health and Climate Change Dimensions
                      </u>
                      <ol type="1">
                        <li>
                          <u>Climate Change and Adaptation Mechanisms.</u>
                        </li>
                        <li>
                          <u>Food Security.</u>
                        </li>
                        <li>
                          <u>Health Security.</u>
                        </li>
                        <li>
                          <u>Environmental Security.</u>
                        </li>
                      </ol>
                      <u style={{ color: "#cf0202" }}>
                        Axis 3: Natural Resource Security and Risk Management
                      </u>
                      <ol type="1">
                        <li>
                          <u>Water Security.</u>
                        </li>
                        <li>
                          <u>Natural Resource Management.</u>
                        </li>
                        <li>
                          <u>Risk and Disaster Management.</u>
                        </li>
                        <li>
                          <u>Economic Security and Energy Challenges.</u>
                        </li>
                        <li>
                          <u>Peace and Social Security.</u>
                        </li>
                      </ol>
                      <u style={{ color: "#cf0202" }}>
                        Axis 4: Digital Technology and Cybersecurity
                      </u>
                      <ol>
                        <li>
                          <u>Cybersecurity and the Challenges of Cybercrime.</u>
                        </li>
                        <ul>
                          <li>
                            Effects of Cyber Threats on National Security.
                          </li>
                          <li>
                            Vital Infrastructure Security and National Security:
                          </li>
                          <ul>
                            <li>
                              The Role of Critical Infrastructure (Electricity,
                              Water, Transportation) in Maintaining and Ensuring
                              National Security.
                            </li>
                            <li>
                              Cyberattacks Targeting Critical Infrastructure and
                              Their Impact on National Security.
                            </li>
                          </ul>
                          <li>Espionage and Cyber Threats to the State.</li>
                          <li>
                            Enhancing International Cooperation in Combating
                            Cyber Threats.
                          </li>
                          <li>Awareness and Training in Cybersecurity.</li>
                          <li>
                            Developing National Capabilities in Cybersecurity.
                          </li>
                          <li>
                            Legal and Ethical Challenges in Cybersecurity.
                          </li>
                        </ul>
                        <li>
                          <u>
                            Technological Advancements and the Era of Big Data.
                          </u>
                        </li>
                        <li>
                          <u>
                            Militarization of Space and National Sovereignty.
                          </u>
                        </li>
                        <li>
                          <u>
                            Geospatial Domain and Information Systems
                            Technologies.
                          </u>
                        </li>
                        <li>
                          <u>Artificial Intelligence and National Security.</u>
                        </li>
                      </ol>
                      <ul>
                        <li>
                          National Security Challenges Linked to Artificial
                          Intelligence.
                        </li>
                        <li>
                          Potential Security Threats Posed by Artificial
                          Intelligence.
                        </li>
                        <li>
                          The Use of Artificial Intelligence in Cyber Attacks
                          and Espionage.
                        </li>
                        <li>
                          Legal and Ethical Challenges Associated with Military
                          Use of Artificial Intelligence.
                        </li>
                        <li>
                          Strategies to Enhance National Security through
                          Artificial Intelligence.
                        </li>
                        <li>
                          Developing Artificial Intelligence Technologies for
                          Border Monitoring and Protection.
                        </li>
                        <li>
                          Using Artificial Intelligence for Tactical and
                          Intelligence Analysis.
                        </li>
                        <li>
                          Strengthening Cyber Resilience and Response to Cyber
                          Threats using Artificial Intelligence.
                        </li>
                        <li>
                          International Cooperation and Coordination in National
                          Security and Artificial Intelligence.
                        </li>
                        <li>
                          The Future and Expected Developments in Artificial
                          Intelligence Technology and its Impact on National
                          Security.
                        </li>
                        <li>
                          Future Innovations in Artificial Intelligence and
                          their Effects on Security Threats and Opportunities.
                        </li>
                        <li>
                          The Impact of Machine Learning and Deep Learning on
                          National Security Strategies.
                        </li>
                        <li>
                          A Futuristic Analysis of How Nations Adapt to
                          Technological Transformations in Artificial
                          Intelligence.
                        </li>
                      </ul>
                      <u style={{ color: "#cf0202" }}>
                        Axis 5: Migration, Crime, and Terrorism, and Mechanisms
                        to Enhance Domestic Security and Develop Border Systems
                      </u>
                      <ol>
                        <li>Challenges Related to Migration:</li>
                        <ul>
                          <li>
                            Increasing Numbers of Migrants and Their Causes.
                          </li>
                          <li>
                            Impacts of Illegal Migration on Domestic Security.
                          </li>
                        </ul>
                        <li>Crime and Migration:</li>
                        <ul>
                          <li>Relationship between Crime and Migrants.</li>
                          <li>
                            Criminal Phenomena Associated with Illegal
                            Migration.
                          </li>
                        </ul>
                        <li>Terrorism and Migration:</li>
                        <ul>
                          <li>
                            Escalating Terrorism Threats Targeting Migrants.
                          </li>
                          <li>
                            Exploitation of Migration by Terrorist Groups.
                          </li>
                        </ul>
                        <li>Enhancing Domestic Security:</li>
                        <ul>
                          <li>
                            The Role of Security Agencies and Police in
                            Combating Crime and Terrorism.
                          </li>
                          <li>
                            International Collaboration to Combat Crime and
                            Share Information.
                          </li>
                        </ul>
                        <li>
                          Developing Security Systems for Borders and Crossings:
                        </li>
                        <ul>
                          <li>
                            Security Technologies and Monitoring at Borders.
                          </li>
                          <li>
                            Strengthening Preparedness and Response to Threats.
                          </li>
                        </ul>
                      </ol>
                      <u style={{ color: "#cf0202" }}>
                        Axis 6: Education and Scientific Research
                      </u>
                      <ol>
                        <li>
                          <u>
                            The Role of Education and Scientific Research in
                            Developing National Security Capabilities and
                            Knowledge.
                          </u>
                        </li>
                        <li>
                          <u>
                            Reviewing Best Practices in Promoting Education and
                            Scientific Research in the Field of National
                            Security.
                          </u>
                        </li>
                      </ol>
                      <u style={{ color: "#cf0202" }}>
                        Axis 7: Human Rights and National Security
                      </u>
                      <ol>
                        <li>
                          <u>
                            Achieving a Balance between National Security, Human
                            Rights, and Citizen Liberties.
                          </u>
                        </li>
                        <li>
                          <u>
                            Examining Legal and Ethical Challenges Related to
                            National Security Practices.
                          </u>
                        </li>
                      </ol>
                      <u style={{ color: "#cf0202" }}>
                        Axis 8: Local and International Laws and Legislation and
                        Their Role in Enhancing National Security
                      </u>
                      <li>
                        <u>
                          The Role of Regional and International Alliances in
                          Supporting National Security Strategies.
                        </u>
                      </li>
                      <li>
                        <u>
                          Enhancing International Cooperation in Areas such as
                          Trade, Environmental Security, and Development to
                          Promote National Security.
                        </u>
                      </li>
                      <li>
                        <u>
                          National and International Laws, Regulations, and
                          Bilateral Agreements.
                        </u>
                      </li>
                      <p style={{ color: "#cf0202" }}>
                        <b>
                          <u>Conference Objectives:</u>
                        </b>
                      </p>
                      <ol>
                        <li>
                          Understanding Current Challenges: Analyzing and
                          discussing the geostrategic shifts and security
                          challenges posed by issues such as illegal migration,
                          cyber security, artificial intelligence, climate
                          change, natural disasters, and more.
                        </li>
                        <li>
                          Knowledge and Experience Exchange: Providing a
                          platform for experts, researchers, and decision-makers
                          to share best practices, research findings, and
                          experiences in relevant national security fields.
                        </li>
                        <li>
                          Developing Effective Strategies: Steering the
                          discussions towards the development of tangible and
                          effective strategies to address major challenges in
                          enhancing national security capabilities.
                        </li>
                        <li>
                          International Collaboration: Discussing ways to
                          enhance cooperation and coordination among countries
                          and international organizations to tackle these
                          challenges at both regional and global levels.
                        </li>
                        <li>
                          Policy Development: Offering concrete policy
                          recommendations to assist governments in enhancing
                          their responses to these challenges.
                        </li>
                        <li>
                          Raising Awareness: Increasing public awareness about
                          the importance of addressing the mentioned challenges
                        </li>
                        <li>
                          Building Networks: Encouraging the establishment of
                          relationships and collaboration networks among
                          participants from various entities and communities
                          interested in national security.
                        </li>
                        <li>
                          Exploring Innovative Solutions: Encouraging
                          participants to present innovative solutions and new
                          technologies for dealing with these challenges.
                        </li>
                      </ol>

                      <p style={{ color: "#cf0202" }}>
                        <b>
                          <u>Participation Conditions</u>
                        </b>
                      </p>
                      <p>
                        Participation is open to experts, specialists, research
                        centers, study offices, universities, and all those
                        interested in the fields of national security, either by
                        attending or by submitting a scientific work or proposal
                        that can contribute to enriching the conference with new
                        insights. Submissions are subject to the same conditions
                        as those established for the GeoTunis International
                        Conference.
                      </p>
                      <p style={{ color: "#cf0202" }}>
                        <b>
                          <u>Acceptance of Abstracts </u>
                        </b>
                      </p>
                      <p>
                        All those wishing to submit a paper should send
                        abstracts of their work within the legal deadlines of
                        GeoTunis.
                      </p>
                    </TabPane>
                    <TabPane tabId="libya">
                      <center>
                        <h5>
                          Libya Day for GIS , Remote Sensing & Geo-AI in
                          Geo-Tunis
                        </h5>
                      </center>
                      <p style={{ color: "#cf0202" }}>
                        <b>
                          <u>Introduction</u>
                        </b>
                      </p>
                      <p>
                        Geographic Information Systems (GIS) and Geographic
                        Artificial Intelligence (GeoAI) are modern technologies
                        that are revolutionizing the way we think and interact
                        with the natural world and the surrounding geographical
                        environment. The continuous advancements in these fields
                        are opening up vast opportunities to harness technology
                        for a wide range of applications, whether it&#39;s in
                        scientific research, business development, urban
                        planning, agriculture, sustainable development, risk
                        management, and more. Thanks to the significant and
                        promising capabilities of GIS and GeoAI, and following
                        the resounding success of the Libyan GIS Day in its
                        previous editions, the organizers of &quot;Libyan GIS
                        and Geographic AI Day&quot; are delighted to announce
                        the new edition of this prominent event. This event will
                        be held as part of the major events of the 16th edition
                        of the international &quot;GeoTunis&quot; conference in
                        Hammamet, Tunisia, from November18 to November22, 2024.
                        This event aims to provide a leading platform for
                        researchers, professionals, and enthusiasts in the field
                        of GIS and GeoAI to exchange ideas, experiences, and
                        connect with their peers from around the world.
                        Participants will have the opportunity to stay updated
                        on the latest technological and research developments in
                        this field and explore how to apply them across a
                        diverse range of sectors. The Libyan GIS Day will
                        feature a diverse range of activities, including keynote
                        lectures by international experts, interactive
                        workshops, research sessions, and practical
                        applications. Participants will also have the
                        opportunity to present their research and work and
                        interact with their peers in the field. The organizers
                        of this event anticipate that &quot;GeoTunis / Libyan
                        GIS Day&quot; will contribute to enhancing international
                        cooperation and knowledge transfer in the field of GIS
                        and GeoAI, ultimately leading to more effective use of
                        these technologies for development and progress.
                        Moreover, Libyan GIS Day within GeoTunis is not only an
                        opportunity for investment but also a leading scientific
                        and technological market for producers and users of
                        these technologies. It serves as a platform for
                        knowledge exchange and investment opportunities, both
                        within Africa and internationally. We look forward to
                        your attendance and participation in this exciting and
                        productive event, which promises to be an exceptional
                        occasion for learning, sharing, and networking in this
                        thrilling field. The invitation is open for
                        participation, contribution to the conference, and
                        enriching its content.
                        <center>
                          <b>With warm regards, The Organizing Committee</b>
                        </center>
                      </p>
                      <p style={{ color: "#cf0202" }}>
                        <b>
                          <u>Objectives of Libya GISDay:</u>
                        </b>
                      </p>
                      <p>
                        <b>
                          Advance investment and partnership through:
                          <ol type="1">
                            <li>
                              Promoting cooperation between private and public
                              institutions and strengthening cooperative
                              relations between Libyan institutions and
                              international companies active in the field of
                              geographic information systems technology and
                              geospace.
                            </li>
                            <li>
                              Supporting planning domains by relying on digital
                              technologies
                            </li>
                            <li>
                              Building a geographical database and establishing
                              an infrastructure for spatial data
                            </li>
                            <li>
                              Establishing a comprehensive development using
                              geospace technology and geographic information
                              systems
                            </li>
                            <li>
                              Mainstreaming the use of remote sensing technology
                              and geographic information systems in various
                              priority sectors such as the following fields of
                              application:
                            </li>
                          </ol>
                        </b>
                        The security and military field, health and epidemic
                        control,the field of energy and oil, smart cities, urban
                        planning, management of natural and water resources,
                        management of infrastructures, management of sewage
                        systems, management of the environmental field and
                        pollution, management of archaeological; religious
                        monuments and pilgrimage, communication management,
                        building atlases and geographic portals, The field of
                        statistics, population census, election maps…
                      </p>
                      <p style={{ color: "#cf0202" }}>
                        <b>
                          <u>Scientific themes</u>
                        </b>
                      </p>

                      <b>
                        <ol type="I">
                          <li>
                            Applications of Geographic Information Systems
                            (GIS), Remote Sensing, and Artificial Intelligence
                            in Disaster Management and Rapid Response
                          </li>
                          <ol type="1">
                            <li>
                              Geographic Applications in Disaster Management
                            </li>
                            <ul>
                              <li>
                                Application of GIS in disaster management.
                              </li>
                              <li>
                                Using digital maps and GIS for risk analysis.
                              </li>
                              <li>
                                Role of satellite imagery in disaster
                                monitoring.
                              </li>
                              <li>Remote sensing in disaster monitoring.</li>
                              <li>
                                Utilizing remote sensing technology on
                                satellites and drones for continuous monitoring
                                of disaster-prone areas.
                              </li>
                            </ul>
                            <li>
                              Artificial Intelligence and Disaster Management
                            </li>
                            <ul>
                              <li>
                                Applications of artificial intelligence in
                                disaster prediction.
                              </li>
                              <li>
                                Using artificial intelligence to enhance
                                disaster response.
                              </li>
                              <li>
                                The role of artificial intelligence in disaster
                                management.
                              </li>
                              <li>
                                Machine learning and big data analysis for
                                disaster prediction and response planning.
                              </li>
                            </ul>
                            <li>
                              Disaster Management and Rapid Response Planning
                            </li>
                            <ul>
                              <li>
                                Organizing and coordinating efforts during
                                disasters.
                              </li>
                              <li>
                                Strategies for implementing information
                                technology in rapid response.
                              </li>
                              <li>
                                Using digital maps and GIS for resource
                                management and aid in evacuation and relief
                                operations.
                              </li>
                              <li>
                                The role of spatial and temporal data in
                                decision support.
                              </li>
                            </ul>
                          </ol>
                          <li>
                            Applications of Geographic Information Systems and
                            Remote Sensing in the Service Sector
                          </li>
                          <ul>
                            <li>
                              Applications in Health, Recovery, and Epidemic
                              Control.
                            </li>
                            <li>Applications in Education and Training.</li>
                            <li>
                              Applications in Telecommunications and Fiber Optic
                              Network Construction.
                            </li>
                            <li>Applications in Population Census.</li>
                            <li>
                              Applications in Transportation and Mobility.
                            </li>
                            <li>Applications in Tourism and Archaeology.</li>
                            <li>Applications in Social Services.</li>
                          </ul>
                          <li>
                            Applications of Geographic Information Systems and
                            Remote Sensing in the Field of Security and Defense
                          </li>
                          <ul>
                            <li>
                              Urban and Personal Security and Facility
                              Protection.
                            </li>
                            <li>
                              Border and Maritime Security and Surveillance.
                            </li>
                            <li>
                              Counter-smuggling and Unauthorized Migration.
                            </li>
                            <li>Civil Defense.</li>
                            <li>Crime Prevention and Counter-terrorism.</li>
                            <li>
                              Cybersecurity and Artificial Intelligence in
                              Security.
                            </li>
                          </ul>
                          <li>Smart Cities and Sustainable Urban Planning.</li>
                          <li>Environment and Climate Change.</li>
                          <li>
                            Agriculture and Natural and Water Resource
                            Management.
                          </li>
                          <li>
                            Energy, Renewable Energy, Industry, and Mining.
                          </li>
                          <li>Urban Planning and Municipal Work.</li>
                          <li>Coastal Zone Management.</li>
                        </ol>
                      </b>
                      <p style={{ color: "#cf0202" }}>
                        <b>
                          <u>The general content of the Libyan day</u>
                        </b>
                      </p>
                      <ul>
                        <li>
                          Scientific sessions / lectures and technical
                          presentations
                        </li>
                        <li>Technical workshops</li>
                        <li>Training workshops</li>
                        <li>
                          An exhibition for companies, research centers and
                          ministries
                        </li>
                        <li>B2B business meetings</li>
                      </ul>
                      <p style={{ color: "#cf0202" }}>
                        <b>
                          <u>Targeted stakeholdersfrom the Libyan day</u>
                        </b>
                      </p>
                      <ul>
                        <li>Ministries and public and private institutions</li>
                        <li>Companies and study offices</li>
                        <li>Universities, institutes, research centers</li>
                        <li>Students and undergraduates</li>
                        <li>Municipalities and local and regional councils</li>
                        <li>Workers in the service sector</li>
                      </ul>
                      <p style={{ color: "#cf0202" }}>
                        <b>
                          <u>Joint Preparatory Committee</u>
                        </b>
                      </p>
                      <b>
                        Scientific Committee / Chairmanship of the Scientific
                        Council
                      </b>
                      <ul>
                        <li>Dr. Moftah Dkhil - Libyan Geographical Society</li>
                        <li>
                          Dr. Mohamed Ayari / Tunisian Association of Digital
                          Geographic Information
                        </li>
                      </ul>
                      <b>
                        The Preparatory Committee / Chairing the Preparatory
                        Committee
                      </b>
                      <ul>
                        <li>Mr Mohamed Idris - Tripoli University- Libya</li>
                        <li>Mrs Hala Saidi - Tunisia</li>
                      </ul>
                      <p style={{ color: "#cf0202" }}>
                        <b>
                          <u>Libyan Day presenting languages:</u>
                        </b>
                      </p>
                      <ul>
                        <li>Arabic</li>
                        <li>English</li>
                      </ul>
                      <p style={{ color: "#cf0202" }}>
                        <b>
                          <u>Important dates</u>
                        </b>
                      </p>
                      <b>
                        <ul>
                          <li>
                            <u>
                              The deadline for receiving business summaries:
                              September 30, 2024
                            </u>
                          </li>
                          <li>
                            <u>
                              The deadline for registration to attend: November
                              10, 2024
                            </u>
                          </li>
                        </ul>
                      </b>
                    </TabPane>
                    <TabPane tabId="program">
                      <Table bordered ref={this.tabRef}>
                        <tbody>
                          <tr>
                            <td>
                              <img
                                src="./static/img/geotunis16/SGS.jpg"
                                alt="sgs"
                                className="img-thumbnail mx-auto d-block"
                              />
                            </td>
                            <td>
                              <div className="text-center">
                                <p>الجمعية الجغرافية السعودية</p>
                                <p>Saudi Geographical Society (SGS)</p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <img
                                src="./static/img/geotunis16/AUSGEO.jpg"
                                alt="AUSGEO"
                                className="img-thumbnail mx-auto d-block"
                              />
                            </td>
                            <td>
                              <div className="text-center">
                                <p>الاتحاد العربي للمساحة</p>
                                <p>AUS GEO</p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <img
                                src="./static/img/geotunis16/UEAGEO.jpg"
                                width="150"
                                height="150"
                                alt="UEAGEO"
                                className="img-thumbnail mx-auto d-block"
                              />
                            </td>
                            <td>
                              <div className="text-center">
                                <p>الاتحاد الاوروعربي للجيوماتيك</p>
                                <p>UEA-GEO</p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <img
                                src="./static/img/geotunis16/suptech.jpg"
                                alt="suptech"
                                className="img-thumbnail mx-auto d-block"
                              />
                            </td>
                            <td>
                              <div className="text-center">
                                <p>
                                  الجامعة الخاصة للتكنولوجيا و إدارة الاعمال
                                </p>
                                <p>SUPTECH</p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <img
                                src="./static/img/geotunis16/DACFS.png"
                                alt="DACFS"
                                className="img-thumbnail mx-auto d-block"
                              />
                            </td>
                            <td>
                              <div className="text-center">
                                <p>
                                  المركز العربي الدنماركي للدراسات المستقبلية في
                                  كوبنهاكن / الدنمارك
                                </p>
                                <p>DACFS</p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <img
                                src="./static/img/geotunis16/EMS.jpg"
                                alt="EMS"
                                className="img-thumbnail mx-auto d-block"
                              />
                            </td>
                            <td>
                              <div className="text-center">
                                <p>الجمعية الجغرافية الامارتية</p>
                                <p>Emirates Geographical Society</p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <img
                                src="./static/img/geotunis16/IGU.jpg"
                                alt="IGU"
                                className="img-thumbnail mx-auto d-block"
                              />
                            </td>
                            <td>
                              <div className="text-center">
                                <p>الاتحاد الدولي للجغرافيا</p>
                                <p>International Geographical Union</p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <img
                                src="./static/img/geotunis16/ICA.jpg"
                                width="150"
                                height="150"
                                alt="ICA"
                                className="img-thumbnail mx-auto d-block"
                              />
                            </td>
                            <td>
                              <div className="text-center">
                                <p>الرابطة الدولية للكارتوغرافيا</p>
                                <p>ICA</p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <img
                                src="./static/img/geotunis16/SNGS.jpg"
                                alt="SNGS"
                                className="img-thumbnail mx-auto d-block"
                              />
                            </td>
                            <td>
                              <div className="text-center">
                                <p>الجمعية الجغرافية الوطنية السودانية</p>
                                <p>Sudanese National Geographic Society</p>
                              </div>
                            </td>
                          </tr>
                          <tr className="text-center">
                            <td>
                              Euro-Mediterranean Federation for Scientific
                              Research and Technological Innovation
                            </td>
                            <td>
                              الفيدرالية الاورومتوسطية للبحث العلمي و التجديد
                              التكنولوجي
                            </td>
                          </tr>
                          <tr className="text-center">
                            <td>Maghreb Organization GIS and Remote Sensing</td>
                            <td>
                              المنظمة المغاربية لنظم المعلومات الجغرافية و
                              الاستشعار عن بعد
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <img
                                src="./static/img/geotunis16/GSPMAG.jpg"
                                alt="GEO-SP"
                                className="img-thumbnail mx-auto d-block"
                              />
                            </td>
                            <td>
                              <div className="text-center">
                                <p>مجلة منشورات علوم جغرافية</p>
                                <p>Geographical Sciences Publications MAG</p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <img
                                src="./static/img/geotunis16/AECSFMMO.jpg"
                                height="150"
                                width="150"
                                alt="AECSFMMO"
                                className="img-thumbnail mx-auto d-block"
                              />
                            </td>
                            <td>
                              <div className="text-center">
                                <p>
                                  المنظمة الفرنسية للتبادل العلمي و الثقافي مع
                                  المغرب و الشرق الأوسط
                                </p>
                                <p>
                                  The French Organization for Scientific and
                                  Cultural Exchange with Magreb and the Middle
                                  East
                                </p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <img
                                src="./static/img/geotunis16/50africa.jpg"
                                height="150"
                                width="150"
                                alt="50Africa"
                                className="img-thumbnail mx-auto d-block"
                              />
                            </td>
                            <td>
                              <div className="text-center">
                                <p>جمعية افريقيا 50 فرنسا</p>
                                <p>Association Africa 50 / France</p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <img
                                src="./static/img/geotunis16/AFFA.jpg"
                                alt="AMAF"
                                className="img-thumbnail mx-auto d-block"
                              />
                            </td>
                            <td>
                              <div className="text-center">
                                <p>جمعية أصدقاء افريقيا الفرنكوفونية</p>
                                <p>
                                  Association of Friends of Francophone Africa
                                </p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <img
                                src="./static/img/geotunis16/FSG.jpg"
                                alt="FSG"
                                className="img-thumbnail mx-auto d-block"
                              />
                            </td>
                            <td>
                              <div className="text-center">
                                <p>مجلة الندوة للدراسات القانونية</p>
                                <p>Al Nadwa Journal for Legal Studies</p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <img
                                src="./static/img/geotunis16/OGTL.jpg"
                                width="150"
                                height="150"
                                alt="OGTL"
                                className="img-thumbnail mx-auto d-block"
                              />
                            </td>
                            <td>
                              <div className="text-center">
                                <p>نقابة الطوبوغرافيين المجازين بلبنان</p>
                                <p>OGTL</p>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                      <Table bordered ref={this.tabRef}>
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="lang text-center"
                              key="rfHead1"
                              colspan="2"
                            >
                              Media partner
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <img
                                src="./static/img/geotunis16/internationalca.jpg"
                                alt="International_Conference_Alerts "
                                width="200"
                                height="200"
                                className="img-thumbnail mx-auto d-block"
                              />
                            </td>
                            <td>
                              <div className="text-center">
                                <a
                                  href="https://internationalconferencealerts.com/"
                                  target="_blank"
                                >
                                  <p>International Conference Alerts</p>
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </TabPane>
                    <TabPane tabId="fees">
                      <center>
                        <p style={{ color: "#cf0202" }}>
                          <b>
                            <u>Registration fees for Geotunis and Libya Day</u>
                          </b>
                        </p>
                      </center>
                      <Table bordered ref={this.tabRef}>
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="lang text-center"
                              key="rfHead1"
                              colspan="2"
                            >
                              Participation fee with accommodation in a Double
                              room
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th className="lang" key="rfcategory1">
                              University professors and teachers
                            </th>
                            <th className="lang" key="fee1">
                              500 Euros
                            </th>
                          </tr>
                          <tr>
                            <th className="lang" key="rfcategory2">
                              Students
                            </th>
                            <th className="lang" key="fee2">
                              450 Euros
                            </th>
                          </tr>
                          <tr>
                            <th className="lang" key="rfcategory3">
                              Companies, Official and International Institutions
                            </th>
                            <th className="lang" key="fee3">
                              550 Euros
                            </th>
                          </tr>
                          <tr>
                            <th className="lang" key="rfcategory4">
                              Exhibition booth
                            </th>
                            <th className="lang" key="fee4">
                              900 Euros
                            </th>
                          </tr>
                          <tr>
                            <th className="lang" key="rfcategory5">
                              Family companion, Accommodation in the same room
                            </th>
                            <th className="lang" key="fee5">
                              250 Euros
                            </th>
                          </tr>
                        </tbody>
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="lang text-center"
                              key="rfHead2"
                              colspan="2"
                            >
                              Participation fee with accommodation in a Single
                              room
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th className="lang" key="rfcategory1">
                              University professors and teachers
                            </th>
                            <th className="lang" key="fee6">
                              550 Euros
                            </th>
                          </tr>
                          <tr>
                            <th className="lang" key="rfcategory2">
                              Students
                            </th>
                            <th className="lang" key="fee7">
                              500 Euros
                            </th>
                          </tr>
                          <tr>
                            <th className="lang" key="rfcategory3">
                              Companies, Official and International Institutions
                            </th>
                            <th className="lang" key="fee8">
                              600 Euros
                            </th>
                          </tr>
                          <tr>
                            <th className="lang" key="rfcategory4">
                              Exhibition booth
                            </th>
                            <th className="lang" key="fee4">
                              900 Euros
                            </th>
                          </tr>
                        </tbody>
                      </Table>
                      <h4
                        className="lang h4GeoTunis"
                        key="includes"
                        style={{ color: "#cf0202" }}
                      >
                        Registration Fee Includes
                      </h4>
                      <ul ref={this.ulRef}>
                        <li className="lang" key="include1">
                          Accommodation for 5 days, 4 nights, with full pension
                          coverage, morning breakfast, lunch and dinner
                        </li>
                        <li className="lang" key="include2">
                          Residence starts from 2:00 pm on November 18 and ends
                          at midday on November 22, 2024.
                        </li>
                        <li className="lang" key="include3">
                          Attending various scientific events
                        </li>
                        <li className="lang" key="include4">
                          Participation in the opening and closing ceremonies
                        </li>
                        <li className="lang" key="include5">
                          Coffee Break
                        </li>
                        <li className="lang" key="include6">
                          Publications and releases of the conference
                        </li>
                        <li className="lang" key="include7">
                          Document carrier
                        </li>
                        <li className="lang" key="include8">
                          Scientific publication of the activities of the
                          conference
                        </li>
                        <li>Social program</li>
                      </ul>
                      <h4
                        className="lang h4GeoTunis"
                        key="includes"
                        style={{ color: "#cf0202" }}
                      >
                        Online participation fees
                      </h4>
                      150 Euros
                      <h4
                        className="lang h4GeoTunis"
                        key="method"
                        style={{ color: "#cf0202" }}
                      >
                        Payment method
                      </h4>
                      <p className="lang" key="methodp1">
                        payment by bank transfer to the current account of the
                        association or at sight during the conference
                        registration day.
                      </p>
                      <ul ref={this.ulRef}>
                        <li className="lang" key="bankinfo1">
                          Bank Name: ATTIJARI BANK
                        </li>
                        <li className="lang" key="bankinfo2">
                          Branch / Agency / Paris Street Tunis - AGENCE AVENUE
                          PARIS TUNIS
                        </li>
                        <li className="lang" key="bankinfo3">
                          R.I.B Current account number: 04018104000419165651
                        </li>
                        <li className="lang" key="bankinfo4">
                          I.B.A.N: TN59 04018104000419165651
                        </li>
                        <li className="lang" key="bankinfo5">
                          Account Holder Name / ATIGN - GEOTUNIS
                        </li>
                        <li className="lang" key="bankinfo6">
                          Swift: BSTUTNTT
                        </li>
                      </ul>
                      <h4
                        className="lang h4GeoTunis"
                        key="method2"
                        style={{ color: "#cf0202" }}
                      >
                        Payment method for participants from Tunisian
                        institutions
                      </h4>
                      <p className="lang" key="methodp1">
                        payment by bank transfer to the current account of the
                        association or at sight during the conference
                        registration day.
                      </p>
                      <ul ref={this.ulRef}>
                        <li className="lang" key="bankinfo1">
                          Bank Name: ATTIJARI BANK
                        </li>
                        <li className="lang" key="bankinfo2">
                          Branch / Agency / Paris Street Tunis - AGENCE AVENUE
                          PARIS TUNIS
                        </li>
                        <li className="lang" key="bankinfo3">
                          R.I.B Current account number: 04018104000419165651
                        </li>
                        <li className="lang" key="bankinfo4">
                          I.B.A.N: TN59 04018104000419165651
                        </li>
                        <li className="lang" key="bankinfo5">
                          Account Holder Name / ATIGN - GEOTUNIS
                        </li>
                        <li className="lang" key="bankinfo6">
                          Swift: BSTUTNTT
                        </li>
                      </ul>
                      <p className="lang" key="methodp2">
                        The transfer link should be sent via fax or e-mail For
                        further information, please contact the Organizing
                        Committee at the following addresses:
                        geoconf2@gmail.com/ atignassotiation@gmail.com Tel. /
                        Fax: (00216)71 245 692
                      </p>
                      <a
                        href="https://forms.gle/FT9YqMBJJWNdMwbe6"
                        target="_blank"
                        className="aGeoTunis"
                      >
                        <Button
                          color="primary"
                          className="lang"
                          key="registerBtn"
                        >
                          Register now
                        </Button>
                      </a>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default GeoTunisEn;
