import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import InscriptionForm from "../Forms/InscriptionForm";
class RegisterModal extends Component {
  render() {
    return (
      <div>
        <Modal isOpen={this.props.showIscriptionModal} toggle={this.props.toggleInscriptionModal}>
          <ModalHeader toggle={this.props.toggleInscriptionModal}>SignUp</ModalHeader>
          <ModalBody>
            <InscriptionForm toggle={this.props.toggleInscriptionModal} handleLoginState = {this.props.handleLoginState} isLoggedIn={this.props.isLoggedIn}/>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
export default RegisterModal;
