import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import LoginForm from "../Forms/LoginForm";


class LoginModal extends Component {
  render() {
    return (
      <div>
        <Modal isOpen={this.props.showLoginModal} toggle={this.props.toggleLoginModal}>
          <ModalHeader toggle={this.props.toggleLoginModal}>SignIn</ModalHeader>
          <ModalBody>
            <LoginForm toggle={this.props.toggleLoginModal} handleLoginState = {this.props.handleLoginState} isLoggedIn={this.props.isLoggedIn} userData={this.props.userData}/>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
export default LoginModal;
