import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  InputGroup,
  InputGroupText
} from "reactstrap";

import axiosInstance from "../../axios";

class AddEvent extends Component {
  constructor(props) {
    super(props);
    this.state = Object.freeze({
      title: "",
      localisation: "",
      dateDeb: "",
      dateFin: "",
      moreDetails: "",
      image: null,
      errors: {},
      loading: false,
      fileName: ""
    });
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleImageChange = (e) => {
    const file = e.target.files[0];
    this.setState({ image: file , fileName: file.name});
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    const formData = new FormData();
    formData.append("title", this.state.title);
    formData.append("localisation", this.state.localisation);
    formData.append("dateDeb", this.state.dateDeb);
    formData.append("dateFin", this.state.dateFin);
    formData.append("moreDetails", this.state.moreDetails);
    formData.append("image", this.state.image);

    axiosInstance
      .post("events/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const { events } = this.props;
        const newEvent = response.data;
        const updatedEvents = [...events, newEvent];
        this.props.updateEvents(updatedEvents);
        window.location.reload();
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.error("Error: ", error);
        this.setState({ loading: false });
      });
  };

  render() {
    const {loading} = this.state
    return (
      <div className="addevent">
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="title">Event Title</Label>
                <Input
                  id="title"
                  name="title"
                  placeholder="Enter the event title"
                  type="text"
                  value={this.state.title}
                  onChange={this.handleChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="localisation">Location</Label>
                <Input
                  id="localisation"
                  name="localisation"
                  placeholder="Enter the event location"
                  type="text"
                  value={this.state.localisation}
                  onChange={this.handleChange}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="dateDeb">Starting date</Label>
                <Input
                  id="dateDeb"
                  name="dateDeb"
                  placeholder="Enter the event's starting date"
                  type="date"
                  value={this.state.dateDeb}
                  onChange={this.handleChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="dateFin">End date</Label>
                <Input
                  id="dateFin"
                  name="dateFin"
                  placeholder="Enter the event's end date"
                  type="date"
                  value={this.state.dateFin}
                  onChange={this.handleChange}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Label for="moreDetails">Drive link or other site</Label>
            <Input
              id="moreDetails"
              name="moreDetails"
              placeholder="Enter the drive link or other site"
              type="text"
              onChange={this.handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="file">Event poster
            <InputGroup className="mt-3" style={{ cursor: "pointer" }}>
              <InputGroupText>Select a file</InputGroupText>
              <Input
                disabled="true"
                placeholder={this.state.fileName || "No file selected"}
              />
            </InputGroup>
            <Input
              id="file"
              name="file"
              placeholder="Add the event poster"
              type="file"
              accept="image/png, image/jpg, image/jpeg"
              onChange={this.handleImageChange}
              style={{visibility: "hidden"}}
              required
            />
            </Label>
          </FormGroup>
          {loading ? (
            <Button color="primary" disabled={true} className="mb-3">
              <Spinner size="sm" />
            </Button>
          ) : (
            <Button color="primary" type="submit" className="mb-3">
              Add the event
            </Button>
          )}

          <Link to="../" >
            <Button color="danger" className="mb-3 ms-3">Cancel</Button>
          </Link>
        </Form>
      </div>
    );
  }
}
export default AddEvent;
