import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardHeader,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap";

const Partners = () => {
  const partners = [
    {
      src: "./static/img/UAG.jpg",
      title: "Arab Union of Surveyors",
      link: "https://www.auog.org/",
    },
    {
      src: "./static/img/ICT.png",
      title: "International Cartographic Association (ICA)",
      link: "https://icaci.org/",
    },
    {
      src: "./static/img/geosp.png",
      title: "Geo SP - MAG",
      link: "https://www.facebook.com/GeoSciencePublication",
    },
    {
      src: "./static/img/suptech.png",
      title: "SUPTECH",
      link: "https://suptech.tn/",
    },
    {
      src: "./static/img/IGU.png",
      title: "International Geographical Union",
      link: "https://igu-online.org/",
    },
    {
      src: "./static/img/kfu.png",
      title: "King Faisal University in Chad",
      link: "https://en.wikipedia.org/wiki/King_Faisal_University",
    },
    {
      src: "./static/img/af50.jpg",
      title: "Africa50",
      link: "https://www.africa50.com/fr/",
    },
    {
      src: "./static/img/tgs.png",
      title: "Turkish Geographical Society",
      link: "https://www.tck.org.tr/en/",
    },
    {
      src: "./static/img/egs.png",
      title: "Emirates Geographical Society",
      link: "https://emiratesgeog.org/",
    },
    {
      src: "./static/img/no-logo.png",
      title: "Egyptian Geographical Society",
      link: "https://fr.wikipedia.org/wiki/Soci%C3%A9t%C3%A9_de_g%C3%A9ographie_d%27%C3%89gypte",
    },
    {
      src: "./static/img/no-logo.png",
      title: "Euro-Arab Union of Geomatics",
      link: "https://if3g.unioneag.org/",
    },
    {
      src: "./static/img/no-logo.png",
      title: "Francophone Friends of Africa Organization",
    },
    {
      src: "./static/img/no-logo.png",
      title: "French Organization for Scientific and Cultural Exchange",
    },
    {
      src: "./static/img/no-logo.png",
      title: "Maghreb Organization for Remote Sensing",
    },
    {
      src: "./static/img/no-logo.png",
      title: "Sudanese National Geographic Society",
    },
    {
      src: "./static/img/no-logo.png",
      title: "Mediterranean Association for Scientific Research",
    },
  ];

  return (
    <div className="partner-container">
      <Container className="text-center mt-5">
        <Row className="row-content mt-5">
          <h4 className="mt-5">Our Partners</h4>
          {partners.map((partner, index) => (
            <Col key={index} xs="12" sm="6" md="4" lg="3">
              <Card className="mb-3">
                <CardImg top src={partner.src} alt={partner.title} />
                <CardHeader className="text-center">
                  <CardTitle>{partner.title}</CardTitle>
                </CardHeader>
                {partner.link ? (
                  <CardBody className="text-center">
                    <Button color="primary" href={partner.link} target='_blank'>
                      More detail
                    </Button>
                  </CardBody>
                ) : (
                  <></>
                )}
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Partners;
