import { Component } from "react";
import axiosInstance from "../../../axios";

import { Card, CardBody } from "reactstrap";

import { Line } from "react-chartjs-2";
import { registerables, Chart } from "chart.js";

Chart.register(...registerables);

class MembersPerYearStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usersPerYear: [],
    };
  }

  componentDidMount() {
    this.fetchMemberPerYear();
  }

  fetchMemberPerYear = () => {
    axiosInstance
      .get("admin/statistics/users-per-year/")
      .then((response) => {
        this.setState({ usersPerYear: response.data });
      })
      .catch((error) =>
        console.log("error fetching members per yeas: ", error)
      );
  };

  render() {
    const { usersPerYear } = this.state;

    const chartData = {
      labels: usersPerYear.map((data) => data.year),
      datasets: [
        {
          label: "Inscription par an",
          data: usersPerYear.map((data) => data.Membre_Inscrit),
          borderColor: "#8884d8",
          pointRadius: 8,
          fill: false,
        },
      ],
    };

    const chartOptions = {
      scales: {
        x: {
          type: "category",
        },
        y: {
          ticks : {precision: 0,}
          
        },
      },
    };
    return (
      <Card
        style={{
          width: "15rem",
        }}
        className="mt-2"
      >
        <CardBody>
          <div>
            <Line data={chartData} options={chartOptions}/>
          </div>
        </CardBody>
      </Card>
    );
  }
}
export default MembersPerYearStats;
