import React, { Component } from "react";
import { Container, Row, Col, UncontrolledCarousel } from "reactstrap";

const items = [
  {
    src: "./static/img/1.jpg",
  },
  {
    src: "./static/img/2.jpg",
  },
  {
    src: "./static/img/3.jpg",
  },
  {
    src: "./static/img/5.jpg",
  },
  {
    src: "./static/img/6.jpg",
  },
  {
    src: "./static/img/7.jpg",
  },
  {
    src: "./static/img/8.jpg",
  },
];

class PhotoGallery extends Component {
  render() {
    return (
      <Container>
        <hr />
        <Row className="row-content">
          <Col sm="7" className="mt-3 mx-auto">
            <UncontrolledCarousel items={items} />
          </Col>
        </Row>
      </Container>
    );
  }
}
export default PhotoGallery;
