import React, { Component } from "react";
import {
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  FormFeedback,
  Spinner,
  Alert,
} from "reactstrap";
import axiosInstance from "../../axios";

class InscriptionForm extends Component {
  constructor(props) {
    super(props);
    this.state = Object.freeze({
      nom: "",
      prenom: "",
      dateNaiss: "",
      mail: "",
      password: "",
      password2: "",
      numTel: "",
      pays: "",
      niveaux: "",
      organisation: "",
      loading: false,
      success: false,
      errors: {},
    });
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ loading: true });

    const {
      nom,
      prenom,
      dateNaiss,
      mail,
      password,
      password2,
      numTel,
      pays,
      niveaux,
      organisation,
    } = this.state;
    if (password !== password2) {
      this.setState({
        errors: { password2: "Les mots de passe doivent correspondre" },
      });
      return;
    }
    axiosInstance.get(`utilisateurs/?mail=${mail}`).then((response) => {
      console.log(response.data)
      if (response.data.exists === true) {
        this.setState({
          errors: { mail: "User with this mail already exists" },
          loading: false,
          success: false
        });
      } else {
        axiosInstance
          .post("utilisateurs/inscription/", {
            nom: nom,
            prenom: prenom,
            dateNaiss: dateNaiss,
            mail: mail,
            password: password,
            numTel: numTel,
            pays: pays,
            niveaux: niveaux,
            organisation: organisation,
          })
          .then(() => {
            this.setState({ success: true, loading: false });
            setTimeout(() => {
              this.props.toggle();
            }, 2000);
          })
          .catch((error) => {
            console.log(error);
            this.setState({ loading: false });
          });
      }
    });
  }

  render() {
    const { errors, loading, success } = this.state;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="nom">First name</Label>
              <Input
                id="nom"
                name="nom"
                placeholder="Enter your first name"
                type="text"
                value={this.state.nom}
                onChange={this.handleChange}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="prenom">Last name</Label>
              <Input
                id="prenom"
                name="prenom"
                placeholder="Enter your last name"
                type="text"
                value={this.state.prenom}
                onChange={this.handleChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Label for="dateNaiss">Date of birth</Label>
          <Input
            id="dateNaiss"
            name="dateNaiss"
            placeholder="Enter your date of birth"
            type="date"
            value={this.state.dateNaiss}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="mail">Email</Label>
          <Input
            id="mail"
            name="mail"
            placeholder="Enter your email address"
            type="email"
            value={this.state.mail}
            onChange={this.handleChange}
            invalid={!!errors.mail}
          />
          {errors.mail && <FormFeedback>{errors.mail}</FormFeedback>}
        </FormGroup>
        <FormGroup>
          <Label for="password">Password</Label>
          <Input
            id="password"
            name="password"
            placeholder="Enter your password"
            type="password"
            value={this.state.password}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="password2">Confirm your password</Label>
          <Input
            id="password2"
            name="password2"
            placeholder="Confirm your password"
            type="password"
            value={this.state.password2}
            onChange={this.handleChange}
            invalid={!!errors.password2}
          />
          {errors.password2 && <FormFeedback>{errors.password2}</FormFeedback>}
        </FormGroup>
        <FormGroup>
          <Label for="numTel">Phone number</Label>
          <Input
            id="numTel"
            name="numTel"
            placeholder="Enter your phone number"
            type="number"
            value={this.state.numTel}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="pays">Country</Label>
          <Input
            id="pays"
            name="pays"
            placeholder="Enter your country"
            type="select"
            value={this.state.pays}
            onChange={this.handleChange}
          >
            <option value="">Select your country of residence</option>
            <option value="Afghanistan">Afghanistan</option>
            <option value="Albania">Albania</option>
            <option value="Algeria">Algeria</option>
            <option value="Andorra">Andorra</option>
            <option value="Angola">Angola</option>
            <option value="Antigua & Deps">Antigua & Deps</option>
            <option value="Argentina">Argentina</option>
            <option value="Armenia">Armenia</option>
            <option value="Australia">Australia</option>
            <option value="Austria">Austria</option>
            <option value="Azerbaijan">Azerbaijan</option>
            <option value="Bahamas">Bahamas</option>
            <option value="Bahrain">Bahrain</option>
            <option value="Bangladesh">Bangladesh</option>
            <option value="Barbados">Barbados</option>
            <option value="Belarus">Belarus</option>
            <option value="Belgium">Belgium</option>
            <option value="Belize">Belize</option>
            <option value="Benin">Benin</option>
            <option value="Bhutan">Bhutan</option>
            <option value="Bolivia">Bolivia</option>
            <option value="Bosnia">Bosnia</option>
            <option value="Herzegovina">Herzegovina</option>
            <option value="Botswana">Botswana</option>
            <option value="Brazil">Brazil</option>
            <option value="Brunei">Brunei</option>
            <option value="Bulgaria">Bulgaria</option>
            <option value="Burkina">Burkina</option>
            <option value="Burundi">Burundi</option>
            <option value="Cambodia">Cambodia</option>
            <option value="Cameroon">Cameroon</option>
            <option value="Canada">Canada</option>
            <option value="Cape Verde">Cape Verde</option>
            <option value="Central African">Central African</option>
            <option value="Rep Chad">Rep Chad</option>
            <option value="Chile">Chile</option>
            <option value="China">China</option>
            <option value="Colombia">Colombia</option>
            <option value="Comoros">Comoros</option>
            <option value="Congo">Congo</option>
            <option value="Congo (Democratic Rep)">
              Congo (Democratic Rep)
            </option>
            <option value="Costa Rica">Costa Rica</option>
            <option value="Croatia">Croatia</option>
            <option value="Cuba">Cuba</option>
            <option value="Cyprus">Cyprus</option>
            <option value="Czech Republic">Czech Republic</option>
            <option value="Denmark">Denmark</option>
            <option value="Djibouti">Djibouti</option>
            <option value="Dominica">Dominica</option>
            <option value="Dominican Republic">Dominican Republic</option>
            <option value="East Timor">East Timor</option>
            <option value="Ecuador">Ecuador</option>
            <option value="Egypt">Egypt</option>
            <option value="El Salvador">El Salvador</option>
            <option value="Equatorial Guinea">Equatorial Guinea</option>
            <option value="Eritrea">Eritrea</option>
            <option value="Estonia">Estonia</option>
            <option value="Ethiopia">Ethiopia</option>
            <option value="Fiji">Fiji</option>
            <option value="Finland">Finland</option>
            <option value="France">France</option>
            <option value="Gabon">Gabon</option>
            <option value="Gambia">Gambia</option>
            <option value="Georgia">Georgia</option>
            <option value="Germany">Germany</option>
            <option value="Ghana">Ghana</option>
            <option value="Greece">Greece</option>
            <option value="Grenada">Grenada</option>
            <option value="Guatemala">Guatemala</option>
            <option value="Guinea">Guinea</option>
            <option value="Guinea-Bissau">Guinea-Bissau</option>
            <option value="Guyana">Guyana</option>
            <option value="Haiti">Haiti</option>
            <option value="Honduras">Honduras</option>
            <option value="Hungary">Hungary</option>
            <option value="Iceland">Iceland</option>
            <option value="India">India</option>
            <option value="Indonesia">Indonesia</option>
            <option value="Iran">Iran</option>
            <option value="Iraq">Iraq</option>
            <option value="Ireland (Republic)">Ireland (Republic)</option>
            <option value="Italy">Italy</option>
            <option value="Ivory Coast">Ivory Coast</option>
            <option value="Jamaica Japan">Jamaica Japan</option>
            <option value="Jordan">Jordan</option>
            <option value="Kazakhstan">Kazakhstan</option>
            <option value="Kenya">Kenya</option>
            <option value="Kiribati">Kiribati</option>
            <option value="Korea">Korea</option>
            <option value="North Korea">North Korea</option>
            <option value="South Kosovo">South Kosovo</option>
            <option value="Kuwait">Kuwait</option>
            <option value="Kyrgyzstan">Kyrgyzstan</option>
            <option value="Laos">Laos</option>
            <option value="Latvia">Latvia</option>
            <option value="Lebanon">Lebanon</option>
            <option value="Lesotho">Lesotho</option>
            <option value="Liberia">Liberia</option>
            <option value="Libya">Libya</option>
            <option value="Liechtenstein">Liechtenstein</option>
            <option value="Lithuania">Lithuania</option>
            <option value="Luxembourg">Luxembourg</option>
            <option value="Macedonia">Macedonia</option>
            <option value="Madagascar">Madagascar</option>
            <option value="Malawi">Malawi</option>
            <option value="Malaysia">Malaysia</option>
            <option value="Maldives">Maldives</option>
            <option value="Mali">Mali</option>
            <option value="Malta">Malta</option>
            <option value="Marshall Islands">Marshall Islands</option>
            <option value="Mauritania">Mauritania</option>
            <option value="Mauritius ">Mauritius</option>
            <option value="Mexico">Mexico</option>
            <option value="Micronesia">Micronesia</option>
            <option value="Moldova">Moldova</option>
            <option value="Monaco">Monaco</option>
            <option value="Mongolia">Mongolia</option>
            <option value="Montenegro">Montenegro</option>
            <option value="Morocco">Morocco</option>
            <option value="Mozambique">Mozambique</option>
            <option value="Myanmar (Burma)">Myanmar (Burma)</option>
            <option value="Namibia">Namibia</option>
            <option value="Nauru">Nauru</option>
            <option value="Nepal">Nepal</option>
            <option value="Netherlands">Netherlands</option>
            <option value="New Zealand">New Zealand</option>
            <option value="Nicaragua">Nicaragua</option>
            <option value="Niger">Niger</option>
            <option value="Nigeria">Nigeria</option>
            <option value="Norway">Norway</option>
            <option value="Oman">Oman</option>
            <option value="Pakistan">Pakistan</option>
            <option value="Palau">Palau</option>
            <option value="Palestine">Palestine</option>
            <option value="Panama">Panama</option>
            <option value="Papua">Papua</option>
            <option value="New Guinea">New Guinea</option>
            <option value="Paraguay">Paraguay</option>
            <option value="Peru">Peru</option>
            <option value="Philippines">Philippines</option>
            <option value="Poland">Poland</option>
            <option value="Portugal">Portugal</option>
            <option value="Qatar">Qatar</option>
            <option value="Romania">Romania</option>
            <option value="Russian">Russian</option>
            <option value="Federation">Federation</option>
            <option value="Rwanda St">Rwanda St</option>
            <option value="Kitts & Nevis St">Kitts & Nevis St</option>
            <option value="Lucia Saint Vincent & the Grenadines">
              Lucia Saint Vincent & the Grenadines
            </option>
            <option value="Samoa">Samoa</option>
            <option value="San Marino">San Marino</option>
            <option value="Sao Tome & Principe">Sao Tome & Principe</option>
            <option value="Saudi Arabia">Saudi Arabia</option>
            <option value="Senegal">Senegal</option>
            <option value="Serbia">Serbia</option>
            <option value="Seychelles">Seychelles</option>
            <option value="Sierra">Sierra</option>
            <option value="Leone">Leone</option>
            <option value="Singapore">Singapore</option>
            <option value="Slovakia">Slovakia</option>
            <option value="Slovenia">Slovenia</option>
            <option value="Solomon Islands">Solomon Islands</option>
            <option value="Somalia">Somalia</option>
            <option value="South Africa">South Africa</option>
            <option value="South Sudan">South Sudan</option>
            <option value="Spain">Spain</option>
            <option value="Sri Lanka">Sri Lanka</option>
            <option value="Sudan">Sudan</option>
            <option value="Suriname">Suriname</option>
            <option value="Swaziland">Swaziland</option>
            <option value="Sweden">Sweden</option>
            <option value="Switzerland">Switzerland</option>
            <option value="Syria">Syria</option>
            <option value="Taiwan">Taiwan</option>
            <option value="Tajikistan">Tajikistan</option>
            <option value="Tanzania">Tanzania</option>
            <option value="Thailand">Thailand</option>
            <option value="Togo">Togo</option>
            <option value="Tonga">Tonga</option>
            <option value="Trinidad & Tobago">Trinidad & Tobago</option>
            <option value="Tunisia">Tunisia</option>
            <option value="Turkey">Turkey</option>
            <option value="Turkmenistan">Turkmenistan</option>
            <option value="Tuvalu">Tuvalu</option>
            <option value="Uganda">Uganda</option>
            <option value="Ukraine">Ukraine</option>
            <option value="United Arab Emirates">United Arab Emirates</option>
            <option value="United Kingdom">United Kingdom</option>
            <option value="United States">United States</option>
            <option value="Uruguay">Uruguay</option>
            <option value="Uzbekistan">Uzbekistan</option>
            <option value="Vanuatu">Vanuatu</option>
            <option value="Vatican City">Vatican City</option>
            <option value="Venezuela">Venezuela</option>
            <option value="Vietnam">Vietnam</option>
            <option value="Yemen">Yemen</option>
            <option value="Zambia">Zambia</option>
            <option value="Zimbabwe">Zimbabwe</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="niveaux">Educational level</Label>
          <Input
            id="niveaux"
            name="niveaux"
            placeholder="Enter your educational level"
            type="select"
            value={this.state.niveaux}
            onChange={this.handleChange}
          >
            <option value="">Select your educational level</option>
            <option value="dr">Doctor</option>
            <option value="eng">Engineer</option>
            <option value="etudiant">Student</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="organisation">Organization</Label>
          <Input
            id="organisation"
            name="organisation"
            placeholder="Enter your organization"
            type="text"
            value={this.state.organisation}
            onChange={this.handleChange}
          />
        </FormGroup>
        {loading ? (
          <Button color="primary" disabled={true}>
            <Spinner size="sm" /> Loading
          </Button>
        ) : success ? (
          <Alert color="success">You have been successfully registered</Alert>
        ) : (
          <Button color="primary" type="submit">
            SignUp
          </Button>
        )}
      </Form>
    );
  }
}
export default InscriptionForm;
