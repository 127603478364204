import React, { Component } from "react";
import {
  Row,
  Col,
  Container,
  Button,
  ButtonGroup,
  ButtonToolbar,
} from "reactstrap";
import { Link } from "react-router-dom";

class ProfileHeader extends Component {
  render() {
    const { userData } = this.props;
    return (
      <div>
        <Row>
          <Container className="bg-light text-dark">
            <Col className="p-5">
              <h1 className="p-5">
                Hello, {userData.nom} {userData.prenom}
              </h1>
            </Col>
              <ButtonToolbar>
                <ButtonGroup className="mt-1">
                  <Button tag={Link} to={"user_events"} color="primary">
                    Regestred in events
                  </Button>
                  <div className="button-separator"></div>
                  <Button tag={Link} to={"user_cv"} color="primary">
                    My CV
                  </Button>
                  <div className="button-separator"></div>
                  <Button tag={Link} to={"user_publications"} color="primary">
                    My scientific publications
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
          </Container>
        </Row>
      </div>
    );
  }
}

export default ProfileHeader;
