import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import ResetPswForm from "../Forms/ResetPswForm";


class ResetPasswordModal extends Component {
  render() {
    return (
      <div>
        <Modal isOpen={this.props.showForgotPasswordModal} toggle={this.props.toggleForgotPasswordModal}>
          <ModalHeader toggle={this.props.toggleForgotPasswordModal}>Reset Password</ModalHeader>
          <ModalBody>
            <ResetPswForm toggle={this.props.toggleForgotPasswordModal}/>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
export default ResetPasswordModal;
