import { Component } from "react";
import axiosInstance from "../../../axios";
import {Card, CardBody } from "reactstrap";
import { PieChart } from "react-minimal-pie-chart";

class NiveauxEtudesStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalUsers: 0,
      totalEngineer: 0,
      totalDr: 0,
      totalEtudiants: 0,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    this.fetchTotalEngineer();
    this.fetchTotalDr();
    this.fetchTotalEtudiants();
    this.fetchTotalUsers();
  };

  fetchTotalUsers = () => {
    axiosInstance
      .get("admin/statistics/total-users/")
      .then((response) => {
        const totalUsers = response.data.total_users;
        this.setState({ totalUsers });
      })
      .catch((error) => {
        console.log("Error fetching total users", error);
      });
  };

  fetchTotalEngineer = () => {
    axiosInstance("admin/statistics/total-engineer/")
      .then((response) => {
        const totalEngineer = response.data.total_engineers;
        this.setState({ totalEngineer });
      })
      .catch((error) => {
        console.log("Error fetching total engineers", error);
      });
  };

  fetchTotalDr = () => {
    axiosInstance("admin/statistics/total-dr/")
      .then((response) => {
        const totalDr = response.data.total_dr;
        this.setState({ totalDr });
      })
      .catch((error) => {
        console.log("Error fetching total dr", error);
      });
  };

  fetchTotalEtudiants = () => {
    axiosInstance("admin/statistics/total-etudiant/")
      .then((response) => {
        const totalEtudiants = response.data.total_etudiant;
        this.setState({ totalEtudiants });
      })
      .catch((error) => {
        console.log("Error fetching total etudiants", error);
      });
  };

  render() {
    const { totalUsers, totalEngineer, totalDr, totalEtudiants } = this.state;
    const engineerPercentage = (totalEngineer / totalUsers) * 100;
    const drPercentage = (totalDr / totalUsers) * 100;
    const etudiantPercentage = (totalEtudiants / totalUsers) * 100;
    return (
      <Card style={{ width: "15rem" }}>
        <CardBody>
          <h6>Educational level</h6>
          <PieChart
            data={[
              {
                title: "Engineer",
                value: engineerPercentage,
                color: "#526D82",
              },
              { title: "Doctor", value: drPercentage, color: "#9DB2BF" },
              {
                title: "Student",
                value: etudiantPercentage,
                color: "#DDE6ED",
              },
            ]}
            label={({ dataEntry }) =>
              `${dataEntry.title}: ${Math.round(dataEntry.value)}%`
            } // Display the title as the label
            labelStyle={{
              fontSize: "5px",
              fontFamily: "sans-serif",
            }}
            labelPosition={60}
          />
        </CardBody>
      </Card>
    );
  }
}

export default NiveauxEtudesStats;
