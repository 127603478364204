import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  InputGroupText
} from "reactstrap";
import axiosInstance from "../../axios";

class UpdateEvent extends Component {
  constructor(props) {
    super(props);
    this.state = Object.freeze({
      title: "",
      localisation: "",
      dateDeb: "",
      dateFin: "",
      moreDetails: "",
      image: null,
      errors: {},
      fileName: ""
    });
  }

  componentDidMount() {
    const { eventId } = this.props;
    this.setState({ eventId });
    axiosInstance
      .get(`events/${eventId}/`)
      .then((response) => {
        const event = response.data;
        this.setState({
          title: event.title,
          localisation: event.localisation,
          moreDetails: event.moreDetails,
          dateDeb: event.dateDeb,
          dateFin: event.dateFin,
        });
      })
      .catch((error) => {
        console.error("Error fetching event: ", error);
      });
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleImageChange = (e) => {
    const file = e.target.files[0];
    this.setState({ image: file, fileName: file.name });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { eventId } = this.props;
    const formData = new FormData();
    formData.append("title", this.state.title);
    formData.append("localisation", this.state.localisation);
    formData.append("dateDeb", this.state.dateDeb);
    formData.append("dateFin", this.state.dateFin);
    formData.append("moreDetails", this.state.moreDetails)
    if (this.state.image) {
      formData.append("image", this.state.image);
    }

    axiosInstance
      .post(`admin/update-event/${eventId}/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then(() => {
        window.location.reload();

      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  };

  render() {
    return (
      <div className="UpdateEvent">
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="title">Title</Label>
                <Input
                  id="title"
                  name="title"
                  placeholder="Enter the event title"
                  type="text"
                  value={this.state.title}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="localisation">Location</Label>
                <Input
                  id="localisation"
                  name="localisation"
                  placeholder="Enter the event location"
                  type="text"
                  value={this.state.localisation}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="dateDeb">Starting date</Label>
                <Input
                  id="dateDeb"
                  name="dateDeb"
                  placeholder="Enter the event's starting date"
                  type="date"
                  value={this.state.dateDeb}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="dateFin">End date</Label>
                <Input
                  id="dateFin"
                  name="dateFin"
                  placeholder="Enter the event's end date"
                  type="date"
                  value={this.state.dateFin}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
          <Label for="moreDetails">Drive link or other site</Label>
            <Input
              id="moreDetails"
              name="moreDetails"
              placeholder="Enter the drive link or other site"
              type="text"
              value={this.state.moreDetails}
              onChange={this.handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="file">Event poster
            <InputGroup className="mt-3" style={{ cursor: "pointer" }}>
              <InputGroupText>Select a file</InputGroupText>
              <Input
                disabled
                placeholder={this.state.fileName || "No file selected"}
              />
            </InputGroup>
            <Input
              id="file"
              name="file"
              placeholder="Add the event poster"
              type="file"
              accept="image/png, image/jpg, image/jpeg"
              onChange={this.handleImageChange}
              style={{visibility: "hidden"}}
            />
            </Label>
          </FormGroup>
          <Button color="primary" className="me-3 mb-3" type="submit">
            Update the event
          </Button>
          <Link to="../">
            <Button color="danger" className="mb-3">Cancel</Button>
          </Link>
        </Form>
      </div>
    );
  }
}
export default UpdateEvent;
