import React from "react";
import { Row, Col } from "reactstrap";
const Header = () => {
  return (
    <Row>
      <header className="jumbotron">
        <Col className="text-center mt-5">
          <h1 className="tadgi">TADGI</h1>
          <p className="tadgi pb-5">
            Tunisian Association of Digital Geographic Information
          </p>
        </Col>
      </header>
    </Row>
  );
};

export default Header;
