import React, { Component } from "react";
import { Button, Table } from "reactstrap";
import axiosInstance from "../../axios";

class UserPublications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: [],
    };
  }

  componentDidMount() {
    axiosInstance
      .get(`utilisateurs/article/`)
      .then((res) => {
        this.setState({ articles: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleDelete = (articleId) => {
    axiosInstance
      .post(`utilisateurs/delete-article/${articleId}/`)
      .then(() => {

        const updatedArticles = this.state.articles.filter(
          (article) => article.id !== articleId
        );
        this.setState({ articles: updatedArticles });
      })
      .catch((error) => {
        console.error("Erreur lors de la suppréssion: ", error);
      });
  };

  render() {
    const { toggleArticlesModal, userData } = this.props;
    const { articles } = this.state;

    return (
      <div>
        <Table>
          <thead>
            <tr>
              <th>Title</th>
              <th>Authors</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {articles.map((article) => (
              <tr key={article.id}>
                <td>{article.titre}</td>
                <td>
                  {article.authors.map((author) => (
                    <div key={`${author.nom}_${author.prenom}`}>
                      {author.nom} {author.prenom}
                    </div>
                  ))}
                </td>
                <td>
                  <Button
                    color="danger"
                    onClick={() => this.handleDelete(article.id)}
                  >
                    X
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Button
          className="ms-2 mb-3"
          size="sm"
          color="primary"
          onClick={() => toggleArticlesModal(userData)}
        >
          Add article
        </Button>
      </div>
    );
  }
}
export default UserPublications;
