import React, { Component } from "react";
import axiosInstance from "../../axios";
import {Table} from "reactstrap"

class RegisteredUsersComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      registeredUsers: [],
      registeredInvites: []
    };
  }

  componentDidMount() {
    const { eventId } = this.props;

    // Make an API request to get registered users for the event
    axiosInstance
      .get(`events/${eventId}/registered_users/`)
      .then((response) => {
        this.setState({ registeredUsers: response.data.registered_users, registeredInvites: response.data.registered_invites});
      })
      .catch((error) =>
        console.error("Error fetching registered users: ", error)
      );
  }

  render() {
    const { registeredUsers, registeredInvites } = this.state;
    return (
      <div className="registered-users">
        <h6>List of members registered for the event</h6>
        <div className="users mt-3">
        <Table striped responsive>
          <thead>
            <tr>
              <th>First name</th>
              <th>Last name</th>
              <th>Phone number</th>
              <th>Email</th>
              <th>Country</th>
              <th>Educational level</th>
              <th>Organization</th>
            </tr>
          </thead>
          <tbody>
            {registeredUsers.map((user) => (
              <tr key={user.id}>
                <td>{user.nom}</td>
                <td>{user.prenom}</td>
                <td>{user.numTel}</td>
                <td>{user.mail}</td>
                <td>{user.pays}</td>
                <td>{user.niveaux}</td>
                <td>{user.organisation}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <h6>List of non-members registered in the event</h6>
        <div className="users mt-3">
        <Table striped responsive>
          <thead>
            <tr>
              <th>First name</th>
              <th>Last name</th>
              <th>Phone number</th>
              <th>Email</th>
              <th>Country</th>
              <th>Educational level</th>
              <th>Organization</th>
            </tr>
          </thead>
          <tbody>
            {registeredInvites.map((user) => (
              <tr key={user.id}>
                <td>{user.nom}</td>
                <td>{user.prenom}</td>
                <td>{user.numTel}</td>
                <td>{user.mail}</td>
                <td>{user.pays}</td>
                <td>{user.niveaux}</td>
                <td>{user.organisation}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      </div>
    );
  }
}

export default RegisteredUsersComponent;
