import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

class Topics extends Component {
  render() {
    return (
      <Container className="text-center" id="events">
        <hr />
        <h4>Topics that interest us</h4>
        <Row className="row-content">
          <Col xs="12" sm="3" className="mt-3">
            <img
              src="./static/img/space.jpg"
              className="rounded-circle"
              alt="spaceimage"
              width="150"
              height="140"
            />
            <h5 className="mt-4">Space Science</h5>
          </Col>
          <Col xs="12" sm="3" className="mt-3">
            <img
              src="./static/img/sat.jpg"
              className="rounded-circle"
              alt="spaceimage"
              width="150"
              height="140"
            />
            <h5 className="mt-4">Satellite Images</h5>
          </Col>
          <Col xs="12" sm="3" className="mt-3">
            <img
              src="./static/img/envtech.jpg"
              className="rounded-circle"
              alt="spaceimage"
              width="150"
              height="140"
            />
            <h5 className="mt-4">Environmental Technology</h5>
          </Col>
          <Col xs="12" sm="3" className="mt-3">
            <img
              src="./static/img/gis.png"
              className="rounded-circle"
              alt="spaceimage"
              width="150"
              height="140"
            />
            <h5 className="mt-4">Geographic Information Systems</h5>
          </Col>
          <Col xs="12" sm="3" className="mt-3">
            <img
              src="./static/img/landsat8.jpg"
              className="rounded-circle"
              alt="spaceimage"
              width="150"
              height="140"
            />
            <h5 className="mt-4">Remote Sensing</h5>
          </Col>
          <Col xs="12" sm="3" className="mt-3">
            <img
              src="./static/img/risk.jpg"
              className="rounded-circle"
              alt="spaceimage"
              width="150"
              height="140"
            />
            <h5 className="mt-4">Natural Risks</h5>
          </Col>
          <Col xs="12" sm="3" className="mt-3">
            <img
              src="./static/img/esa.jpg"
              className="rounded-circle"
              alt="spaceimage"
              width="150"
              height="140"
            />
            <h5 className="mt-4">Radar Images</h5>
          </Col>
          <Col xs="12" sm="3" className="mt-3">
            <img
              src="./static/img/water.png"
              className="rounded-circle"
              alt="spaceimage"
              width="150"
              height="140"
            />
            <h5 className="mt-4">Water and Technology</h5>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default Topics;
