import React,{ Component } from "react";
import axiosInstance from "../../../axios";
import { Card } from "reactstrap";
import {Bar} from "react-chartjs-2"
import { registerables, Chart } from "chart.js";

Chart.register(... registerables);

class MembersPerCountryStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      membersPerCountry: {
        labels: [],
        data: [],
      },
    };

  }

  componentDidMount() {
    this.fetchMembersPerCountry();
  }

  fetchMembersPerCountry = () => {
    axiosInstance.get("admin/statistics/users-per-year-barchart/")
      .then((response) => {
        const membersPerCountry = {labels: response.data.labels, data: response.data.data};
        this.setState({membersPerCountry: membersPerCountry}, () => {
        })
      })
      .catch((error) => {
        console.log("Error fetching members per countrr :", error);
      });
  };

  renderChart() {
    const { membersPerCountry } = this.state;
    const integerData = membersPerCountry.data.map((value) => Math.trunc(value));


    const chartData = {
      labels: membersPerCountry.labels,
      datasets: [
        {
          label: "Members by country",
          data: integerData ,
          backgroundColor: "rgba(75, 192, 192, 0.6)",
        },
      ],
    };

    const chartOptions = {
      scales: {
        x: {
          type: "category",
        },
        y: {
          ticks : {precision: 0,}
          
        },
      },
    };
    return <Bar data={chartData} options={chartOptions} />;
  }

  render() {
    return (
      <Card style={{ width: "15rem" }} className="mt-2">
        <div>{this.renderChart()}</div>
      </Card>
    );
  }
}

export default MembersPerCountryStats;
