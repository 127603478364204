import React, { Component } from "react";

import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavLink,
  NavbarText,
  Button,
} from "reactstrap";

import { Link } from "react-router-dom";
import {HashLink} from "react-router-hash-link"

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  noActivity() {
    alert("This activity does not existe at the mement");
  }

  render() {
    const { isOpen } = this.state;
    const { isLoggedIn, userData, toggleLoginModal, showResetPasswordModal, toggleResetModal } = this.props;
    const linkStyle ={
      textDecoration: 'none'
    }
    return (
      <div lca>
        <Navbar expand="sm" style={{ backgroundColor: "#367c9c" }}>
          <Link to={"/"}>
            <img src="./static/img/logo.png" height={30} alt="atign" />
          </Link>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="me-auto" navbar>
              <NavItem>
                <Link to="/" className="nav-link text-white">
                  Home
                </Link>
              </NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret className="text-white">
                  Association
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem>
                    <Link to="/id" className="nav-link text-black">
                      Identity
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to="/obj" className="nav-link text-black">
                      Objective
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to="/partners" className="nav-link text-black">
                      Partners
                    </Link>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret className="text-white">
                  Activities
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem>
                    <HashLink to="/#events" style={linkStyle}>
                    <NavLink
                      className="text-black"
                    >
                      Events
                    </NavLink>
                    </HashLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink onClick={this.noActivity} className="text-black">
                      Training
                    </NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink onClick={this.noActivity} className="text-black">
                      Projects
                    </NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret className="text-white">
                  Scientific publications
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem>
                    <NavLink
                      target="_blank"
                      href="https://www.geosp.net/?cat=14&lang=fr"
                      className="text-black"
                    >
                      Research
                    </NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink
                      target="_blank"
                      href="https://www.geosp.net/?cat=14&lang=fr"
                      className="text-black"
                    >
                      Article
                    </NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <Link to="/geotunis" className="nav-link text-white" target="_blank">
                Geotunis
                </Link>
              </NavItem>
              <NavItem>
              <HashLink to="/#contact" style={linkStyle}>
                <NavLink
                  className="text-white"
                >
                  Contact
                </NavLink>
                </HashLink>
              </NavItem>
              {isLoggedIn &&
              userData &&
              userData.mail !== "geoconf2@gmail.com" ? (
                <NavItem>
                  <Link
                    to={`/profil/${userData.id}/`}
                    className="nav-link text-white"
                  >
                    My Profile
                  </Link>
                </NavItem>
              ) : isLoggedIn &&
                userData &&
                userData.mail === "geoconf2@gmail.com" ? (
                <NavItem>
                  <Link
                    to={`/admin/${userData.id}/`}
                    className="nav-link text-white"
                  >
                    Admin
                  </Link>
                </NavItem>
              ) : null}
            </Nav>
            <NavbarText>
              {isLoggedIn ? (
                <Button
                  size="sm"
                  color="danger"
                  onClick={this.props.handleLogout}
                >
                  LogOut
                </Button>
              ) : (
                <div className="notLogged">
                  <Button
                    size="sm"
                    color="success"
                    onClick={this.props.toggleInscriptionModal}
                  >
                    SignUp
                  </Button>
                  <Button
                    className="ms-2"
                    size="sm"
                    color="primary"
                    onClick={() => toggleLoginModal(userData)}
                  >
                    SignIn
                  </Button>
                </div>
              )}
            </NavbarText>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

export default Navigation;
