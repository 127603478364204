import React, { Component } from "react";
import Header from "./Home/Header";
import GISPresentation from "./Home/GISPresentation";
import UpcommingEvents from "./Home/UpcommingEvents";
import Topics from "./Home/Topics";
import PhotoGallery from "./Home/PhotoGallery";
import Contact from "./Home/Contact";
import EventRegistrationModal from "./Modals/EventRegistrationModal";
import { Container } from "reactstrap";
import PastEvents from "./Home/PastEvents";

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEventModal: false,
      selectedEvent: null,
    };
    this.toggleEventModal = this.toggleEventModal.bind(this);
  }

  toggleEventModal(eventId) {
    this.setState((prevState) => ({
      selectedEvent: eventId,
      showEventModal: !prevState.showEventModal,
    }));
  }

  render() {
    const { userData } = this.props;
    return (
      <div className="HomePage">
        <Header />
        <GISPresentation />
        <Container id="events">
          <UpcommingEvents
            toggleEventModal={this.toggleEventModal}
            userData={userData}
          />
          <PastEvents />
        </Container>
        <Topics />
        <PhotoGallery />
        <Container id="contact">
        <Contact />
        </Container>
        <EventRegistrationModal
          userData={userData}
          event_id={this.state.selectedEvent}
          showEventModal={this.state.showEventModal}
          toggleEventModal={this.toggleEventModal}
        />
      </div>
    );
  }
}
export default HomePage;
