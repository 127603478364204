import { Component } from "react";
import axiosInstance from "../../../axios";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import countryCoordinates from "../../../countryCoordinates";
import { Card, CardBody } from "reactstrap";


class MembersPerCountryMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      membersPerCountry: [],
    };
  }


  componentDidMount(){
    this.fetchMembersPerCountry()
  }


  fetchMembersPerCountry = () => {
    axiosInstance("admin/statistics/members-per-country/")
      .then((response) => {
        const membersPerCountry = response.data;
        this.setState({ membersPerCountry: membersPerCountry });
      })
      .catch((error) => {
        console.log("Error fetching members per countrr :", error);
      });
  };

  renderMarkers() {
    const { membersPerCountry } = this.state;

    return Object.entries(membersPerCountry).map(([country, count]) => {
      const position = countryCoordinates[country];
      if (position) {
        return (
          <Marker key={country} position={position}>
            <Popup>{`${country}: ${count} membre(s)`}</Popup>
          </Marker>
        );
      } else {
        return null;
      }
    });
  }

  render() {
    return (
      <Card style={{ width: "27rem" }}>
        <CardBody>
        <h6>Members by country</h6>
          <MapContainer
            center={[36.806, 10.181]}
            zoom={4}
            style={{ height: "400px", width: "100%" }}
            attributionControl= {false}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {this.renderMarkers()}
          </MapContainer>
        </CardBody>
      </Card>
    );
  }
}

export default MembersPerCountryMap;
