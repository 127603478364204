import React, { Component } from "react";
import { MapContainer, Marker, TileLayer } from "react-leaflet";

class ContactUsMap extends Component {
  constructor(props) {
    super(props);
    this.atign = [36.79825459923654, 10.180593924458382];
  }
  render() {
    return (
      <div className="ContactUsMap mb-5" id="contact">
        <MapContainer
          center={this.atign}
          zoom={20}
          dragging={false}
          touchZoom={false}
          doubleClickZoom={false}
          scrollWheelZoom={false}
          boxZoom={false}
          keyboard={false}
          zoomControl={false}
          attributionControl={false}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <Marker position={this.atign} />
        </MapContainer>
      </div>
    );
  }
}

export default ContactUsMap;
