import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import EventRegistrationForm from "../Forms/EventRegistrationForm";
class EventRegistrationModal extends Component {
  render() {
    return (
      <div>
        <Modal isOpen={this.props.showEventModal} toggle={this.props.toggleEventModal}>
          <ModalHeader toggle={this.props.toggleEventModal}>Register</ModalHeader>
          <ModalBody>
            <EventRegistrationForm toggle={this.props.toggleEventModal} event_id={this.props.event_id}/>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
export default EventRegistrationModal;
