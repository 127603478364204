import React, { Component } from "react";
import ProfileHeader from "./Profile/ProfileHeader";
import { Routes, Route, Navigate } from "react-router-dom";
import UserEvents from "./Profile/UserEvents";
import UserCv from "./Profile/UserCv";
import UserPublications from "./Profile/UserPublications";
import ArticlesModal from "./Modals/ArticlesModal";

class ProfilePage extends Component {
  constructor(props) {
    super(props);
    this.state = { showArticlesModal: false };
  }

  toggleArticlesModal = () => {
    this.setState((prevState) => ({
      showArticlesModal: !prevState.showArticlesModal,
    }));
  };

    addArticle = (newArticle) => {
    this.setState(prevState => ({
      articles: [...prevState.articles, newArticle]
    }));
  }

  render() {
    const { userData } = this.props;
    return (
      <div className="ProfilePage">
        <ProfileHeader userData={userData} />
        <Routes>
          <Route path={"user_events"} element={<UserEvents />} />
          <Route path={"user_cv"} element={<UserCv userData={userData} />} />
          <Route
            path={"user_publications"}
            element={
              <UserPublications
                userData={userData}
                toggleArticlesModal={this.toggleArticlesModal}
              />
            }
          />

          <Route index element={<Navigate to="user_events" />} />
        </Routes>
        <ArticlesModal
          showArticlesModal={this.state.showArticlesModal}
          toggleArticlesModal={this.toggleArticlesModal}
          userData={userData}
        />
      </div>
    );
  }
}
export default ProfilePage;
