import "./App.css";
import HomePage from "./components/HomePage";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import RegisterModal from "./components/Modals/RegisterModal";
import LoginModal from "./components/Modals/LoginModal";
import ProfilePage from "./components/ProfilePage";
import AdminPage from "./components/AdminPage";
import axiosInstance from "./axios";
import { Routes, Route } from "react-router-dom";
import "bootstrap/dist/js/bootstrap.js";
import { Component } from "react";
import Objective from "./components/Objective";
import AssociationIdentity from "./components/AssociationIdentity";
import Partners from "./Partners";
import GeoTunisEn from "./GeotunisEn";
import GeoTunisAr from "./GeoTunisAr";
import ChangePassword from "./components/Forms/ChangePassword";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showIscriptionModal: false,
      showLoginModal: false,
      isLoggedIn: false,
      userData: null,
    };
    this.toggleInscriptionModal = this.toggleInscriptionModal.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleLoginState = this.handleLoginState.bind(this);
    this.toggleLoginModal = this.toggleLoginModal.bind(this);
  }

  componentDidMount() {
    this.getCurrentUserId();
    const access_token = localStorage.getItem("access_token");
    const refresh_token = localStorage.getItem("refresh_token");
    if (access_token && refresh_token) {
      this.setState({ isLoggedIn: true });
    } else {
      this.setState({ isLoggedIn: false });
    }
  }

  async getCurrentUserId() {
    const access_token = localStorage.getItem("access_token");
    const refresh_token = localStorage.getItem("refresh_token");
    const token = access_token || refresh_token;

    await axiosInstance.get("current_user/", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!token) {
      // Redirect the user to the login page or handle the error appropriately
      return;
    }

    axiosInstance
      .get("current_user/")
      .then((response) => {
        if (response.data) {
          const userId = response.data.id;
          this.getUserProfile(userId);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getUserProfile(userId) {
    axiosInstance(`profil/${userId}/`)
      .then((response) => {
        const userData = response.data;
        this.setState({ userData });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  toggleInscriptionModal() {
    this.setState((prevState) => ({
      showIscriptionModal: !prevState.showIscriptionModal,
    }));
  }

  toggleLoginModal(userData) {
    this.setState((prevState) => ({
      showLoginModal: !prevState.showLoginModal,
      userData: userData,
    }));
  }

  handleLogout() {
    axiosInstance
      .post("utilisateurs/logout/blacklist/", {
        refresh_token: localStorage.getItem("refresh_token"),
      })
      .then(() => {
        window.location.href = "/";
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        axiosInstance.defaults.headers["Authorization"] = null;
        this.setState((prevState) => ({
          isLoggedIn: !prevState.isLoggedIn,
          userData: !prevState.userData,
        }));
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleLoginState() {
    this.setState({ isLoggedIn: true });
  }
  render() {
    return (
      <div className="App">
        <Navigation
          toggleInscriptionModal={this.toggleInscriptionModal}
          toggleLoginModal={this.toggleLoginModal}
          handleLogout={this.handleLogout}
          isLoggedIn={this.state.isLoggedIn}
          userData={this.state.userData}
        />
        <div className="App-content">
          <Routes>
            <Route
              path="/"
              element={<HomePage userData={this.state.userData} />}
            />
            <Route
              path="/obj"
              element={<Objective userData={this.state.userData} />}
            />
            <Route
              path="/id"
              element={<AssociationIdentity userData={this.state.userData} />}
            />
            <Route
              path="/partners"
              element={<Partners userData={this.state.userData} />}
            />
            <Route
              path="/geotunis"
              element={<GeoTunisEn userData={this.state.userData} />}
            />
            <Route
              path="/geotunis-ar"
              element={<GeoTunisAr userData={this.state.userData} />}
            />
            <Route
              path="/password-reset/confirm/:uidb64/:token/"
              element={<ChangePassword />}
            />
          </Routes>

          {this.state.userData && (
            <Routes>
              <Route
                path={`/profil/${this.state.userData.id}/*`}
                element={<ProfilePage userData={this.state.userData} />}
              />
              <Route
                path={`/admin/${this.state.userData.id}/*`}
                element={<AdminPage userData={this.state.userData} />}
              />
            </Routes>
          )}

          <RegisterModal
            showIscriptionModal={this.state.showIscriptionModal}
            isLoggedIn={this.state.isLoggedIn}
            toggleInscriptionModal={this.toggleInscriptionModal}
            handleLoginState={this.handleLoginState}
          />
          <LoginModal
            isLoggedIn={this.state.isLoggedIn}
            handleLoginState={this.handleLoginState}
            showLoginModal={this.state.showLoginModal}
            toggleLoginModal={this.toggleLoginModal}
            userData={this.state.userData}
            toggleResetModal={this.toggleResetModal}
            showResetPasswordModal={this.state.showResetPasswordModal}
          />
        </div>
        <Footer />
      </div>
    );
  }
}

export default App;
