import React, { Component } from "react";

class Header extends Component {
  render() {
    return (
      <header fluid>
        <img src="./static/img/baniere ATIGN.jpg" className="img-fluid" alt="ATIGN" />
      </header>
    );
  }
}
export default Header;
